import moment from "moment/moment";
import {DATE_FORM_FORMAT} from "@/constans/date";

export const approvesByTimeDefaultFilters = {
  salesTypes: '',
  countries: '',
  regions: '',
  userGroups: '',
  dateFrom: moment('2023-09').startOf('month').format(DATE_FORM_FORMAT),
  dateTo: moment('2023-09').endOf('month').format(DATE_FORM_FORMAT),
}

export const amountAndAverageDefaultFilters = {
  deliveryService: '',
  type: '',
  userGroups: '',
  dateFrom: moment('2023-09').startOf('month').format(DATE_FORM_FORMAT),
  dateTo: moment('2023-09').endOf('month').format(DATE_FORM_FORMAT),
}
export default [
  {
    path: '/settings/region',
    name: 'pageRegionList',
    meta: { title: 'page.pageRegionList', roles: ['SETTING_REGION_VIEW'] },
    component: () => import('@/pages/settings/region'),
  },
  {
    path: '/settings/region/create',
    name: 'pageRegionCreate',
    meta: { title: 'page.pageRegionCreate', roles: ['SETTING_REGION_VIEW'] },
    component: () => import('@/pages/settings/region/create'),
  },
  {
    path: '/settings/region/:id/edit',
    name: 'pageRegionEdit',
    meta: { title: 'page.pageRegionEdit', roles: ['SETTING_REGION_VIEW'] },
    component: () => import('@/pages/settings/region/id/edit'),
  },
  {
    path: '/settings/region/region-import',
    name: 'pageRegionImport',
    meta: { title: 'page.pageRegionImport', roles: ['SETTING_REGION_VIEW'] },
    component: () => import('@/pages/settings/region/import'),
  },
]

import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/OrderDeliveryStatusLog/helpers'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import orderDeliveryStatusLogRepository from '@/repository/admin/orderDeliveryStatusLogRepository'

const state = {
  deliveryStatusLogList: [],
  filters: StorageService.get(storageKeys.ORDER_DELIVERY_STATUS_LOG) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  deliveryStatusLogList: state => state.deliveryStatusLogList,
}

const actions = {
  async loadDeliveryStatusLogList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.ORDER_DELIVERY_STATUS_LOG, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await orderDeliveryStatusLogRepository.list(preparedFilters)

    context.commit('setDeliveryStatusLogList', { data, filters })

    return { deliveryStatusLogList: data.items, pagination: data.pagination }
  },
}

const mutations = {
  setDeliveryStatusLogList(state, { data, filters }) {
    state.deliveryStatusLogList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/CallStatistics/helpers'
import orderReportRepository from '@/repository/admin/orderReportRepository'
import { mockPagination, omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'

const state = {
  filters: StorageService.get(storageKeys.CALL_STATISTICS_FILTERS) || { ...defaultFilters },
  callStatisticsList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  callStatisticsList: state => state.callStatisticsList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadCallStatisticsList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.CALL_STATISTICS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilter = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilter,
    })
    const { data } = await orderReportRepository.callStatistics(preparedFilters.filter)

    context.commit('setCallStatisticsList', {
      data,
      pagination: mockPagination(Object.values(data), filters),
      filters,
    })

    return { callStatisticsList: data }
  },

  updateSavedFilters(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.CALL_STATISTICS_FILTERS, filters)

    context.commit('setCallStatisticsList', {
      data: context.getters.callStatisticsList,
      pagination: mockPagination(Object.values(context.getters.callStatisticsList), filters),
      filters,
    })
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.CALL_STATISTICS_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.CALL_STATISTICS_FILTERS, updatedFilters)
  },
}

const mutations = {
  setCallStatisticsList(state, { data, filters, pagination }) {
    state.callStatisticsList = data
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import StorageService from '@/services/storage.service'
import TableOptions from '@/models/TableOptions'
import storageKeys from '@/constans/storageKeys'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import smsTemplateRepository from '@/repository/call-centre/smsTemplateRepository'
import { defaultFilters } from '@/models/SmsTemplate/helpers'

const state = {
  filters: StorageService.get(storageKeys.SMS_TEMPLATE_FILTERS) || {
    ...defaultFilters,
  },
  tableOptions: TableOptions.defaultTableOptions(),
  smsTemplateList: [],
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  smsTemplateList: state => state.smsTemplateList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadSmsTemplateList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.SMS_TEMPLATE_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await smsTemplateRepository.list(preparedFilters)
    context.commit('setSmsTemplateList', { data, filters })

    return { smsTemplateList: data.items, pagination: data.pagination }
  },
  createSmsTemplate(context, payload) {
    return smsTemplateRepository.post(payload)
  },
  async loadSmsTemplate(context, id) {
    const { data } = await smsTemplateRepository.get(id)
    return { data }
  },
  async updateSmsTemplate(context, payload) {
    const { id, ...data } = payload
    return smsTemplateRepository.put(id, data)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.SMS_TEMPLATE_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.SMS_TEMPLATE_FILTERS, updatedFilters)
  },
}

const mutations = {
  setSmsTemplateList(state, { data, filters }) {
    state.smsTemplateList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="innerTime"
    transition="scale-transition"
    offset-y
    z-index="500"
  >
    <template v-slot:activator="props">
      <slot
        name="activator"
        v-bind="{
          ...props,
          inputValue: formattedValue,
        }"
      />
    </template>
    <div class="d-flex">
      <v-time-picker
        ref="timePicker"
        v-model="innerTime"
        header-color="black lighten-7"
        v-bind="timePickerSettings"
      >
        <v-container fluid class="pb-3 pt-0 px-2">
          <v-row>
            <v-col cols="6" class="pa-0 px-1">
              <ui-button theme="outline" block class="w-100" size="small" @click="menu = false">
                {{ $t('base.cancel') }}
              </ui-button>
            </v-col>

            <v-col cols="6" class="pa-0 px-1">
              <ui-button block class="w-100" size="small" @click="onApply">
                {{ $t('base.apply') }}
              </ui-button>
            </v-col>
          </v-row>
        </v-container>
      </v-time-picker>
    </div>
  </v-menu>
</template>

<script>
import { isFunction } from 'lodash'
import { TIME_SHORT_FORMAT, DATE_FORM_FORMAT } from '@/constans/date'

const KEYDOWN_ENTER = 'Enter'

export default {
  name: 'ui-time-picker',
  props: {
    value: {
      type: String,
      default: '00:00',
    },
    formatField: {
      type: String,
      default: 'HH:mm',
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    displayDateFormatter: {
      type: Function,
      default: null,
    },
    returnValueFormat: {
      type: String,
      default: TIME_SHORT_FORMAT,
    },
  },
  data() {
    return {
      menu: false,
      innerTime: '00:00',
    }
  },
  computed: {
    formattedValue() {
      const time = this.innerTime

      if (isFunction(this.displayDateFormatter)) {
        return this.displayDateFormatter(time)
      }

      return time
    },
    timePickerSettings() {
      const defaultSettings = {
        flat: true,
        format: '24hr',
        color: 'primary base',
        scrollable: true,
        locale: this.$i18n.locale,
      }

      return {
        ...defaultSettings,
        ...this.settings,
      }
    },
    isFullDate() {
      return this.isValidDate(this.value)
    },
  },
  watch: {
    value: {
      handler(time) {
        if (time) {
          const isValidDate = this.isValidDate(time)
          this.innerTime = isValidDate ? this.$moment(time).format(TIME_SHORT_FORMAT) : time
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown)
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === KEYDOWN_ENTER) {
        this.onApply()
      }
    },
    isValidDate(date) {
      return date.length > 4 && this.$moment(date).isValid()
    },
    onApply() {
      this.$refs.menu.save(this.innerTime)
      const fullDate = this.isFullDate ? this.value : new Date()

      const date = this.$moment(this.$moment(fullDate).format(DATE_FORM_FORMAT) + ' 00:00:00')
      const [hours, minutes] = this.innerTime.split(':')
      date.add(hours, 'hours')
      date.add(minutes, 'minutes')

      this.$emit('input', this.$moment(date).format(this.returnValueFormat))
    },
  },
}
</script>

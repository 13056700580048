import regionImportRepository from '@/repository/admin/regionImportRepository'
const { saveAs } = require('file-saver')
import i18n from '@/plugins/vue-i18n'
function renderHeaders(data) {
  if (!data) return []
  return Array.isArray(data) && data.length
    ? Object.keys(data[0]).map(key => {
        return {
          text: i18n.t(`regionImport.field.${key}`),
          sortable: false,
          value: key,
        }
      })
    : []
}

const state = {
  regionPreviewList: [],
  regionPreviewHeaders: [],
}

const getters = {
  regionPreviewList: state => state.regionPreviewList,
  regionPreviewHeaders: state => state.regionPreviewHeaders,
}

const actions = {
  async loadExportFile() {
    const response = await regionImportRepository.export()
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = 'Шаблон импорта областей.xlsx'
    saveAs(blob, filename)
    return response
  },
  async importFile(context, payload) {
    const { formData, config } = payload
    return await regionImportRepository.import(formData, config)
  },
  async loadPreviewFile(context, payload) {
    const { formData, config } = payload
    const { data } = await regionImportRepository.preview(formData, config)

    const headers = renderHeaders(data)

    context.commit('setRegionPreviewHeaders', headers)
    context.commit('setRegionPreviewList', data)

    return {
      regionPreviewList: data,
      regionPreviewHeaders: headers,
    }
  },
}

const mutations = {
  setRegionPreviewList(state, data) {
    state.regionPreviewList = data
  },
  setRegionPreviewHeaders(state, data) {
    state.regionPreviewHeaders = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

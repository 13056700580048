export default [
  {
    path: '/manager/logistic-price',
    name: 'pageLogisticPrice',
    meta: { title: 'page.pageLogisticPrice', roles: ['MNG_LOGISTIC_COST'] },
    component: () => import('@/pages/manager/logistic-price'),
  },
  {
    path: '/manager/logistic-price/import',
    name: 'pageLogisticPriceImport',
    meta: { title: 'page.pageLogisticPriceImport', roles: ['MNG_LOGISTIC_COST'] },
    component: () => import('@/pages/manager/logistic-price/import'),
  },
]

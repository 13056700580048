export default [
  {
    path: '/settings/user-schedule',
    name: 'pageUserScheduleList',
    meta: { title: 'page.pageUserScheduleList', roles: ['SETTING_OPERATOR_SCHEDULE_VIEW'] },
    component: () => import('@/pages/settings/user-schedule'),
  },
  {
    path: '/settings/user-schedule/create',
    name: 'pageUserScheduleCreate',
    meta: {
      title: 'page.pageUserScheduleCreate',
      roles: ['SETTING_OPERATOR_SCHEDULE_OPERATE'],
    },
    component: () => import('@/pages/settings/user-schedule/create'),
  },
  {
    path: '/settings/user-schedule/:id/edit',
    name: 'pageUserScheduleEdit',
    meta: {
      title: 'page.pageUserScheduleCreate',
      roles: ['SETTING_OPERATOR_SCHEDULE_OPERATE'],
    },
    component: () => import('@/pages/settings/user-schedule/id/edit'),
  },
]

export const defaultFilters = ({ typeOfPayment, prepayment_type, month }) => ({
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    user: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    typeOfPayment,
  },
  prepayment_type,
  month,
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
})

import { UserNotificationsRepository } from '@/repository/admin'
import { defaultFilters } from '@/models/UserNotifications/helper'
import { cloneDeep } from 'lodash'

const state = {
  connected: false,
  unreadNotifications: [],
  readNotifications: [],
  hasNewNotifications: false,
  unreadNotificationsCount: 0,
  isLoading: false,
  filters: cloneDeep(defaultFilters),
  currentPage: 1,
  maxPageNumber: 1,
}

const getters = {
  isConnected: ({ connected }) => connected,
  unreadNotifications: ({ unreadNotifications }) => unreadNotifications,
  readNotifications: ({ readNotifications }) => readNotifications,
  hasNewNotifications: ({ hasNewNotifications }) => hasNewNotifications,
  unreadNotificationsCount: ({ unreadNotificationsCount }) => unreadNotificationsCount,
  isLoading: ({ isLoading }) => isLoading,
  currentPage: ({ currentPage }) => currentPage,
  maxPageNumber: ({ maxPageNumber }) => maxPageNumber,
}

const actions = {
  async loadNotifications({ commit }, payload) {
    const filters = payload ? { ...payload } : { ...defaultFilters }
    commit('setLoading', true)

    try {
      const { data } = await UserNotificationsRepository.list(filters)

      if (!data.items.length && data.pagination.currentPage === 1) {
        return commit('resetNotifications')
      }

      commit('setNotifications', data)
      commit('setCurrentPage', data.pagination.currentPage)
      commit('setMaxPageNumber', data.pagination.maxPageNumber)
    } catch (e) {
      console.log(e)
    } finally {
      commit('setLoading', false)
    }
  },

  async deleteAllNotifications({ dispatch }) {
    try {
      await UserNotificationsRepository.removeAll()
      dispatch('loadNotifications')
    } catch (e) {
      console.log(e)
    }
  },

  async deleteNotificationById({ commit }, { id, read }) {
    console.log(id)
    try {
      await UserNotificationsRepository.delete(id)
      commit('locallyRemoveNotification', { id, read })

      // Todo can be important after QA testing
      // dispatch('loadNotifications')
    } catch (e) {
      console.log(e)
    }
  },

  async bulkRead({ state, commit }) {
    try {
      await UserNotificationsRepository.bulkRead({
        notifications: state.unreadNotifications.map(({ id }) => id),
      })

      commit('setUnreadNotificationsCount', 0)
      commit('setHasNewNotifications', false)
    } catch (e) {
      console.error(e)
    }
  },

  async getUnreadNotificationsCount({ commit }) {
    try {
      const { data } = await UserNotificationsRepository.getUnreadNotificationsCount()
      commit('setHasNewNotifications', data > 0)
      commit('setUnreadNotificationsCount', data)
    } catch (e) {
      console.error(e)
    }
  },

  ws_connect({ commit }) {
    commit('setConnected', true)
  },

  ws_disconnect({ commit }) {
    commit('setConnected', false)
  },

  ws_notification_create({ commit }, payload) {
    commit('addNotification', payload)
  },
}

const mutations = {
  setConnected(state, connected) {
    state.connected = connected
  },

  addNotification(state, data) {
    state.unreadNotifications.unshift(data)
    state.hasNewNotifications = true
    state.unreadNotificationsCount += data.length
  },

  setNotifications(state, { items }) {
    const result = items.reduce(
      (acc, item) => {
        // Todo recheck this conditions, change to increase performance
        // Todo Fix reduce error
        if (item.readAt) {
          const elementExist = state.readNotifications.some(stateItem => stateItem.id === item.id)
          if (elementExist) return acc

          acc.read.push(item)
        } else {
          const elementExist = state.unreadNotifications.some(stateItem => stateItem.id === item.id)
          if (elementExist) return acc
          acc.unread.push(item)
        }
        // item.readAt ? acc.read.push(item) : acc.unread.push(item)
        // console.log(acc)
        return acc
      },
      {
        read: [],
        unread: [],
      },
    )

    console.log('r', result)

    state.readNotifications = [...state.readNotifications, ...result?.read]
    state.unreadNotifications = [...state.unreadNotifications, ...result?.unread]

    state.hasNewNotifications = !!result.unread.length
    state.unreadNotificationsCount = result.unread.length
  },

  setHasNewNotifications(state, bool) {
    state.hasNewNotifications = bool
  },

  setUnreadNotificationsCount(state, data) {
    state.unreadNotificationsCount = data
  },

  setLoading(state, bool) {
    state.isLoading = bool
  },

  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage
  },

  setMaxPageNumber(state, maxPageNumber) {
    state.maxPageNumber = maxPageNumber
  },

  resetNotifications(state) {
    state.readNotifications.length ? (state.readNotifications = []) : null
    state.unreadNotifications.length ? (state.unreadNotifications = []) : null

    state.filters = cloneDeep(defaultFilters)

    state.currentPage !== 1 ? (state.currentPage = 1) : null
    state.maxPageNumber !== 1 ? (state.maxPageNumber = 1) : null
  },

  locallyRemoveNotification(state, data) {
    const notifications = data.read ? 'readNotifications' : 'unreadNotifications'

    // Todo: replace to binarySearch for performance optimization
    console.log(state[notifications])
    state[notifications] = state[notifications].filter(({ id }) => id !== data.id)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default [
  {
    path: '/logistics/delivery-service-analytic',
    name: 'pageDeliveryServiceAnalytic',
    meta: {
      title: 'page.pageDeliveryServiceAnalytic',
      roles: ['LOGISTIC_DELIVERY_SERVICE_REPORT'],
    },
    component: () => import('@/pages/logistics/delivery-service-analytic'),
  },
]

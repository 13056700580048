import { intersection, isEqual, cloneDeep } from 'lodash'
import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/Offer/helpers'
import { defaultFilters as defaultStatisticFilters } from '@/models/OfferStatistic/helpers'
import { defaultFilters as defaultOfferColdStatistics } from '@/models/OfferColdSatistic/helpers'
import offerRepository from '@/repository/admin/offerRepository'
import salesTypeRepository from '@/repository/admin/salesTypeRepository'
import TableOptions from '@/models/TableOptions'
import {getPaginatedList, mockPagination, omitEmptyDeepFilters, prepareFilters} from '@/helpers/filters'

const state = {
  offer: null,
  offerList: [],
  offerStatistic: [],
  isLoadingOfferStatistic: false,
  offerColdStatistic: [],
  filters: StorageService.get(storageKeys.OFFER_FILTERS) || { ...defaultFilters },
  filtersStatistic: StorageService.get(storageKeys.OFFER_STATISTICS_FILTERS) || {
    ...defaultStatisticFilters,
  },
  filtersColdOfferStatistic: StorageService.get(storageKeys.OFFER_COLD_STATISTIC_FILTERS) || {
    ...defaultOfferColdStatistics,
  },
  tableOptions: TableOptions.defaultTableOptions(),
  tableAdditionalProjectsOptions: TableOptions.defaultTableOptions(),
  salesTypes: [],
}

const getters = {
  offer: state => state.offer,
  filters: state => state.filters,
  filtersStatistic: state => state.filtersStatistic,
  filtersColdOfferStatistic: state => {
    return state.filtersColdOfferStatistic
  },
  isLoadingOfferStatistic: state => state.isLoadingOfferStatistic,
  tableOptions: state => state.tableOptions,
  tableAdditionalProjectsOptions: state => state.tableAdditionalProjectsOptions,
  offerList: state => state.offerList,
  offerStatistic: state => state.offerStatistic,
  offerColdStatistic: state => state.offerColdStatistic,
  offerColdStatisticPaginated: state =>
    getPaginatedList(state.offerColdStatistic, state.tableOptions.pagination, {
      sortBy: state.filters.sortBy,
      sortDesc: state.filters.sortDesc,
    }),
  salesTypes: state => state.salesTypes,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
  isOpenFilterColdStatisticPanel: state =>
    state.filtersColdOfferStatistic.clientOptions &&
    state.filtersColdOfferStatistic.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadOffers(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.OFFER_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await offerRepository.list(preparedFilters)

    context.commit('setOffersList', { data, filters })

    return { offerList: data.items, pagination: data.pagination }
  },
  updateStorageFilters({ commit, state }, filters) {
    commit('setStatisticFilters', filters)
    StorageService.set(storageKeys.OFFER_STATISTICS_FILTERS, filters)

    commit('setOfferStatistic', {
      data: state.offerStatistic,
      pagination: mockPagination(state.offerStatistic, filters),
      filters,
    })
  },
  async loadOffersStatistics({ commit, dispatch }, filters) {
    commit('setStatisticFilters', filters)
    StorageService.set(storageKeys.OFFER_STATISTICS_FILTERS, filters)
    commit('setIsLoadingOfferStatistic', true)

    let { data } = await offerRepository.statistic()

    commit('setOfferStatistic', {
      data,
      pagination: mockPagination(data, filters),
      filters,
    })

    if (!isEqual(defaultStatisticFilters.UIFilter, filters.UIFilter)) {
      data = await dispatch('filterOffersStatistic', { filters, skipReFetch: true })
    }

    commit('setIsLoadingOfferStatistic', false)
    return { offersStatistics: data }
  },
  async filterOffersStatistic({ state, dispatch }, payload) {
    const { skipReFetch, filters: outerFilters } = payload
    const filters = cloneDeep(outerFilters)

    // Check filter for empty values
    Object.keys(filters.UIFilter).forEach(key => {
      if (filters.UIFilter[key].id.value === null) return
      if (filters.UIFilter[key].id.value.trim() === '') {
        filters.UIFilter[key].id.value = null
      }
    })

    // Reset filters if it was submitted with no data
    if (isEqual(defaultStatisticFilters.UIFilter, filters.UIFilter)) {
      dispatch('resetFilters')
      return state.offerStatistic
    }

    // Skip re-fetching data and work with local data
    if (!skipReFetch) {
      await dispatch('loadOffersStatistics', filters)
    }

    // Save filters
    dispatch('updateStorageFilters', filters)

    const splitter = '|'
    const selectedCountries =
      filters.UIFilter?.country?.id?.value?.split(splitter)?.map(Number) || null
    const selectedOfferGroups =
      filters.UIFilter?.offerGroups?.id?.value?.split(splitter)?.map(Number) || null

    const filteredData = state.offerStatistic.reduce((acc, item) => {
      if (!selectedCountries && !selectedOfferGroups) return acc

      if (selectedCountries && selectedOfferGroups) {
        const countriesIntersection = selectedCountries.includes(item.country_id)

        const offerGroupIds = item.offer_groups.map(({ id }) => id)
        if (!offerGroupIds.length) return acc
        const offerGroupsIntersection = !!intersection(selectedOfferGroups, offerGroupIds).length

        countriesIntersection && offerGroupsIntersection ? acc.push(item) : null
        return acc
      }

      if (selectedCountries) {
        selectedCountries.includes(item.country_id) ? acc.push(item) : null
      }

      if (selectedOfferGroups) {
        const offerGroupIds = item.offer_groups.map(({ id }) => id)
        if (!offerGroupIds.length) return acc
        const offerGroupsIntersection = !!intersection(selectedOfferGroups, offerGroupIds).length
        offerGroupsIntersection ? acc.push(item) : null
      }

      return acc
    }, [])

    state.offerStatistic = filteredData
    return filteredData
  },
  resetFilters({ dispatch, state }) {
    dispatch('loadOffersStatistics', {
      ...defaultStatisticFilters,
      pagination: state.filtersStatistic.pagination,
    })
  },
  async loadColdOfferStatistic(context, filters) {
    context.commit('setColdStatisticFilters', filters)
    StorageService.set(storageKeys.OFFER_COLD_STATISTIC_FILTERS, filters)
    const { data } = await offerRepository.coldStatistic(filters.filter)
    context.commit('setOfferColdStatistic', {
      data,
      pagination: mockPagination(Object.values(data), filters),
      filters,
    })
    return { coldOffersStatistics: data }
  },

  updateSavedFilters(context, filters) {
    context.commit('setColdStatisticFilters', filters)
    StorageService.set(storageKeys.OFFER_COLD_STATISTIC_FILTERS, filters)

    context.commit('setOfferColdStatistic', {
      data: context.getters.offerColdStatistic,
      pagination: mockPagination(Object.values(context.getters.offerColdStatistic), filters),
      filters,
    })
  },

  async loadSalesType({ commit }) {
    try {
      const { data } = await salesTypeRepository.list()
      commit('setSalesTypes', data.items)
    } catch (e) {
      console.log(e)
    }
  },

  async searchSalesType(context, filters) {
    const { data } = await salesTypeRepository.list(filters)

    return {
      salesTypeList: data.items,
      pagination: data.pagination,
    }
  },

  async searchOfferList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await offerRepository.list(preparedFilters)

    return { offerList: data.items, pagination: data.pagination }
  },
  async loadOffer(context, id) {
    const {data} = await offerRepository.get(id)
    context.commit('setLoadedOffer', data)
    return {offer: data}
  },
  async loadOfferOneC(context, id) {
    const { data } = await offerRepository.getOneC(id)
    return { offer: data }
  },
  createOffer(context, payload) {
    return offerRepository.post(payload)
  },
  updateOffer(context, payload) {
    const { id, ...data } = payload
    return offerRepository.put(id, data)
  },
  deleteOffer(context, payload) {
    return offerRepository.delete(payload)
  },
  offersFiltersStorageReset() {
    StorageService.remove(storageKeys.OFFER_FILTERS)
  },
  updateOffersFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.OFFER_FILTERS, updatedFilters)
  },
  coldStatisticFiltersStorageReset() {
    StorageService.remove(storageKeys.OFFER_COLD_STATISTIC_FILTERS)
  },
  updateColdStatisticFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filtersColdOfferStatistic,
      clientOptions,
    }
    commit('setColdStatisticFilters', updatedFilters)
    StorageService.set(storageKeys.OFFER_COLD_STATISTIC_FILTERS, updatedFilters)
  },
}

const mutations = {
  setOffersList(state, { data, filters }) {
    state.offerList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setOfferStatistic(state, { data, pagination, filters }) {
    state.offerStatistic = [...data]
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setOfferColdStatistic(state, { data, pagination, filters }) {
    state.offerColdStatistic = Object.entries(data).map(item => {
      item[1].title = item[0]
      return item[1]
    })
    state.tableAdditionalProjectsOptions = new TableOptions(pagination, filters)
  },
  setColdStatisticFilters(state, filters) {
    state.filtersColdOfferStatistic = { ...filters }
  },
  setStatisticFilters(state, filters) {
    state.filtersStatistic = { ...filters }
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setIsLoadingOfferStatistic(state, bool) {
    state.isLoadingOfferStatistic = bool
  },
  setSalesTypes(state, data) {
    state.salesTypes = data
  },
  setPagination(state, pagination) {
    state.tableAdditionalProjectsOptions = {
      ...state.tableAdditionalProjectsOptions,
      page: pagination.page,
      pagination,
      totalItems: Math.ceil(state.tableAdditionalProjectsOptions.total / pagination.limit),
    }
  },
  setSort(state, { sortBy, sortDesc }) {
    state.tableAdditionalProjectsOptions = {
      ...state.tableAdditionalProjectsOptions,
      sortBy,
      sortDesc,
    }
  },
  setLoadedOffer(state, data) {
    state.offer = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'
import moment from 'moment'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    offer: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    status: 'trash',
    operator: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        value: '',
        operator: 'in',
      },
    },
    userGroups: null,
    createdAt: {
      value: `${moment('2023-09').startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${
        moment('2023-09').endOf('month').format(DATE_TIME_FORM_FULL_FORMAT,)}`,
      operator: 'bwn',
    },
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/CallCentreStatistics/helpers'
import orderReportRepository from '@/repository/admin/orderReportRepository'
import { mockPagination, omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'
import { formatDate } from '@/helpers/date'
import { DATE_FORMAT } from '@/constans/date'
import i18n from "@/plugins/vue-i18n";
const { saveAs } = require('file-saver')

const state = {
  filters: StorageService.get(storageKeys.CALL_CENTRE_FILTERS) || { ...defaultFilters },
  callCentreList: [],
  callCentreTotal: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  callCentreList: state => state.callCentreList,
  callCentreTotal: state => state.callCentreTotal,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadCallCentreList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.CALL_CENTRE_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilter = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilter,
    })
    const { data } = await orderReportRepository.callCentre(preparedFilters.filter)
    context.commit('setCallCentreList', {
      data,
      pagination: mockPagination(data.rows, filters),
      filters,
    })

    return { callCentreList: data.rows, callCentreTotal: data.total }
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.CALL_CENTRE_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.CALL_CENTRE_FILTERS, updatedFilters)
  },
  updateSavedFilters(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.CALL_CENTRE_FILTERS, filters)
    context.commit('setCallCentreList', {
      data: {
        rows: context.getters.callCentreList,
        total: context.getters.callCentreTotal,
      },
      pagination: mockPagination(context.getters.callCentreList, filters),
      filters,
    })
  },
  async loadExportFile(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const response = await orderReportRepository.callCentreExport(
      { ...preparedFilters.filter, xlsx: true },
      { responseType: 'blob' },
    )
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('callCentreStatistics.title')} ${formatDate(
      appliedFilters.dateFrom,
      DATE_FORMAT,
    )}-${formatDate(appliedFilters.dateTo, DATE_FORMAT)}.xlsx`
    saveAs(blob, filename)
    return response
  },
}

const mutations = {
  setCallCentreList(state, { data, filters, pagination }) {
    state.callCentreList = data.rows
    state.callCentreTotal = data.total
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

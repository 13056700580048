import StorageService from '@/services/storage.service'
import TableOptions from '@/models/TableOptions'
import storageKeys from '@/constans/storageKeys'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import campaignQueueRepository from '@/repository/call-centre/campaignQueueRepository'
import { defaultFilters } from '@/models/CampaignQueue/helpers'

const state = {
  filters: StorageService.get(storageKeys.CAMPAIGN_FILTERS_QUEUE) || {
    ...defaultFilters,
  },
  tableOptions: TableOptions.defaultTableOptions(),
  campaignQueueList: [],
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  campaignQueueList: state => state.campaignQueueList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadCampaignQueueList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.CAMPAIGN_FILTERS_QUEUE, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await campaignQueueRepository.list(preparedFilters)
    context.commit('setCampaignQueueList', { data, filters })

    return { campaignQueue: data.items, pagination: data.pagination }
  },
  async searchCampaignQueueList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await campaignQueueRepository.list(preparedFilters)

    return { campaignQueueList: data.items, pagination: data.pagination }
  },
}

const mutations = {
  setCampaignQueueList(state, { data, filters }) {
    state.campaignQueueList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

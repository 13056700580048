import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import orderLeadAssociatingRepository from '@/repository/admin/orderLeadAssociatingRepository'

const actions = {
  async searchOrderLeadAssociatingList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await orderLeadAssociatingRepository.list(preparedFilters)

    return { leadAssociatingList: data.items, pagination: data.pagination }
  },
  async loadOrderLeadAssociating(context, id) {
    const { data } = await orderLeadAssociatingRepository.get(id)
    return data
  },
  createOrderLeadAssociating(context, payload) {
    return orderLeadAssociatingRepository.post(payload)
  },
  updateOrderLeadAssociating(context, payload) {
    const { id, ...data } = payload
    return orderLeadAssociatingRepository.put(id, data)
  },
  deleteOrderLeadAssociating(context, payload) {
    return orderLeadAssociatingRepository.delete(payload)
  },
}

export default {
  namespaced: true,
  actions,
}

import StorageService from '@/services/storage.service'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import { defaultFilters } from '@/models/DeliveryWarehouse/helpers'
import deliveryWarehouseRepository from '@/repository/admin/deliveryWarehouseRepository'
import storageKeys from '@/constans/storageKeys'

const state = {
  filters: StorageService.get(storageKeys.DELIVERY_WAREHOUSE_FILTERS) || { ...defaultFilters },
  warehouseList: [],
  tableOptions: TableOptions.defaultTableOptions(),
  deliveryServiceStats: [],
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  warehouseList: state => state.warehouseList,
  deliveryServiceStats: state => state.deliveryServiceStats,

  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadWarehouseList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.DELIVERY_WAREHOUSE_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const { data } = await deliveryWarehouseRepository.list(preparedFilters)
    context.commit('setWarehouseList', { data, filters })

    return { warehouseList: data.items, pagination: data.pagination }
  },
  async searchWarehouseList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await deliveryWarehouseRepository.list(preparedFilters)

    return { warehouseList: data.items, pagination: data.pagination }
  },
  async loadWarehouse(context, id) {
    const { data } = await deliveryWarehouseRepository.get(id)
    return data
  },
  createWarehouse(context, payload) {
    return deliveryWarehouseRepository.post(payload)
  },
  updateWarehouse(context, payload) {
    const { id, ...data } = payload
    return deliveryWarehouseRepository.put(id, data)
  },
  deleteWarehouse(context, payload) {
    return deliveryWarehouseRepository.delete(payload)
  },
  async loadDeliveryServiceStats({ commit }, id) {
    const { data } = await deliveryWarehouseRepository.statistic({ id })
    commit('setDeliveryServiceStats', data)
    return data
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.DELIVERY_WAREHOUSE_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.DELIVERY_WAREHOUSE_FILTERS, updatedFilters)
  },
}

const mutations = {
  setWarehouseList(state, { data, filters }) {
    state.warehouseList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setDeliveryServiceStats(state, data) {
    state.deliveryServiceStats = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default [
  {
    path: '/logistics/hanging-ransom',
    name: 'pageHangingRansomLogistics',
    meta: { title: 'page.pageHangingRansom', roles: ['LOGISTIC_HANDING_RANSOM'] },
    component: () => import('@/pages/manager/hanging-ransom'),
  },
  {
    path: '/logistics/hanging-ransom/unpaid-orders',
    name: 'pageUnpaidOrdersLogistics',
    meta: { title: 'page.pageUnpaidOrders', roles: ['LOGISTIC_HANDING_RANSOM'] },
    component: () => import('@/pages/manager/hanging-ransom/unpaid-orders'),
  },
]

import StorageService from '@/services/storage.service'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import questionnaireAnswerRepo from '@/repository/admin/questionnaireAnswerRepository'
import { defaultFilters } from '@/models/QuestionnaireAnswer/helpers'
import storageKeys from '@/constans/storageKeys'

const state = {
  filters: StorageService.get(storageKeys.ANSWER_FILTERS) || { ...defaultFilters },
  answersList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  answersList: state => state.answersList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadAnswersList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.ANSWER_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await questionnaireAnswerRepo.list(preparedFilters)

    context.commit('setAnswer', { data, filters })

    return { answersList: data.items, pagination: data.pagination }
  },
  async loadAnswers(context, id) {
    const { data } = await questionnaireAnswerRepo.get(id)
    return data
  },
  createAnswers(context, payload) {
    return questionnaireAnswerRepo.post(payload)
  },
  updateAnswers(context, payload) {
    const { id, ...data } = payload
    return questionnaireAnswerRepo.put(id, data)
  },
  deleteAnswers(context, id) {
    return questionnaireAnswerRepo.delete(id)
  },
  deleteMassAnswers(context, payload) {
    return questionnaireAnswerRepo.questionnaireAnswerMassDelete(payload)
  },
}

const mutations = {
  setAnswer(state, { data, filters }) {
    state.answersList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

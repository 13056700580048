import dostavim from '@/router/notice-department/dostavim'
import reconfirmationStatistic from '@/router/notice-department/reconfirmation-statistic'
import smsCampaign from '@/router/notice-department/sms-campaign'
import orders from '@/router/notice-department/orders'
import orderImport from '@/router/notice-department/order-import'
import repeatedConfirm from '@/router/notice-department/repeated-confirm'
import smsChain from '@/router/notice-department/sms-chain'

const routers = [
  {
    path: '/notice-department',
    redirect: '/notice-department/dashboard',
    component: () => import('@/layouts/LayoutAdmin.vue'),
    children: [
      {
        path: '/notice-department/dashboard',
        name: 'noticeDepartmentDashboard',
        meta: {
          title: 'page.pageCallCentreManagerDashboard',
          roles: ['SIDEBAR_DASHBOARD_ORDER', 'SIDEBAR_DASHBOARD_CANCEL_NON_RANSOM'],
        },
        component: () => import('@/pages/manager/Dashboard'),
      },
      ...dostavim,
      ...reconfirmationStatistic,
      ...smsCampaign,
      ...orders,
      ...orderImport,
      ...repeatedConfirm,
      ...smsChain,
    ],
  },
]

export default routers

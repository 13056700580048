import moment from "moment";
import { DATE_FORM_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    userGroups: null,
    operators: null,
    offerGroups: null,
    offers: null,
    niches: null,
    salesTypes: null,
    salesModels: null,
    regions: null,
    deliveryTypes: null,
    deliveryServices: null,
    countries: null,
    dateFrom: moment('2023-09').startOf('month').format(DATE_FORM_FORMAT),
    dateTo: moment('2023-09').endOf('month').format(DATE_FORM_FORMAT)
  },
  sortBy: ['sort'],
  sortDesc: [false],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

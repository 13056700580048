import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'
import moment from 'moment'
import { DATE_FORM_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    dateFrom: moment('2023-09').startOf('month').format(DATE_FORM_FORMAT),
    dateTo: moment('2023-09').endOf('month').format(DATE_FORM_FORMAT),
    firstGroup: 'status_group',
    secondGroup: 'reject_reason',
    countries: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    regions: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    userGroups: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    operators: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    offerGroups: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    offers: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    niches: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    salesTypes: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    salesModels: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    filterDateBy: 'created_at',
    statuses: null,
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedRejectionFilters(filter) {
  const filters = cloneDeep(defaultFilters.filter)

  filters.countries.filter.id.value = joinBySymbol(filter.countries)
  filters.regions.filter.id.value = joinBySymbol(filter.regions)
  filters.userGroups.filter.id.value = joinBySymbol(filter.userGroups)
  filters.operators.filter.id.value = joinBySymbol(filter.operators)
  filters.offerGroups.filter.id.value = joinBySymbol(filter.offerGroups)
  filters.offers.filter.id.value = joinBySymbol(filter.offers)
  filters.niches.filter.id.value = joinBySymbol(filter.offerNiches)
  filters.salesTypes.filter.id.value = joinBySymbol(filter.salesTypes)
  filters.salesModels.filter.id.value = joinBySymbol(filter.offerSalesModels)
  filters.dateFrom = joinBySymbol(filter.dateFrom)
  filters.dateTo = joinBySymbol(filter.dateTo)
  filters.filterDateBy = joinBySymbol(filter.filterDateBy)
  filters.firstGroup = joinBySymbol(filter.firstGroup)
  filters.secondGroup = joinBySymbol(filter.secondGroup)
  filters.statuses = filter.statuses

  return filters
}

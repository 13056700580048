import StorageService from '@/services/storage.service'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import KlnSectionRepository from '@/repository/admin/klnSectionRepository'
import { defaultFilters } from '@/models/QuestionnaireKln/helper'
import { defaultFilters as filtersStatisticDefault } from '@/models/StatisticKln/helper'
import storageKeys from '@/constans/storageKeys'
import { cloneDeep } from 'lodash'

const state = {
  filters: StorageService.get(storageKeys.QUESTIONNAIRES_KNL_FILTERS) || { ...defaultFilters },
  filtersStatistics: StorageService.get(storageKeys.STATISTICS_KLN_FILTERS) || {
    ...filtersStatisticDefault,
  },
  questionnairesList: [],
  statistics: {},
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  filtersStatistics: state => state.filtersStatistics,
  tableOptions: state => state.tableOptions,
  questionnairesList: state => state.questionnairesList,
  statistics: state => state.statistics,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
  isOpenStatisticsFilterPanel: state =>
    state.filtersStatistics.clientOptions &&
    state.filtersStatistics.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadQuestionnairesList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.QUESTIONNAIRES_KNL_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await KlnSectionRepository.list(preparedFilters)

    context.commit('setQuestionnaires', { data, filters })

    return { questionnairesList: data.items, pagination: data.pagination }
  },

  async loadStatisticsReport(context, filters) {
    const localFilters = cloneDeep(filters)
    const requestBody = {}

    for (const key in localFilters.filter) {
      if (!localFilters.filter[key]) continue

      if (key === 'userGroups') {
        requestBody.groups = localFilters.filter[key]
      } else {
        requestBody[key] = localFilters.filter[key]
      }

      if (!Array.isArray(localFilters.filter[key])) continue
      if (key === 'dateFrom' || key === 'dateTo') continue
      localFilters.filter[key] = localFilters.filter[key].join('|')
    }

    context.commit('setFiltersStatistics', localFilters)
    StorageService.set(storageKeys.STATISTICS_KLN_FILTERS, localFilters)

    const { data } = await KlnSectionRepository.statisticsReport(requestBody)

    context.commit('setStatistics', data)

    return { statistics: data }
  },

  async searchQuestionnairesList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await KlnSectionRepository.list(preparedFilters)

    return { questionnairesList: data.items, pagination: data.pagination }
  },
  async loadItemsKlnSectionList() {
    const { data } = await KlnSectionRepository.getItems()
    return { itemsKlnSection: data }
  },
  async loadQuestionnaires(context, id) {
    const { data } = await KlnSectionRepository.get(id)
    return data
  },
  createQuestionnaires(context, payload) {
    return KlnSectionRepository.post(payload)
  },
  updateQuestionnaires(context, payload) {
    const { id, ...data } = payload
    return KlnSectionRepository.put(id, data)
  },
  deleteQuestionnaires(context, payload) {
    const { id, ...data } = payload
    return KlnSectionRepository.put(id, data)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.SALES_SCRIPT_FILTERS)
  },
  filtersStatisticStorageReset() {
    StorageService.remove(storageKeys.STATISTICS_KLN_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.QUESTIONNAIRES_KNL_FILTERS, updatedFilters)
  },

  updateFiltersClientOptionsStatistic({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filtersStatistics,
      clientOptions,
    }
    commit('setFiltersStatistics', updatedFilters)
    StorageService.set(storageKeys.STATISTICS_KLN_FILTERS, updatedFilters)
  },
}

const mutations = {
  setQuestionnaires(state, { data, filters }) {
    state.questionnairesList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setFiltersStatistics(state, filters) {
    state.filtersStatistics = { ...filters }
  },
  setStatistics(state, statistics) {
    state.statistics = statistics
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

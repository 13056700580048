import { camelCaseToKebab } from "@/helpers/string"
export function buildDOMStyles (items) {
  const css = buildClassesByStylesScript(items)
  const head = document.head || document.getElementsByTagName('head')[0]
  const styleOld = head.querySelector('[data-tiptap-class=""]')
  let styleNew = null
  if (!styleOld) {
    styleNew = document.createElement('style')
    styleNew.setAttribute('data-tiptap-class', '')
    styleNew.type = 'text/css'
    head.appendChild(styleNew)
    styleNew.innerHTML = css
  } else {
    styleOld.innerHTML = css
  }
}
export function buildClassesByStylesScript (items = []) {
  return items.reduce((cur, item) => {
    const className = `.predefined-style-${item.id}`
    const classStyles = Object.entries(item.styles).reduce((cur, [key, value]) => {
      return cur.concat(`${camelCaseToKebab(key)}: ${value}`)
    }, []).join('; ')
    const result = `${className} {${classStyles}}`
    cur += result
    return cur
  }, '')
}

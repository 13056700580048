import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/MetashipAccount/helpers'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import { MetashipShopRepository } from '@/repository/admin'

const state = {
  shopList: [],
  filters: StorageService.get(storageKeys.METASHIP_ACCOUNT_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  shopList: state => state.shopList,
}

const actions = {
  async loadMetashipShopList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.METASHIP_ACCOUNT_FILTERS, filters)
    const appliedFilters = omitEmptyDeepFilters(filters.filter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const { data } = await MetashipShopRepository.list(preparedFilters)
    context.commit('setList', { data, filters })
    return { products: data.items, pagination: data.pagination }
  },
  filtersStorageReset(context) {
    StorageService.remove(storageKeys.METASHIP_ACCOUNT_FILTERS)
    context.commit('setFilters', { ...defaultFilters })
    context.commit('removeList')
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.shopList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  removeList(state) {
    state.shopList = []
    state.tableOptions = new TableOptions()
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

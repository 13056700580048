import StorageService from '@/services/storage.service'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import { defaultFilters } from '@/models/City/helpers'
import cityRepository from '@/repository/common/cityRepository'
import storageKeys from '@/constans/storageKeys'

const state = {
  filters: StorageService.get(storageKeys.CITY_FILTERS) || {
    ...defaultFilters,
  },
  cityList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  cityList: state => state.cityList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadCityList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.CITY_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await cityRepository.list(preparedFilters)
    context.commit('setCityList', { data, filters })

    return { cityList: data.items, pagination: data.pagination }
  },
  async searchCityList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await cityRepository.list(preparedFilters)

    return { cityList: data.items, pagination: data.pagination }
  },
  async loadCity(context, id) {
    const { data } = await cityRepository.get(id)
    return data
  },
  createCity(context, payload) {
    return cityRepository.post(payload)
  },

  updateCity(context, payload) {
    const { id, ...data } = payload
    return cityRepository.put(id, data)
  },
  bulkUpdateCity(context, payload) {
    return cityRepository.bulkUpdate(payload)
  },
  bulkActivateCity(context, payload) {
    return cityRepository.bulkActivate(payload)
  },
  deleteCity(context, payload) {
    return cityRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.CITY_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.CITY_FILTERS, updatedFilters)
  },
}

const mutations = {
  setCityList(state, { data, filters }) {
    state.cityList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default [
  {
    path: '/notice-department/sms-chain',
    name: 'pageSmsChain',
    meta: { title: 'page.pageSmsChain', roles: ['SIDEBAR_NOTIFICATION_CHAIN_VIEW'] },
    component: () => import('@/pages/notice-department/sms-chain'),
  },
  {
    path: '/notice-department/sms-chain/create',
    name: 'pageSmsChainCreate',
    meta: { title: 'page.pageSmsChainCreate', roles: ['SIDEBAR_NOTIFICATION_CHAIN_VIEW'] },
    component: () => import('@/pages/notice-department/sms-chain/create'),
  },
  {
    path: '/notice-department/sms-chain/:id/edit',
    name: 'pageSmsChainEdit',
    meta: { title: 'page.pageSmsChainEdit', roles: ['SIDEBAR_NOTIFICATION_CHAIN_VIEW'] },
    component: () => import('@/pages/notice-department/sms-chain/id/edit'),
  },
]

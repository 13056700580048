export default [
  {
    path: '/logistics/orders',
    name: 'pageLogisticsOrders',
    meta: { title: 'page.pageLogisticsOrders', roles: ['LOGISTIC_ORDER_VIEW'] },
    component: () => import('@/pages/logistics/orders'),
  },
  {
    path: '/logistics/orders/create',
    name: 'pageLogisticsOrdersCreate',
    meta: { title: 'page.pageOrderCreate', roles: ['LOGISTIC_ORDER_CREATE'] },
    component: () => import('@/pages/logistics/orders/create'),
  },
  {
    path: '/logistics/orders/:id/edit',
    name: 'pageLogisticsOrdersEdit',
    meta: { title: 'page.pageOrderEdit', roles: ['LOGISTIC_ORDER_VIEW'] },
    component: () => import('@/pages/logistics/orders/id/edit'),
  },
]

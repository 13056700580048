export default [
  {
    path: '/settings/delivery-service',
    name: 'pageDeliveryServiceList',
    meta: { title: 'page.pageDeliveryService', roles: ['SETTING_DELIVERY_SERVICE_VIEW'] },
    component: () => import('@/pages/settings/delivery-service'),
  },
  {
    path: '/settings/delivery-service/create',
    name: 'pageDeliveryServiceCreate',
    meta: { title: 'page.pageDeliveryServiceCreate', roles: ['SETTING_DELIVERY_SERVICE_VIEW'] },
    component: () => import('@/pages/settings/delivery-service/create'),
  },
  {
    path: '/settings/delivery-service/:id/edit',
    name: 'pageDeliveryServiceEdit',
    meta: { title: 'page.pageDeliveryServiceEdit', roles: ['SETTING_DELIVERY_SERVICE_VIEW'] },
    component: () => import('@/pages/settings/delivery-service/id/edit'),
  },
]

export default [
  {
    path: '/settings/delivery-points-order',
    name: 'pageDeliveryPointsList',
    meta: { title: 'page.pageDeliveryPointsList', roles: ['SETTING_DELIVERY_POINT_VIEW'] },
    component: () => import('@/pages/settings/delivery-points'),
  },
  {
    path: '/settings/delivery-points-order/create',
    name: 'pageDeliveryPointsCreate',
    meta: { title: 'page.pageDeliveryPointsCreate', roles: ['SETTING_DELIVERY_POINT_VIEW'] },
    component: () => import('@/pages/settings/delivery-points/create'),
  },
  {
    path: '/settings/delivery-points-order/:id/edit',
    name: 'pageDeliveryPointsEdit',
    meta: { title: 'page.pageDeliveryPointsEdit', roles: ['SETTING_DELIVERY_POINT_VIEW'] },
    component: () => import('@/pages/settings/delivery-points/id/edit'),
  },
  {
    path: '/settings/delivery-points-order/import',
    name: 'pageDeliveryPointsImport',
    meta: { title: 'page.pageDeliveryPointsImport', roles: ['SETTING_DELIVERY_POINT_VIEW'] },
    component: () => import('@/pages/settings/delivery-points/import'),
  },
]

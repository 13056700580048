export default [
  {
    path: '/settings/ticket-types',
    name: 'pageTicketTypesList',
    meta: { title: 'page.pageTicketTypesList', roles: ['SETTING_TICKET_TYPE_VIEW'] },
    component: () => import('@/pages/settings/ticket-types'),
  },
  {
    path: '/settings/ticket-types/create',
    name: 'pageTicketTypesCreate',
    meta: { title: 'page.pageTicketTypesCreate', roles: ['SETTING_TICKET_TYPE_VIEW'] },
    component: () => import('@/pages/settings/ticket-types/create'),
  },
  {
    path: '/settings/ticket-types/:id/edit',
    name: 'pageTicketTypesEdit',
    meta: { title: 'page.pageTicketTypesEdit', roles: ['SETTING_TICKET_TYPE_VIEW'] },
    component: () => import('@/pages/settings/ticket-types/id/edit'),
  },
]

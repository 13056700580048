import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    country: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    product: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    active: 1,
    addToMail: null,
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedProductGiftFilters(filter) {
  const filters = cloneDeep(defaultFilters.filter)

  filters.country.id.value = joinBySymbol(filter.countries)
  filters.product.id.value = joinBySymbol(filter.products)
  filters.addToMail = filter.addToMail
  filters.active = filter.active

  return filters
}

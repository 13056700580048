export const defaultFiltersView = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    enabled: 1,
  },
  sortBy: ['id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

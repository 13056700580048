import { joinBySymbol } from '@/helpers/filters'
import moment from 'moment/moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  custom_filter: {
    attribute: [],
  },
  filter: {
    status: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    city: {
      id: {
        value: '',
        operator: 'in',
      },
      district: {
        id: {
          value: '',
          operator: 'in',
        },
        region: {
          id: {
            value: '',
            operator: 'in',
          },
          country: {
            id: {
              value: '',
              operator: 'in',
            },
          },
        },
      },
    },
    operator: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    offer: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      niche: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    tags: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    partnerId: {
      value: '',
      operator: 'in',
    },
    landing: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    deliveryService: {
      id: {
        value: '',
        operator: 'in',
      },
      type: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    statusReason: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    products: {
      product: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    createdAt: {
      value: `${moment('2023-09').startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${
        moment('2023-09').endOf('month').format(DATE_TIME_FORM_FULL_FORMAT,)}`,
      operator: 'bwn',
    },
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
    selectedColumns: [],
  },
}

export function prepareOrderReportFilters(filter) {
  const filters = {}
  filters.countries = [...joinBySymbol(filter.countries).split('|')]
  filters.regions = [...joinBySymbol(filter.regions).split('|')]
  filters.districts = [...joinBySymbol(filter.districts).split('|')]
  filters.cities = [...joinBySymbol(filter.cities).split('|')]
  return filters
}

import SuperRepository from '../superRepository'

export default new (class DashboardManagerRepository extends SuperRepository {
  baseUri() {
    return 'dashboard_manager'
  }
  deliveryStatistic = async params =>
    this.httpClient().get(`${this.baseUri()}/delivery_service_order`, params)
  deliveryServiceDebt = async params =>
    this.httpClient().get(`${this.baseUri()}/delivery_service_debt`, params)
  orderAndApprove = async params =>
    this.httpClient().get(`${this.baseUri()}/order_and_approved`, params)
  hangingRansom = async params => this.httpClient().get(`${this.baseUri()}/hanging_ransom`, params)
  approvePensioner = async params =>
    this.httpClient().get(`${this.baseUri()}/approve_pensioner`, params)
  noRansom72hours = async params =>
    this.httpClient().get(`${this.baseUri()}/no_ransom_72_hours`, params)
  approvesByTime = async params =>
    this.httpClient().get(`${this.baseUri()}/approve_by_date_time`, params)
  averageOrderStatusTime = async params =>
    this.httpClient().get(`${this.baseUri()}/avg_status_time`, params)
})()

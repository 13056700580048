import { defaultFilters } from '@/models/Currencies/helpers'
import currenciesRepository from '@/repository/common/currenciesRepository'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'

const state = {
  filters: { ...defaultFilters },
  currenciesList: [],
  tableOptions: TableOptions.defaultTableOptions(),
  activeItem: null,
  loadingData: false,
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  currenciesList: state => state.currenciesList,
  activeItem: state => state.activeItem,
  loadingData: state => state.loadingData,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadCurrenciesList({ commit }, filters) {
    commit('setLoadingData', true)

    filters && commit('setFilters', filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await currenciesRepository.list(preparedFilters)

    commit('setCurrenciesList', { data, filters })

    commit('setLoadingData', false)
    return { countryList: data.items, pagination: data.pagination }
  },

  async updateCurrency({ dispatch }, payload) {
    const { id, name, isoCode } = payload
    await currenciesRepository.put(id, { name, isoCode })
    dispatch('loadCurrenciesList', { ...defaultFilters })
  },
}

const mutations = {
  setCurrenciesList(state, { data, filters }) {
    state.currenciesList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setActiveItem(state, payload) {
    state.activeItem = payload
  },

  setLoadingData(state, payload) {
    state.loadingData = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import StopWordsRepo from "@/repository/admin/stopWordsRepositoty";

const state = {
}

const getters = {
}


const actions = {
  async loadStopWordsList () {
    const {data} = await StopWordsRepo.list()
    return data
  },
  updateStopWordsList (context, payload) {
    return StopWordsRepo.put(payload)
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    active: 1,
    manager: {
      id: {
        value: '',
        operator: 'in',
      },
    },
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedDeliveryWarehouseFilters(filter) {
  const filters = cloneDeep(defaultFilters.filter)
  filters.manager.id.value = joinBySymbol(filter.managers)
  filters.active = filter.active

  return filters
}

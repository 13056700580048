export default [
  {
    path: '/settings/payment-profit',
    name: 'pagePaymentProfitList',
    meta: { title: 'page.pagePaymentProfitList', roles: ['SETTING_SALARY_PROFIT_VIEW'] },
    component: () => import('@/pages/settings/payment-profit'),
  },
  {
    path: '/settings/payment-profit/create',
    name: 'pagePaymentProfitCreate',
    meta: { title: 'page.pagePaymentProfitCreate', roles: ['SETTING_SALARY_PROFIT_VIEW'] },
    component: () => import('@/pages/settings/payment-profit/create'),
  },
  {
    path: '/settings/payment-profit/:id/edit',
    name: 'pagePaymentProfitEdit',
    meta: { title: 'page.pagePaymentProfitEdit', roles: ['SETTING_SALARY_PROFIT_VIEW'] },
    component: () => import('@/pages/settings/payment-profit/id/edit'),
  },
]

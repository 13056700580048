export default [
  {
    path: '/call-centre/export-reports',
    name: 'exportReportsCallCentre',
    meta: {
      title: 'page.exportReports',
      roles: ['CC_REPORT_EXPORT_ORDER', 'CC_REPORT_EXPORT_KLN_RESULT'],
    },
    component: () => import('@/pages/settings/DashboardReports'),
  },
]

export default [
  {
    path: '/settings/product-gift',
    name: 'pageProductGiftList',
    meta: { title: 'page.pageProductGiftList', roles: ['SETTING_PRODUCT_CATALOG_VIEW'] },
    component: () => import('@/pages/settings/product-gift'),
  },
  {
    path: '/settings/product-gift/create',
    name: 'pageProductGiftCreate',
    meta: { title: 'page.pageProductGiftCreate', roles: ['SETTING_PRODUCT_CATALOG_VIEW'] },
    component: () => import('@/pages/settings/product-gift/create'),
  },
  {
    path: '/settings/product-gift/:id/edit',
    name: 'pageProductGiftEdit',
    meta: { title: 'page.pageProductGiftEdit', roles: ['SETTING_PRODUCT_CATALOG_VIEW'] },
    component: () => import('@/pages/settings/product-gift/id/edit'),
  },
]

export default [
  {
    path: '/manager/delivery-tax',
    name: 'pageDeliveryTaxList',
    meta: { title: 'page.pageDeliveryTaxList', roles: ['MNG_COMMISSION_AND_TAXES'] },
    component: () => import('@/pages/manager/delivery-tax'),
  },
  {
    path: '/manager/delivery-tax/import',
    name: 'pageDeliveryTaxImport',
    meta: { title: 'page.pageDeliveryTaxImport' },
    component: () => import('@/pages/manager/delivery-tax/import'),
  },
]

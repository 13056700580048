export const defaultFilters = ({ date }) => ({
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    user: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    date: {
      value: date,
      operator: 'bwn',
    },
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
})

// import deliveryCourierRu from './deliveryCourier'
// import deliveryPointsRu from './deliveryPointsOrder'
// import deliveryPostRu from './deliveryPost'
import averageCheck from '@/router/logistics/average-check'
import deliveryServiceAnalytic from './deliveryServiceAnalytic'
import legalPersonsStats from './legalPersonsStats'
import orders from './orders'
import orderImport from './order-import'
import hangingRansom from '@/router/logistics/hanging-ransom'
import exportReports from '@/router/logistics/export-reports'
import buybackDynamics from '@/router/logistics/buyback-dynamics'
import metashipAccounts from '@/router/settings/metaship-accounts'

const routers = [
  {
    path: '/logistics',
    redirect: '/logistics/dashboard',
    component: () => import('@/layouts/LayoutAdmin.vue'),
    children: [
      {
        path: '/logistics/dashboard',
        name: 'logisticsDashboard',
        meta: {
          title: 'page.pageLogisticsManagerDashboard',
          roles: [
            'LOGISTIC_DASHBOARD_HANDING_RANSOM',
            'LOGISTIC_DASHBOARD_CANCEL_NON_RANSOM',
            'LOGISTIC_DASHBOARD_DELIVERY_SERVICE_SHIPMENT',
            'LOGISTIC_DASHBOARD_DELIVERY_SERVICE_DEBT',
            'LOGISTIC_DASHBOARD_AVERAGE_TIME_IN_STATUS',
          ],
        },
        component: () => import('@/pages/manager/Dashboard'),
      },
      // ...deliveryCourierRu,
      // ...deliveryPointsRu,
      // ...deliveryPostRu,
      ...buybackDynamics,
      ...deliveryServiceAnalytic,
      ...exportReports,
      ...hangingRansom,
      ...legalPersonsStats,
      ...orders,
      ...orderImport,
      ...averageCheck,
      ...metashipAccounts,
    ],
  },
]
export default routers

export default [
  {
    path: '/settings/sales-script-template',
    name: 'pageSalesScriptTemplateList',
    meta: { title: 'page.pageSalesScriptTemplateList', roles: ['SETTING_SCRIPT_TEMPLATE_VIEW'] },
    component: () => import('@/pages/settings/sales-script-template'),
  },
  {
    path: '/settings/sales-script-template/create',
    name: 'pageSalesScriptTemplateCreate',
    meta: { title: 'page.pageSalesScriptTemplateCreate', roles: ['SETTING_SCRIPT_TEMPLATE_VIEW'] },
    component: () => import('@/pages/settings/sales-script-template/create'),
  },
  {
    path: '/settings/sales-script-template/:id/edit',
    name: 'pageSalesScriptTemplateEdit',
    meta: { title: 'page.pageSalesScriptTemplateEdit', roles: ['SETTING_SCRIPT_TEMPLATE_VIEW'] },
    component: () => import('@/pages/settings/sales-script-template/id/edit'),
  },
]

const state = {
  isOktellConnectionEstablished: false,
  isOktellConnectionError: false,
  currentlyOnCall: false,
  openCallModal: false,
  openPreCallModal: false,
  oktellOrder: null,
  callModalWidth: 0
}

const getters = {
  isOktellConnectionEstablished: state => state.isOktellConnectionEstablished,
  isOktellConnectionError: state => state.isOktellConnectionError,
  currentlyOnCall: state => state.currentlyOnCall,
  openCallModal: state => state.openCallModal,
  openPreCallModal: state => state.openPreCallModal,
  oktellOrder: state => state.oktellOrder,
  callModalWidth: state => state.callModalWidth,

}

const actions = {}

const mutations = {
  setIsOktellConnectionEstablished(state, data) {
    state.isOktellConnectionEstablished = data
  },
  setCurrentlyOnCall(state, data) {
    state.currentlyOnCall = data
  },
  setOpenPreCallModal(state, data) {
    state.openPreCallModal = data
  },
  openCallModal(state, data) {
    state.openCallModal = data
  },
  setOpenCallModal(state, data) {
    state.openCallModal = data
  },
  setIsOktellConnectionError(state, data) {
    state.isOktellConnectionError = data
  },
  setOktellOrder(state, data) {
    state.oktellOrder = {...data}
  },
  setCallModalWidth(state, data) {
    state.callModalWidth = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

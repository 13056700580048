import smsSenderRepository from '@/repository/call-centre/smsSenderRepository'

const state = {
  smsSenderList: [],
}

const getters = {
  smsSenderList: state => state.smsSenderList,
}

const actions = {
  createSmsSender(context, payload) {
    return smsSenderRepository.post(payload)
  },
  async loadSmsSender(context, id) {
    const { data } = await smsSenderRepository.get(id)
    return { data }
  },
  async updateSmsSender(context, payload) {
    const { id, ...data } = payload
    return smsSenderRepository.put(id, data)
  },
  async loadOffersWithout(context, payload) {
    const { data } = await smsSenderRepository.offersWithout(payload)
    return data
  },
}

const mutations = {
  setSmsSenderList(state, { data }) {
    state.smsSenderList = [...data.items]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

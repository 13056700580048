import SuperRepository from '../superRepository'

export default new (class klnAssessmentRepository extends SuperRepository {
  baseUri() {
    return 'kln_assessment'
  }
  export = async (params = {}) => this.httpClient().getFile(`${this.baseUri()}/export`, params)
  report = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/summary_point_report`, data, params)
})()

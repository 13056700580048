export default [
  {
    path: '/call-centre/kln-operator-assessment',
    name: 'pageKlnOperatorAssessmentList',
    meta: { title: 'page.pageKlnOperatorAssessmentList', roles: ['CC_KLN_OPERATOR_VIEW'] },
    component: () => import('@/pages/call-centre/kln-operator-assessment'),
  },
  {
    path: '/call-centre/kln-operator-assessment/create',
    name: 'pageKlnOperatorAssessmentCreate',
    meta: { title: 'page.pageKlnOperatorAssessmentCreate', roles: ['CC_KLN_OPERATOR_VIEW'] },
    component: () => import('@/pages/call-centre/kln-operator-assessment/create'),
  },
  {
    path: '/call-centre/kln-operator-assessment/:id/edit',
    name: 'pageKlnOperatorAssessmentEdit',
    meta: { title: 'page.pageKlnOperatorAssessmentEdit', roles: ['CC_KLN_OPERATOR_VIEW'] },
    component: () => import('@/pages/call-centre/kln-operator-assessment/id/edit'),
  },
]

export default class TableOptions {
  constructor(pagination, filters) {
    if (!pagination) {
      this.search = ''
      this.sortBy = []
      this.sortDesc = []
      this.pagination = {
        page: 1,
        limit: 20,
      }
      this.page = 1
      this.totalItems = 1
      this.total = 1
      this.itemsPerPage = 20
    } else {
      if (filters.cfFilter) {
        const searchKeys = Object.keys(filters.cfFilter[0])
        this.search = searchKeys.length ? filters.cfFilter[0][searchKeys[0]].value : ''
      } else if (filters.search) {
        this.search = filters.search
      } else {
        this.search = ''
      }

      this.sortBy = filters.sortBy || []
      this.sortDesc = filters.sortDesc || []
      this.pagination = {
        page: pagination.currentPage,
        limit: pagination.limit,
      }
      this.itemsPerPage = pagination.limit
      this.page = pagination.currentPage
      this.totalItems = pagination.maxPageNumber
      this.total = pagination.total
    }
  }

  static defaultTableOptions() {
    return new TableOptions()
  }

  removeDefaultSortValues(valuesArray = []) {
    valuesArray.forEach(value => {
      const valueIndex = this.sortBy.indexOf(value)
      this.sortDesc = this.sortDesc.filter((value, index) => index !== valueIndex)
    })

    this.sortBy = this.sortBy.filter(value => !valuesArray.includes(value))
  }
}

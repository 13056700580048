export default [
  {
    path: '/manager/prepayment',
    name: 'pagePrepaymentList',
    meta: { title: 'page.pagePrepayment', roles: ['MNG_OPERATOR_SALARY_ADVANCE_1'] },
    component: () => import('@/pages/manager/prepayment'),
  },
  {
    path: '/manager/prepayment-two',
    name: 'pagePrepaymentTwoList',
    meta: { title: 'page.pagePrepaymentTwo', roles: ['MNG_OPERATOR_SALARY_ADVANCE_2'] },
    component: () => import('@/pages/manager/prepayment-two'),
  },
]

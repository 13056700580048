export default [
  {
    path: '/settings/metaship-accounts',
    name: 'pageMetashipAccounts',
    meta: { title: 'page.pageMetashipAccounts', roles: ['SETTING_METASHIP_VIEW'] },
    component: () => import('@/pages/settings/metaship-accounts/index.vue'),
  },
  {
    path: '/settings/metaship-accounts/create',
    name: 'pageMetashipAccountCreate',
    meta: { title: 'page.pageMetashipAccountsCreate', roles: ['SETTING_METASHIP_VIEW'] },
    component: () => import('@/pages/settings/metaship-accounts/create.vue'),
  },
  {
    path: '/settings/metaship-accounts/:id/edit',
    name: 'pageMetashipAccountEdit',
    meta: { title: 'page.pageMetashipAccountsEdit', roles: ['SETTING_METASHIP_VIEW'] },
    component: () => import('@/pages/settings/metaship-accounts/id/edit.vue'),
  },
]

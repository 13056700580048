import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/OfferRating/helpers'
import offerRatingRepository from '@/repository/admin/offerRatingRepository'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'

const state = {
  offerRatingList: [],
  filters: StorageService.get(storageKeys.OFFER_RATING_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  offerRatingList: state => state.offerRatingList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadOfferRatingList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.OFFER_RATING_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await offerRatingRepository.list(preparedFilters)

    context.commit('setOfferRatingList', { data, filters })

    return { offerRatingList: data.items, pagination: data.pagination }
  },
  async searchOfferRatingList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await offerRatingRepository.list(preparedFilters)

    return { offerRatingList: data.items, pagination: data.pagination }
  },
  async loadOfferRating(context, id) {
    const { data } = await offerRatingRepository.get(id)
    return { offer: data }
  },
  createOfferRating(context, payload) {
    return offerRatingRepository.post(payload)
  },
  updateOfferRating(context, payload) {
    const { id, ...data1 } = payload
    return offerRatingRepository.put(id, data1)
  },
  deleteOfferRating(context, payload) {
    return offerRatingRepository.delete(payload)
  },
}

const mutations = {
  setOfferRatingList(state, { data, filters }) {
    state.offerRatingList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

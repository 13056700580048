import SuperRepository from '../superRepository'

export default new (class CampaingRepository extends SuperRepository {
  baseUri() {
    return 'campaign'
  }
  offersWithout = async (data, params) =>
    this.httpClient().post(`${this.baseUri()}/offers-without-related`, data, params)

  createManual = async (data, params) =>
    this.httpClient().post(`${this.baseUri()}/manual`, data, params)
})()

import districtImportRepository from '@/repository/admin/districtImportRepository'
const { saveAs } = require('file-saver')
import i18n from '@/plugins/vue-i18n'
function renderHeaders(data) {
  if (!data) return []
  return Array.isArray(data) && data.length
    ? Object.keys(data[0]).map((key, index) => {
        return {
          text: i18n.t(`districtImport.field.${key}`),
          sortable: false,
          value: key,
          index: index,
        }
      })
    : []
}

const state = {
  districtPreviewList: [],
  districtPreviewHeaders: [],
}

const getters = {
  districtPreviewList: state => state.districtPreviewList,
  districtPreviewHeaders: state => state.districtPreviewHeaders,
}

const actions = {
  async loadExportFile() {
    const response = await districtImportRepository.export()
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = 'Шаблон импорта районов.xlsx'
    saveAs(blob, filename)
    return response
  },
  async importFile(context, payload) {
    const { formData, config } = payload
    return await districtImportRepository.import(formData, config)
  },
  async loadPreviewFile(context, payload) {
    const { formData, config } = payload
    const { data } = await districtImportRepository.preview(formData, config)

    const headers = renderHeaders(data)

    context.commit('setDistrictPreviewHeaders', headers)
    context.commit('setDistrictPreviewList', data)

    return {
      districtPreviewList: data,
      districtPreviewHeaders: headers,
    }
  },
}

const mutations = {
  setDistrictPreviewList(state, data) {
    state.districtPreviewList = data
  },
  setDistrictPreviewHeaders(state, data) {
    state.districtPreviewHeaders = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/Tickets/helpers'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TicketsRepository from '@/repository/admin/ticketsRepository'

const state = {
  filters: StorageService.get(storageKeys.OFFER_TICKETS_FILTERS) || { ...defaultFilters },
  ticketList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  ticketList: state => state.ticketList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}
const actions = {
  async loadTicketsList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.OFFER_TICKETS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)
    const appliedCustomFilters = omitEmptyDeepFilters(filters.custom_filter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
      custom_filter: appliedCustomFilters,
    })
    const { data } = await TicketsRepository.list(preparedFilters)
    context.commit('setTicketsList', { data, filters })

    return { ticketList: data.items, pagination: data.pagination }
  },
  async loadTicket(context, id) {
    const { data } = await TicketsRepository.get(id)
    return data
  },
  createTicket(context, payload) {
    return TicketsRepository.post(payload)
  },
  updateTicket(context, payload) {
    const { id, ...data } = payload
    return TicketsRepository.put(id, data)
  },
  bulkCreateTickets(context, payload) {
    return TicketsRepository.bulkCreate(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.OFFER_TICKETS_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.OFFER_TICKETS_FILTERS, updatedFilters)
  },
}

const mutations = {
  setTicketsList(state, { data, filters }) {
    state.ticketList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default [
  {
    path: '/notice-department/repeated-confirm',
    name: 'pageRepeatedConfirm',
    meta: { title: 'page.pageRepeatedConfirm', roles: ['SIDEBAR_REPEAT_CONFIRM'] },
    component: () => import('@/pages/notice-department/repeated-confirm'),
  },
  {
    path: '/notice-department/repeated-confirm/create',
    name: 'pageRepeatedConfirmCreate',
    meta: { title: 'page.pageCampaignCreate', roles: ['SIDEBAR_REPEAT_CONFIRM'] },
    component: () => import('@/pages/notice-department/repeated-confirm/create'),
  },
  {
    path: '/notice-department/repeated-confirm/:id/edit',
    name: 'pageRepeatedConfirmEdit',
    meta: { title: 'page.pageRepeatedConfirmEdit', roles: ['SIDEBAR_REPEAT_CONFIRM'] },
    component: () => import('@/pages/notice-department/repeated-confirm/id/edit'),
  },
]

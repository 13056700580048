import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/DeliveryInterval/helpers'
import TableOptions from '@/models/TableOptions'
import deliveryIntervalRepository from '@/repository/admin/deliveryIntervalRepository'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'

const state = {
  filters: StorageService.get(storageKeys.DELIVERY_INTERVAL_FILTERS) || { ...defaultFilters },
  deliveryIntervalList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  deliveryIntervalList: state => state.deliveryIntervalList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadDeliveryIntervalList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.DELIVERY_INTERVAL_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await deliveryIntervalRepository.list(preparedFilters)
    context.commit('setDeliveryIntervalList', { data, filters })

    return { deliveryIntervalList: data.items, pagination: data.pagination }
  },
  async loadDeliveryInterval(context, id) {
    const { data } = await deliveryIntervalRepository.get(id)
    return data
  },
  createDeliveryInterval(context, payload) {
    return deliveryIntervalRepository.post(payload)
  },
  updateDeliveryInterval(context, payload) {
    const { id, ...data } = payload
    return deliveryIntervalRepository.put(id, data)
  },
  deleteDeliveryInterval(context, payload) {
    return deliveryIntervalRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.DELIVERY_INTERVAL_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.DELIVERY_INTERVAL_FILTERS, updatedFilters)
  },
}

const mutations = {
  setDeliveryIntervalList(state, { data, filters }) {
    state.deliveryIntervalList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

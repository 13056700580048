export default [
  {
    path: '/manager/call-center-price',
    name: 'pageCallCenterPrice',
    meta: { title: 'page.pageCallCenterPrice', roles: ['MNG_CC_COST'] },
    component: () => import('@/pages/manager/call-center-price'),
  },
  {
    path: '/manager/call-center-price/import',
    name: 'pageCallCenterPriceImport',
    meta: { title: 'page.pageCallCenterPriceImport', roles: ['MNG_CC_COST'] },
    component: () => import('@/pages/manager/call-center-price/import'),
  },
]

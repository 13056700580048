export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

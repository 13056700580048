import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'
import moment from 'moment/moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    createdAt: {
      value: `${moment('2023-09').startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${moment('2023-09')
          .endOf('month').format(DATE_TIME_FORM_FULL_FORMAT,)}`,
      operator: 'bwn',
    },
    status: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    user: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
  },
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedCallControllerFilters(filter) {
  const filters = cloneDeep(defaultFilters.filter)
  filters.user.id = joinBySymbol(filter.user)
  filters.status = joinBySymbol(filter.status)
  return filters
}

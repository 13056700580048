export default [
  {
    path: '/settings/user-penalty',
    name: 'pageUserPenaltyList',
    meta: { title: 'page.pageUserPenaltyList', roles: ['SETTING_USER_PENALTY_VIEW'] },
    component: () => import('@/pages/settings/user-penalty'),
  },
  {
    path: '/settings/user-penalty/create',
    name: 'pageUserPenaltyCreate',
    meta: { title: 'page.pageUserPenaltyCreate', roles: ['SETTING_USER_PENALTY_VIEW'] },
    component: () => import('@/pages/settings/user-penalty/create'),
  },
  {
    path: '/settings/user-penalty/:id/edit',
    name: 'pageUserPenaltyEdit',
    meta: { title: 'page.pageUserPenaltyEdit', roles: ['SETTING_USER_PENALTY_VIEW'] },
    component: () => import('@/pages/settings/user-penalty/id/edit'),
  },
]

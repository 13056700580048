export default [
  {
    path: '/call-centre/tickets',
    name: 'pageTicketsList',
    meta: { title: 'page.pageTicketsList', roles: ['CC_TICKET_VIEW'] },
    component: () => import('@/pages/call-centre/tickets'),
  },
  {
    path: '/call-centre/tickets/create',
    name: 'pageTicketCreate',
    meta: { title: 'page.pageTicketCreate', roles: ['CC_TICKET_VIEW'] },
    component: () => import('@/pages/call-centre/tickets/create'),
  },
  {
    path: '/call-centre/tickets/:id/edit',
    name: 'pageTicketEdit',
    meta: { title: 'page.pageTicketEdit', roles: ['CC_TICKET_VIEW'] },
    component: () => import('@/pages/call-centre/tickets/id/edit'),
  },
]

import userGroupTypeRepo from '@/repository/admin/userGroupTypeRepository'
import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/UserGroupType/helpers'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'

const state = {
  filters: StorageService.get(storageKeys.USER_GROUP_TYPE_FILTERS) || { ...defaultFilters },
  userGroupTypeList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  userGroupTypeList: state => state.userGroupTypeList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadUserGroupTypeList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.USER_GROUP_TYPE_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const { data } = await userGroupTypeRepo.list(preparedFilters)

    context.commit('setUserGroupTypeList', { data, filters })

    return { userGroupTypeList: data.items, pagination: data.pagination }
  },
  async searchUserGroupTypeList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await userGroupTypeRepo.list(preparedFilters)

    return { userGroupTypeList: data.items, pagination: data.pagination }
  },
  async loadUserGroupType(context, id) {
    const { data } = await userGroupTypeRepo.get(id)
    return { userGroupType: data }
  },
  createUserGroupType(context, payload) {
    return userGroupTypeRepo.post(payload)
  },
  updateUserGroupType(context, payload) {
    const { id, ...data } = payload
    return userGroupTypeRepo.put(id, data)
  },
  deleteUserGroupType(context, payload) {
    return userGroupTypeRepo.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.USER_GROUP_TYPE_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.USER_GROUP_TYPE_FILTERS, updatedFilters)
  },
}

const mutations = {
  setUserGroupTypeList(state, { data, filters }) {
    state.userGroupTypeList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

import moment from 'moment/moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultOrderFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    createdAt: {
      value: `${moment('2023-09').startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${moment('2023-09')
          .endOf('month').format(DATE_TIME_FORM_FULL_FORMAT,)}`,
      operator: 'bwn',
    },
    country: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    offer: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    deliveryService: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    status: {
      id: {
        value: 'delivered|partially_paid',
        operator: 'in',
      },
    },
  },
  clientOptions: {
    filterPanelExpanded: false,
  },
}

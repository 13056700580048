export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    deliveryPoint: {
      id: {
        value: '',
        operator: 'in',
      },
      deliveryService: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      city: {
        id: {
          value: '',
          operator: 'in',
        },
        district: {
          id: {
            value: '',
            operator: 'in',
          },
          region: {
            id: {
              value: '',
              operator: 'in',
            },
            country: {
              id: {
                value: '',
                operator: 'in',
              },
            },
          },
        },
      },
    },
    active: 1,
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

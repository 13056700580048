export default [
  {
    path: '/settings/user-group',
    name: 'pageUserGroupList',
    meta: { title: 'page.pageUserGroupList', roles: ['SETTING_USER_GROUP_VIEW'] },
    component: () => import('@/pages/settings/user-group'),
  },
  {
    path: '/settings/user-group/create',
    name: 'pageUserGroupCreate',
    meta: { title: 'page.pageUserGroupCreate', roles: ['SETTING_USER_GROUP_VIEW'] },
    component: () => import('@/pages/settings/user-group/create'),
  },
  {
    path: '/settings/user-group/:id/edit',
    name: 'pageUserGroupEdit',
    meta: { title: 'page.pageUserGroupEdit', roles: ['SETTING_USER_GROUP_VIEW'] },
    component: () => import('@/pages/settings/user-group/id/edit'),
  },
]

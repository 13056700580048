import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/AverageCheck/helpers'
import orderReportRepository from '@/repository/admin/orderReportRepository'
import { mockPagination, omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'
import { formatDate } from '@/helpers/date'
import { DATE_FORMAT } from '@/constans/date'
import i18n from "@/plugins/vue-i18n";
const { saveAs } = require('file-saver')

const state = {
  filters: StorageService.get(storageKeys.ORDER_AVERAGE_CHECK) || { ...defaultFilters },
  averageCheckList: [],
  averageCheckListTotal: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  averageCheckList: state => state.averageCheckList,
  averageCheckListArray: state =>
    Object.entries(state.averageCheckList).map(item => {
      item[1].title = item[0]
      return item[1]
    }),
  averageCheckListTotal: state => state.averageCheckListTotal,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadAverageCheckList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.ORDER_AVERAGE_CHECK, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilter = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilter,
    })
    const { data } = await orderReportRepository.averageCheck(preparedFilters.filter)

    context.commit('setAverageCheckList', {
      data,
      pagination: mockPagination(Object.values(data.rows), filters),
      filters,
    })

    return { averageCheckList: data }
  },
  async loadExportFile(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const response = await orderReportRepository.averageCheckExport(
      { ...preparedFilters.filter, xlsx: true },
      { responseType: 'blob' },
    )
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('averageCheck.title')} ${formatDate(appliedFilters.dateFrom, DATE_FORMAT)}-${formatDate(
      appliedFilters.dateTo,
      DATE_FORMAT,
    )}.xlsx`
    saveAs(blob, filename)
    return response
  },
  updateSavedFilters(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.ORDER_AVERAGE_CHECK, filters)

    context.commit('setAverageCheckList', {
      data: {
        rows: context.getters.averageCheckList,
        total: context.getters.averageCheckListTotal,
      },
      pagination: mockPagination(Object.values(context.getters.averageCheckList), filters),
      filters,
    })
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.ORDER_AVERAGE_CHECK)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.ORDER_AVERAGE_CHECK, updatedFilters)
  },
}

const mutations = {
  setAverageCheckList(state, { data, filters, pagination }) {
    state.averageCheckList = data.rows
    state.averageCheckListTotal = data.total
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default [
  {
    path: '/settings/offer-group',
    name: 'pageOfferGroupList',
    meta: { title: 'page.pageOfferGroupList', roles: ['SETTING_OFFER_GROUP_VIEW'] },
    component: () => import('@/pages/settings/offer-group'),
  },
  {
    path: '/settings/offer-group/create',
    name: 'pageOfferGroupCreate',
    meta: { title: 'page.pageOfferGroupCreate', roles: ['SETTING_OFFER_GROUP_VIEW'] },
    component: () => import('@/pages/settings/offer-group/create'),
  },
  {
    path: '/settings/offer-group/:id/edit',
    name: 'pageOfferGroupEdit',
    meta: { title: 'page.pageOfferGroupEdit', roles: ['SETTING_OFFER_GROUP_VIEW'] },
    component: () => import('@/pages/settings/offer-group/id/edit'),
  },
]

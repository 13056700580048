export default [
  {
    path: '/call-centre/order-operator',
    name: 'pageOrderOperatorList',
    meta: { title: 'page.pageOrderOperatorList', roles: ['CC_ORDER_OP_VIEW'] },
    component: () => import('@/pages/call-centre/order-operator'),
  },
  {
    path: '/call-centre/order-operator/create',
    name: 'pageOrderOperatorCreate',
    meta: { title: 'page.pageOrderOperatorCreate', roles: ['CC_ORDER_OP_CREATE'] },
    component: () => import('@/pages/call-centre/order-operator/create'),
  },
  {
    path: '/call-centre/order-operator/:id/edit',
    name: 'pageOrderOperatorEdit',
    meta: { title: 'page.pageOrderOperatorEdit', roles: ['CC_ORDER_OP_VIEW'] },
    component: () => import('@/pages/call-centre/order-operator/id/edit'),
  },
]

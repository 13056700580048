import SuperRepository from '../superRepository'

export default new (class UserRepository extends SuperRepository {
  baseUri() {
    return 'user'
  }
  bulkUpdate = async (data, params) =>
    this.httpClient().put(`${this.baseUri()}/bulk_update`, data, params)
  bulkActiveStatusUpdate = async (data, params) =>
    this.httpClient().put(`${this.baseUri()}/bulk_activate`, data, params)
  penalty = async params => this.httpClient().get(`${this.baseUri()}/payment_and_hold`, params)
  bulkPenaltyUpdate = async (data, params) =>
    this.httpClient().put(`${this.baseUri()}/payment_and_penalty_bulk_create`, data, params)
})()

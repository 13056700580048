import SalaryBonusRepository from '@/repository/admin/salaryBonusRepository'
const { saveAs } = require('file-saver')
import i18n from '@/plugins/vue-i18n'
function renderHeaders(data) {
  if (!data) return []
  return Array.isArray(data) && data.length
    ? Object.keys(data[0]).map((key, index) => {
        return {
          text: i18n.t(`salaryBonus.field.${key}`),
          sortable: false,
          value: key,
          index: index,
        }
      })
    : []
}

const state = {
  salaryBonusList: [],
  salaryBonusHeaders: [],
}

const getters = {
  salaryBonusList: state => state.salaryBonusList,
  salaryBonusHeaders: state => state.salaryBonusHeaders,
}

const actions = {
  async loadExportFile() {
    const response = await SalaryBonusRepository.export()
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('salaryBonus.exportFileName')}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async importFile(context, payload) {
    const { formData } = payload
    return await SalaryBonusRepository.import(formData)
  },
  async loadPreviewFile(context, payload) {
    const { formData } = payload
    const { data } = await SalaryBonusRepository.preview(formData)

    const headers = renderHeaders(data)

    context.commit('setSalaryBonusHeaders', headers)
    context.commit('setSalaryBonusList', data)

    return {
      salaryBonusList: data,
      salaryBonusHeaders: headers,
    }
  },
}

const mutations = {
  setSalaryBonusList(state, data) {
    state.salaryBonusList = data
  },
  setSalaryBonusHeaders(state, data) {
    state.salaryBonusHeaders = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import averageCheck from '@/router/manager/average-check'
import efficiencyAdditionalProjects from '@/router/manager/additional-projects'
import hangingRansom from '@/router/manager/hanging-ransom'
import prepayment from '@/router/manager/prepayment'
import prepaymentTeamlead from '@/router/manager/prepayment-teamlead'
import webAnalytica from '@/router/manager/web-analytica'
import exportReports from '@/router/manager/export-reports'
import userSalary from '@/router/manager/user-salary'
import buybackDynamics from '@/router/manager/buyback-dynamics'
import deliveryTax from '@/router/manager/delivery-tax'
import logisticPrice from '@/router/manager/logistic-price'
import callCenterPrice from '@/router/manager/call-center-price'
import financialReport from '@/router/manager/financial-report'

const routers = [
  {
    path: '/manager',
    redirect: '/manager/dashboard',
    component: () => import('@/layouts/LayoutAdmin.vue'),
    children: [
      {
        path: '/manager/dashboard',
        name: 'managerDashboard',
        meta: {
          title: 'page.pageManagerDashboard',
          roles: [
            'MNG_DASHBOARD_ORDER',
            'MNG_DASHBOARD_HANDING_RANSOM',
            'MNG_DASHBOARD_CANCEL_NON_RANSOM',
            'MNG_DASHBOARD_DELIVERY_SERVICE_SHIPMENT',
            'MNG_DASHBOARD_APPROVE_PERCENT',
            'MNG_DASHBOARD_DELIVERY_SERVICE_DEBT',
            'MNG_DASHBOARD_AVERAGE_TIME_IN_STATUS',
            'MNG_DASHBOARD_APPROVE_BY_TIME',
          ],
        },
        component: () => import('@/pages/manager/Dashboard'),
      },
      ...averageCheck,
      ...buybackDynamics,
      ...callCenterPrice,
      ...deliveryTax,
      ...efficiencyAdditionalProjects,
      ...exportReports,
      ...hangingRansom,
      ...logisticPrice,
      ...prepayment,
      ...prepaymentTeamlead,
      ...webAnalytica,
      ...userSalary,
      ...financialReport,
    ],
  },
]
export default routers

export default [
  {
    path: '/settings/offer-niche',
    name: 'pageOfferNicheList',
    meta: { title: 'page.pageOfferNicheList', roles: ['SETTING_OFFER_NICHE_VIEW'] },
    component: () => import('@/pages/settings/offer-niche'),
  },
  {
    path: '/settings/offer-niche/create',
    name: 'pageOfferNicheCreate',
    meta: { title: 'page.pageOfferNicheCreate', roles: ['SETTING_OFFER_NICHE_VIEW'] },
    component: () => import('@/pages/settings/offer-niche/create'),
  },
  {
    path: '/settings/offer-niche/:id/edit',
    name: 'pageOfferNicheEdit',
    meta: { title: 'page.pageOfferNicheEdit', roles: ['SETTING_OFFER_NICHE_VIEW'] },
    component: () => import('@/pages/settings/offer-niche/id/edit'),
  },
]

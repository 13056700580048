export default [
  {
    path: '/notice-department/order-import',
    name: 'pageOrderNoticeDepartmentImport',
    meta: {
      title: 'page.pageOrderImport',
      roles: ['SIDEBAR_ORDER_IMPORT_FILE'],
    },
    component: () => import('@/pages/notice-department/order-import'),
  },
]

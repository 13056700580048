import SuperRepository from '../superRepository'

export default new class OrderRepository extends SuperRepository {
  baseUri () {
    return 'order_import'
  }
  export = async (params = {}) => this.httpClient().getFile(`${this.baseUri()}/export`, params);
  import = async (data, params) => this.httpClient().postFile(`${this.baseUri()}/import`, data, params);
  preview = async (data, params) => this.httpClient().postFile(`${this.baseUri()}/preview`, data, params);
}

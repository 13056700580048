export default [
  {
    path: '/settings/delivery-interval',
    name: 'pageDeliveryIntervalList',
    meta: { title: 'page.pageDeliveryIntervalList', roles: ['SETTING_DELIVERY_INTERVAL_VIEW'] },
    component: () => import('@/pages/settings/delivery-interval'),
  },
  {
    path: '/settings/delivery-interval/create',
    name: 'pageDeliveryIntervalCreate',
    meta: { title: 'page.pageDeliveryIntervalCreate', roles: ['SETTING_DELIVERY_INTERVAL_VIEW'] },
    component: () => import('@/pages/settings/delivery-interval/create'),
  },
  {
    path: '/settings/delivery-interval/:id/edit',
    name: 'pageDeliveryIntervalEdit',
    meta: { title: 'page.pageDeliveryIntervalEdit', roles: ['SETTING_DELIVERY_INTERVAL_VIEW'] },
    component: () => import('@/pages/settings/delivery-interval/id/edit'),
  },
]

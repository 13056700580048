export default [
  {
    path: '/call-centre/my-tickets',
    name: 'pageMyTicketsList',
    meta: { title: 'page.pageMyTicketsList' },
    component: () => import('@/pages/call-centre/my-tickets'),
  },
  {
    path: '/call-centre/my-tickets/create',
    name: 'pageMyTicketCreate',
    meta: { title: 'page.pageMyTicketCreate' },
    component: () => import('@/pages/call-centre/my-tickets/create'),
  },
  {
    path: '/call-centre/my-tickets/:id/edit',
    name: 'pageMyTicketEdit',
    meta: { title: 'page.pageMyTicketEdit' },
    component: () => import('@/pages/call-centre/my-tickets/id/edit'),
  },
]

import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/MetashipAccounts/helpers'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'
import metashipAccountRepository from '@/repository/admin/metashipAccountRepository'

const state = {
  filters: StorageService.get(storageKeys.METASHIP_ACCOUNTS_FILTERS) || { ...defaultFilters },
  metashipAccountsList: [],
  tableOptions: TableOptions.defaultTableOptions(),
  loadingData: false,
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  metashipAccountsList: state => state.metashipAccountsList,
  loadingData: state => state.loadingData,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadMetashipAccountsList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.METASHIP_ACCOUNTS_FILTERS, filters)
    context.commit('setLoadingData', true)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await metashipAccountRepository.list(preparedFilters)
    context.commit('setMetashipAccountsList', { data, filters })
    context.commit('setLoadingData', false)

    return { metashipAccountsList: data.items, pagination: data.pagination }
  },
  async searchMetashipAccountsList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await metashipAccountRepository.list(preparedFilters)

    return { metashipAccountsList: data.items, pagination: data.pagination }
  },
  async loadMetashipAccounts(context, id) {
    const { data } = await metashipAccountRepository.get(id)
    return data
  },
  createMetashipAccounts(context, payload) {
    return metashipAccountRepository.post(payload)
  },
  updateMetashipAccounts(context, payload) {
    const { id, ...data } = payload
    return metashipAccountRepository.put(id, data)
  },
  synchronizeMetashipShops(context, payload) {
    return metashipAccountRepository.synchronizeMetashipShops(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.METASHIP_ACCOUNTS_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.METASHIP_ACCOUNTS_FILTERS, updatedFilters)
  },
}

const mutations = {
  setMetashipAccountsList(state, { data, filters }) {
    state.metashipAccountsList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setLoadingData(state, payload) {
    state.loadingData = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default [
  {
    path: '/call-centre/questionnaires-order',
    name: 'pageQuestionnairesOrderList',
    meta: { title: 'page.pageQuestionnairesOrderList', roles: ['CC_QUESTIONNAIRE'] },
    component: () => import('@/pages/call-centre/questionnaires-order'),
  },
  {
    path: '/call-centre/questionnaires-order/:id/:name',
    name: 'pageQuestionnairesOrderEdit',
    meta: { title: 'page.pageQuestionnairesOrderEdit', roles: ['CC_QUESTIONNAIRE'] },
    component: () => import('@/pages/call-centre/questionnaires-order/id/edit'),
  },
]

import i18n from '@/plugins/vue-i18n'

export const ORDER_SOURCE = {
  CPA: 'cpa',
  MANUAL: 'manual',
  IMPORT_FILE: 'import_file',
  CAMPAIGN: 'campaign',
}

export const ORDER_SOURCE_TRANSACTION = {
  [ORDER_SOURCE.CPA]: i18n.tc('order.sourceTitle.cpa'),
  [ORDER_SOURCE.MANUAL]: i18n.tc('order.sourceTitle.manual'),
  [ORDER_SOURCE.IMPORT_FILE]: i18n.tc('order.sourceTitle.importFile'),
  [ORDER_SOURCE.CAMPAIGN]: i18n.tc('order.sourceTitle.campaign'),
}

const generator = require('generate-password-browser')
export function isObject(val) {
  return typeof val === 'object' && !Array.isArray(val) && val !== null
}

export function setData(obj, data) {
  Object.keys(obj).forEach(propName => {
    if (
      Object.prototype.hasOwnProperty.call(obj, propName) &&
      Object.prototype.hasOwnProperty.call(data, propName)
    ) {
      if (data[propName] !== null && data[propName] !== undefined) {
        obj[propName] = data[propName]
      }
    }
  })
}
export function getPrepareData(data = {}, keys = []) {
  const res = JSON.parse(JSON.stringify(data))
  keys.forEach(key => {
    if (res[key] && isObject(res[key])) {
      res[key] = res[key].id
    }
    if (res[key] && Array.isArray(res[key])) {
      res[key] = res[key].map(m => m.id)
    }
  })
  return res
}

export function generateUUID(possibleArray = []) {
  const randomUUID = crypto?.randomUUID()
  let dt = new Date().getTime()
  const res =
    randomUUID ||
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
  if (possibleArray.some(uuid => uuid === res)) {
    throw new Error('Value must be unique')
  }
  return res
}

export function generatePassword(length = 30) {
  return generator.generate({
    length,
    numbers: true,
    uppercase: true,
    lowercase: true,
    symbols: '#?!@$%^&*-',
    strict: true,
  })
}

export function getUniqueArray(array, key = 'id') {
  return array.reduce(
    (acc, option) => {
      if (acc.map[option[key]])
        // если данный город уже был
        return acc // ничего не делаем, возвращаем уже собранное

      acc.map[option[key]] = true // помечаем город, как обработанный
      acc.items.push(option) // добавляем объект в массив городов
      return acc // возвращаем собранное
    },
    {
      map: {}, // здесь будут отмечаться обработанные города
      items: [], // здесь конечный массив уникальных городов
    },
  ).items // получаем конечный массив
}

export function getStringFromArray(array, key = 'name') {
  return array.map(option => option[key]).join(', ')
}

export const randomString = (function () {
  const buffer = new Uint8Array(32)
  let index
  let bitIndex
  const max = window.BigInt(buffer.length)

  const reset = function () {
    index = 0n
    bitIndex = 0n
    crypto.getRandomValues(buffer)
  }
  reset()

  const getBits = function (count) {
    let bits = 0n
    while (count > 0n) {
      const todo = count < 8n - bitIndex ? count : 8n - bitIndex
      count -= todo
      bits = bits << todo
      bits += (window.BigInt(buffer[index]) >> bitIndex) & ((1n << todo) - 1n)
      bitIndex += todo
      if (bitIndex === 8n) {
        bitIndex = 0n
        index++
      }
      if (index === max) reset()
    }
    return bits
  }

  const countBits = function (num) {
    let bitCount = 0n
    while (num > 0n) {
      bitCount++
      num = num >> 1n
    }
    return bitCount
  }

  const getN = function (max, bitCount) {
    if (max <= 0n) throw new Error('this does not compute unless you want an infinite loop')
    let out

    do {
      out = getBits(bitCount)
    } while (out >= max)

    return out
  }

  return function (input, count) {
    let wasNumber = false
    let wasString = false

    switch (typeof input) {
      default: {
        throw new Error('unsupported input')
      }
      case 'number':
        {
          wasNumber = true
          input = window.BigInt(input)
        }
        break
      case 'bigint': {
        const out = getN(input, countBits(max))
        return wasNumber ? Number(out) : out
      }
      case 'string':
        {
          wasString = true
          input = input.split('')
        }
        break
      case 'object': {
        if (!Array.isArray(input)) {
          throw new Error('objects are not supported here')
        }
        if (typeof count != 'number' && typeof count != 'bigint') {
          throw new Error('you need to specify a count')
        }
        const contentCount = window.BigInt(input.length)
        const bitCount = countBits(contentCount)
        const out = [...Array(count)].map(() => input[getN(contentCount, bitCount)])
        return wasString ? out.join('') : out
      }
    }
  }
})()

export const splitArr = (arr, subArrLengths, reverse = false) => {
  let arrayIndex = -1
  let preparedArr = reverse ? Array.from(arr).reverse() : Array.from(arr)

  return preparedArr.reduce((final, curr, i) => {
    if (i % subArrLengths === 0) {
      final.push([curr])
      arrayIndex++
    } else {
      final[arrayIndex].push(curr)
    }

    return final
  }, [])
}

// Number functions
export const copyMaskedNumber = (maskedNumber, initialNumber) => {
  const numbers = {
    maskedNumber: '+' + maskedNumber,
    initialNumber: initialNumber,
  }
  navigator.clipboard.writeText(numbers.maskedNumber)
  localStorage.setItem('copiedNumber', JSON.stringify(numbers))
}

export const getMaskedNumberCopy = (number, reverse = false) => {
  const copiedNumberData = JSON.parse(localStorage.getItem('copiedNumber'))
  if (!copiedNumberData) return null

  if (!reverse) {
    if (copiedNumberData.maskedNumber === number) return copiedNumberData.initialNumber
  } else {
    if (copiedNumberData.initialNumber === number) return copiedNumberData.maskedNumber
  }

  return null
}

export const isInitialPhoneNumber = number => {
  const copiedNumberData = JSON.parse(localStorage.getItem('copiedNumber'))
  if (!copiedNumberData) return false
  return copiedNumberData.initialNumber === number
}

export const getHiddenPhoneValueForSearch = searchString => {
  const validNumber = getMaskedNumberCopy(searchString)
  if (validNumber) return validNumber
  return searchString
}


export const toQueryString = (obj) => {
  const str = [];
  for (const p in obj)
      // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

import StorageService from '@/services/storage.service'
import TableOptions from '@/models/TableOptions'
import {
  hasAppliedFilters,
  omitEmptyDeepFilters,
  omitEmptyFilters,
  prepareFilters,
} from '@/helpers/filters'
import orderRepository from '@/repository/admin/orderRepository'
import orderHistoryRepository from '@/repository/admin/orderHistoryRepository'
import { defaultFilters, prepareOrderReportFilters } from '@/models/Order/helpers'
import { defaultFiltersSuspicious } from '@/models/OrderSuspicious/helpers'
import { defaultFiltersAutocall } from '@/models/OrderAutocall/helpers'
import storageKeys from '@/constans/storageKeys'
import { DATE_DAY_FORMAT } from '@/constans/date'
const { saveAs } = require('file-saver')
import { formatDate } from '@/helpers/date'
import { hasPermission } from '@/helpers/permission'
import i18n from '@/plugins/vue-i18n'
import { ORDER_SOURCE } from '@/constans/orderSources'
import phoneCodesRepository from '@/repository/admin/phoneCodesRepository'

const state = {
  filters: StorageService.get(storageKeys.ORDER_FILTERS) || { ...defaultFilters },
  filtersSuspicious: StorageService.get(storageKeys.ORDER_SUSPICIOUS_FILTERS) || {
    ...defaultFiltersSuspicious,
  },
  filtersAutoCall: StorageService.get(storageKeys.ORDER_AUTOCALL_FILTERS) || {
    ...defaultFiltersAutocall,
  },
  orderList: [],
  orderSuspiciousList: [],
  orderAutoCallList: [],
  order: null,
  tableOptions: TableOptions.defaultTableOptions(),
  orderWebList: [],
  selectedColumns: null,
  isEdit: false,
  loadingData: false,
  countrySetting: null,
  isOperator: false,
  isLogistic: false,
  isNotice: false,
  isAutoDialing: false,
  autodetectPhoneList: [],
}

const getters = {
  filters: state => state.filters,
  filtersSuspicious: state => state.filtersSuspicious,
  filtersAutoCall: state => state.filtersAutoCall,
  tableOptions: state => state.tableOptions,
  orderList: state => state.orderList,
  orderSuspiciousList: state => state.orderSuspiciousList,
  orderAutoCallList: state => state.orderAutoCallList,
  order: state => state.order,
  hasAppliedFilters: state => {
    return hasAppliedFilters(state.filters)
  },
  orderWebList: state => state.orderWebList,
  isEdit: state => state.isEdit,
  isOperator: state => state.isOperator,
  isLogistic: state => state.isLogistic,
  isNotice: state => state.isNotice,
  isAutoDialing: state => state.isAutoDialing,
  prefixPermission: (state, getters) => {
    if (getters.isOperator) return 'CC_ORDER_OP_'
    if (getters.isLogistic) return 'LOGISTIC_ORDER_'
    if (getters.isNotice) return 'SIDEBAR_ORDER_'
    if (getters.isAutoDialing) return 'CC_AUTO_DIALING_'
    return 'CC_ORDER_'
  },
  canEditOrder: (state, getters) => {
    if (!getters.isEdit) return false
    return hasPermission([`${getters.prefixPermission}OPERATE`])
  },
  canCreateOrder: (state, getters) => {
    if (getters.isEdit) return false
    return hasPermission([`${getters.prefixPermission}CREATE`])
  },
  canImport: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}IMPORT_FILE`])
  },
  canViewPhone: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}PHONE_VIEW`])
  },
  canViewLog: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}LOG_VIEW`])
  },
  canInitCall: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}INIT_CALL`])
  },
  canInitSms: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}INIT_SMS`])
  },
  canViewReasonStatusAll: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}STATUS_REASON_ALL`])
  },
  canViewReasonStatusOfOperators: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}STATUS_REASON_OPERATOR`])
  },
  canChangeReasonStatusApproved: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}STATUS_APPROVED`])
  },
  canChangeReasonStatusCallBack: (state, getters) => {
    return hasPermission([
      `${getters.prefixPermission}STATUS_CALLBACK_COMMON`,
      `${getters.prefixPermission}STATUS_CALLBACK_OPERATOR_SELF`,
      `${getters.prefixPermission}STATUS_CALLBACK_OPERATOR_ALL`,
    ])
  },
  hasPermissionCommonStatusCallBack: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}STATUS_CALLBACK_COMMON`])
  },
  hasPermissionOperatorSelfStatusCallBack: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}STATUS_CALLBACK_OPERATOR_SELF`])
  },
  hasPermissionOperatorAllStatusCallBack: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}STATUS_CALLBACK_OPERATOR_ALL`])
  },
  canChangeReasonStatusIncorrect: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}STATUS_INCORRECT`])
  },
  canChangeReasonStatusTrash: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}STATUS_TRASH`])
  },
  canChangeReasonStatusCanceled: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}STATUS_CANCELED`])
  },
  canChangeReasonStatusCanceledRC: (state, getters) => {
    return hasPermission([`${getters.prefixPermission}STATUS_CANCELED_RC`])
  },
  canVisibleStatuses: (state, getters) => {
    return hasPermission([
      `${getters.prefixPermission}STATUS_APPROVED`,
      `${getters.prefixPermission}STATUS_INCORRECT`,
      `${getters.prefixPermission}STATUS_TRASH`,
      `${getters.prefixPermission}STATUS_CANCELED`,
      `${getters.prefixPermission}STATUS_CALLBACK_COMMON`,
      `${getters.prefixPermission}STATUS_CALLBACK_OPERATOR_SELF`,
      `${getters.prefixPermission}STATUS_CALLBACK_OPERATOR_ALL`,
    ])
  },
  selectedColumns: state => state.selectedColumns,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
  loadingData: state => state.loadingData,
  isSourceCPA: state => {
    const { source } = state.order || {}
    return source === ORDER_SOURCE.CPA
  },
  isSourceCampaign: state => {
    const { source } = state.order || {}
    return source === ORDER_SOURCE.CAMPAIGN
  },
  isSourceImport: state => {
    const { source } = state.order || {}
    return source === ORDER_SOURCE.IMPORT_FILE
  },
  isSourceManual: state => {
    const { source } = state.order || {}
    return source === ORDER_SOURCE.MANUAL
  },
  countrySetting: state => state.countrySetting,
  autodetectPhoneList: state => state.autodetectPhoneList,
}

const actions = {
  async loadOrderList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.ORDER_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await orderRepository.list(preparedFilters)

    context.commit('setOrderList', { data, filters })
    return { orderList: data.items, pagination: data.pagination }
  },
  async loadSuspiciousOrderList(context, filters) {
    context.commit('setSuspiciousFilters', filters)
    StorageService.set(storageKeys.ORDER_SUSPICIOUS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await orderRepository.list(preparedFilters)

    context.commit('setSuspiciousOrderList', { data, filters })
    return { orderSuspiciousList: data.items, pagination: data.pagination }
  },
  async loadAutoCallList(context, filters) {
    context.commit('setAutocallFilters', filters)
    StorageService.set(storageKeys.ORDER_AUTOCALL_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await orderRepository.list(preparedFilters)

    context.commit('setAutoCallList', { data, filters })
    return { orderAutoCallList: data.items, pagination: data.pagination }
  },
  async searchOrderList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await orderRepository.list(preparedFilters)

    return { orderList: data.items, pagination: data.pagination }
  },
  async loadOrder(context, id) {
    const { data } = await orderRepository.get(id)
    context.commit('setOrder', data)
    return data
  },
  async loadAdditionalPhone(context, id) {
    const { data } = await orderRepository.loadAdditionalPhone(id)
    return data
  },
  async loadQuestionnaireByOrder(context, id) {
    const { data } = await orderRepository.loadOrderQuestionnaire(id)
    return data
  },
  async loadPhoneInfoList(context, id) {
    const { data } = await orderRepository.loadPhoneInfo(id)
    return data
  },
  async loadOrderHistory(context, payload) {
    const { id, params } = payload
    const appliedFilters = omitEmptyDeepFilters(params.filter)
    const appliedCfFilters = omitEmptyDeepFilters(params.cfFilter)

    const preparedFilters = prepareFilters({
      ...params,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await orderRepository.loadOrderHistory({ id: id, params: preparedFilters })

    return { orderHistoryList: data.items || [], pagination: data.pagination }
  },
  async loadLogHistory(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await orderHistoryRepository.list(preparedFilters)

    return { orderHistoryList: data.items || [], pagination: data.pagination }
  },
  async orderReport(context, payload) {
    const appliedFilters = omitEmptyFilters(payload)

    const response = await orderRepository.report(appliedFilters, { responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.t('orderReportFileName.orders')} ${formatDate(
      appliedFilters.dateFrom,
      DATE_DAY_FORMAT,
    )}-${formatDate(payload.dateTo, DATE_DAY_FORMAT)}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async createPackerTaskPDF(context, payload) {
    const response = await orderRepository.createPackerTask(
      {
        ...payload,
        xlsx: false,
      },
      { responseType: 'blob' },
    )
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('order.packerTask')} ${formatDate(new Date(), DATE_DAY_FORMAT)}.pdf`
    saveAs(blob, filename)
    return response
  },
  async createPackerTaskXLSX(context, payload) {
    const response = await orderRepository.createPackerTask(
      {
        ...payload,
        xlsx: true,
      },
      { responseType: 'blob' },
    )

    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('order.packerTask')} ${formatDate(
      new Date(),
      DATE_DAY_FORMAT,
    )}.xlsx`
    saveAs(blob, filename)
    return response
  },
  createOrder(context, payload) {
    return orderRepository.post(payload)
  },
  bulkUpdateOrder(context, payload) {
    return orderRepository.bulkUpdate(payload)
  },
  bulkUpdateSuspiciousOrder(context, payload) {
    return orderRepository.suspiciousBulkUpdate(payload)
  },
  updateOrder(context, payload) {
    const { id, ...data } = payload
    return orderRepository.put(id, data)
  },
  deleteOrder(context, payload) {
    return orderRepository.delete(payload)
  },

  setSelectedColumns({ commit }, payload) {
    const filters = StorageService.get(storageKeys.ORDER_FILTERS)
    filters.clientOptions.selectedColumns = payload
    commit('setFilters', filters)
    StorageService.set(storageKeys.ORDER_FILTERS, filters)

    commit('setSelectedColumns', payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.ORDER_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.ORDER_FILTERS, updatedFilters)
  },
  async ordersWithoutBarcode(context, payload) {
    const appliedFilters = prepareOrderReportFilters(payload)
    const response = await orderRepository.ordersWithoutBarcodeReport(appliedFilters, {
      responseType: 'blob',
    })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('reportWithoutBarcode.downloadFileName')}.xlsx`
    saveAs(blob, filename)
    return response
  },
  reCalculateOrderPrice(context, { products = [], deliveryIncluded = false }) {
    const { deliveryCost = 0 } = context.state.countrySetting || {}
    const amountProducts = products.reduce((cur, item) => cur + (+item.amount || 0), 0)

    if (deliveryIncluded) return amountProducts
    return amountProducts + deliveryCost
  },
  async disputeStart(context, id) {
    const { data } = await orderRepository.startDispute(id)
    return data
  },
  async sendSms(context, payload) {
    const { id, params } = payload
    await orderRepository.sendSms({ id: id, params: params })
  },
  async helpFromTeamLead(context, id) {
    const { data } = await orderRepository.helpNotification(id)
    return data
  },
  async autoDetectPhone(context, payload) {
    const preparedFilters = prepareFilters({
      ...payload,
    })

    const { data } = await phoneCodesRepository.autodetectPhone(preparedFilters)
    context.commit('setAutoDetectPhone', { data })

    return { autodetectPhoneList: data.result }
  },
}

const mutations = {
  setStateEditOrder(state, payload) {
    state.isEdit = payload
  },
  setOrder(state, payload) {
    state.order = payload
  },
  setOrderList(state, { data, filters }) {
    state.orderList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setAutoDetectPhone(state, { data }) {
    state.autodetectPhoneList = data.result
  },
  setSuspiciousOrderList(state, { data, filters }) {
    state.orderSuspiciousList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setAutoCallList(state, { data, filters }) {
    state.orderAutoCallList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setSuspiciousFilters(state, filters) {
    state.filtersSuspicious = { ...filters }
  },
  setAutocallFilters(state, filters) {
    state.filtersAutoCall = { ...filters }
  },
  setOrderWebList(state, payload) {
    state.orderWebList = payload
  },
  setSelectedColumns(state, payload) {
    state.selectedColumns = payload
  },
  setLoadingData(state, payload) {
    state.loadingData = payload
  },
  setCountrySettingOrder(state, payload) {
    state.countrySetting = payload
  },
  setOperator(state, payload) {
    state.isOperator = payload
  },
  setLogistic(state, payload) {
    state.isLogistic = payload
  },
  setNotice(state, payload) {
    state.isNotice = payload
  },
  setAutoDialing(state, payload) {
    state.isAutoDialing = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import deliveryWarehouse1CRepository from '@/repository/admin/deliveryWarehouse1CRepository'

const state = {}

const getters = {}

const actions = {
  async loadWarehouse1C(context, id) {
    const { data } = await deliveryWarehouse1CRepository.get(id)
    return data
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

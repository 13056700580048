
import {omitEmptyDeepFilters, prepareFilters} from "@/helpers/filters";
import offerSalesModelRepository from "@/repository/admin/offerSalesModelRepository";

const state = {
}

const getters = {
}

const actions = {
  async searchOfferSalesModelList (context, filters) {

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters
    })

    const { data } = await offerSalesModelRepository.list(preparedFilters)

    return { offerSalesModelList: data.items, pagination: data.pagination }
  },
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

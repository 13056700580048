export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    abnormal: 1,
    user: {
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    userType: null,
    type: null,
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

import SuperRepository from '../superRepository'

export default new (class deliveryServiceRepo extends SuperRepository {
  baseUri() {
    return 'delivery_service'
  }
  syncMetashipShop = async (id, params = {}) =>
    this.httpClient().post(`${this.baseUri()}/${id}/synchronize`, params)
  listAvailable = async params =>
    this.httpClient().get(`${this.baseUri()}/available_services`, params)
})()

export default [
  {
    path: '/settings/city',
    name: 'pageCityList',
    meta: { title: 'page.pageCityList', roles: ['SETTING_CITY_VIEW'] },
    component: () => import('@/pages/settings/city'),
  },
  {
    path: '/settings/city/create',
    name: 'pageCityCreate',
    meta: { title: 'page.pageCityCreate', roles: ['SETTING_CITY_VIEW'] },
    component: () => import('@/pages/settings/city/create'),
  },
  {
    path: '/settings/city/:id/edit',
    name: 'pageCityEdit',
    meta: { title: 'page.pageCityEdit', roles: ['SETTING_CITY_VIEW'] },
    component: () => import('@/pages/settings/city/id/edit'),
  },
  {
    path: '/settings/city/city-import',
    name: 'pageCityImport',
    meta: { title: 'page.pageCityImport', roles: ['SETTING_CITY_VIEW'] },
    component: () => import('@/pages/settings/city/import'),
  },
]

export default [
  {
    path: '/settings/sales-script',
    name: 'pageSalesScriptList',
    meta: { title: 'page.pageSalesScriptList', roles: ['SETTING_SCRIPT_VIEW'] },
    component: () => import('@/pages/settings/sales-script'),
  },
  {
    path: '/settings/sales-script/create',
    name: 'pageSalesScriptCreate',
    meta: { title: 'page.pageSalesScriptCreate', roles: ['SETTING_SCRIPT_VIEW'] },
    component: () => import('@/pages/settings/sales-script/create'),
  },
  {
    path: '/settings/sales-script/:id/edit',
    name: 'pageSalesScriptEdit',
    meta: { title: 'page.pageSalesScriptEdit', roles: ['SETTING_SCRIPT_VIEW'] },
    component: () => import('@/pages/settings/sales-script/id/edit'),
  },
]

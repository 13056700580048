export default [
  {
    path: '/settings/user-role',
    name: 'pageUserRoleList',
    meta: { title: 'page.pageUserRoleList', roles: ['SETTING_USER_ROLE_VIEW'] },
    component: () => import('@/pages/settings/user-role'),
  },
  {
    path: '/settings/user-role/create',
    name: 'pageUserRoleCreate',
    meta: { title: 'page.pageUserRoleCreate', roles: ['SETTING_USER_ROLE_VIEW'] },
    component: () => import('@/pages/settings/user-role/create'),
  },
  {
    path: '/settings/user-role/:id/edit',
    name: 'pageUserRoleEdit',
    meta: { title: 'page.pageUserRoleEdit', roles: ['SETTING_USER_ROLE_VIEW'] },
    component: () => import('@/pages/settings/user-role/id/edit'),
  },
]

import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/TicketTypes/helpers'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import ticketTypesRepository from '@/repository/admin/ticketTypesRepository'

const state = {
  ticketTypesList: [],
  filters: StorageService.get(storageKeys.TICKET_TYPES_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  ticketTypesList: state => state.ticketTypesList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadTicketTypes(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.TICKET_TYPES_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await ticketTypesRepository.list(preparedFilters)

    context.commit('setTicketTypesList', { data, filters })

    return { ticketTypesList: data.items, pagination: data.pagination }
  },
  async searchTicketTypesList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await ticketTypesRepository.list(preparedFilters)

    return { ticketTypesList: data.items, pagination: data.pagination }
  },
  async loadTicketType(context, id) {
    const { data } = await ticketTypesRepository.get(id)
    return data
  },
  createTicketTypes(context, payload) {
    return ticketTypesRepository.post(payload)
  },
  updateTicketTypes(context, payload) {
    const { id, ...data } = payload
    return ticketTypesRepository.put(id, data)
  },
  deleteTicketTypes(context, payload) {
    return ticketTypesRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.TICKET_TYPES_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.TICKET_TYPES_FILTERS, updatedFilters)
  },
}

const mutations = {
  setTicketTypesList(state, { data, filters }) {
    state.ticketTypesList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export const defaultFilters = {
	pagination: {
    limit: null,
    page: null,
	},
	filter: null,
	order: {
		rating: 'DESC'
	},
}
  
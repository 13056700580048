import StorageService from '@/services/storage.service'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import questionnaireOrderRepository from '@/repository/admin/questionnaireOrderRepository'
import questionnaireRepository from '@/repository/admin/questionnaireRepository'
import { defaultFilters } from '@/models/QuestionnairesOrder/helpers'
import storageKeys from '@/constans/storageKeys'
import { formatDate } from '@/helpers/date'
import { DATE_DAY_FORMAT } from '@/constans/date'
const { saveAs } = require('file-saver')

const state = {
  filters: StorageService.get(storageKeys.QUESTIONNAIRES_ORDER_FILTERS) || { ...defaultFilters },
  questionnairesOrderList: [],
  questionnairesStatisticsList: [],
  tableOptions: TableOptions.defaultTableOptions(),
  loadingData: false,
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  questionnairesOrderList: state => state.questionnairesOrderList,
  questionnairesStatisticsList: state => state.questionnairesStatisticsList,
  loadingData: state => state.loadingData,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadQuestionnairesOrderList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.QUESTIONNAIRES_ORDER_FILTERS, filters)
    context.commit('setLoadingData', true)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await questionnaireOrderRepository.list(preparedFilters)

    context.commit('setQuestionnairesOrder', { data, filters })
    context.commit('setLoadingData', false)

    return { questionnairesOrderList: data.items, pagination: data.pagination }
  },
  async searchQuestionnairesOrderList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await questionnaireOrderRepository.list(preparedFilters)

    return { questionnairesOrderList: data.items, pagination: data.pagination }
  },
  async loadQuestionnairesOrder(context, id) {
    const { data } = await questionnaireOrderRepository.get(id)
    return data
  },
  async loadQuestionnairesStatistics(context, filters) {
    const { id, ...filter } = filters
    const { data } = await questionnaireOrderRepository.statistics(id, filter)
    context.commit('setQuestionnairesStatistics', { data })
    return { questionnairesStatisticsList: data }
  },
  createQuestionnairesOrder(context, payload) {
    return questionnaireOrderRepository.post(payload)
  },
  updateQuestionnairesOrder(context, payload) {
    const { id, ...data } = payload
    return questionnaireOrderRepository.put(id, data)
  },
  deleteQuestionnairesOrder(context, payload) {
    return questionnaireOrderRepository.delete(payload)
  },
  async loadExportFile(context, filters) {
    const response = await questionnaireRepository.export(filters, { responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `Анкетирование ${formatDate(filters.dateFrom, DATE_DAY_FORMAT)}-${formatDate(
      filters.dateTo,
      DATE_DAY_FORMAT,
    )}.xlsx`
    saveAs(blob, filename)
    return response
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.QUESTIONNAIRES_ORDER_FILTERS, updatedFilters)
  },
}

const mutations = {
  setQuestionnairesOrder(state, { data, filters }) {
    state.questionnairesOrderList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setQuestionnairesStatistics(state, { data }) {
    state.questionnairesStatisticsList = [...data]
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setLoadingData(state, payload) {
    state.loadingData = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/SmsOperatorRelation/helpers'
import smsOperatorRelationRepository from '@/repository/admin/smsOperatorRelationRepository'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'

const state = {
  filtersSmsOperatorRelation: StorageService.get(storageKeys.SMS_OPERATOR_RELATION_FILTERS) || {
    ...defaultFilters,
  },
  smsOperatorRelationList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filtersSmsOperatorRelation: state => state.filtersSmsOperatorRelation,
  tableOptions: state => state.tableOptions,
  smsOperatorRelationList: state => state.smsOperatorRelationList,
  isOpenFilterPanel: state =>
    state.filtersSmsOperatorRelation.clientOptions &&
    state.filtersSmsOperatorRelation.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadSmsOperatorRelationList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.COUNTRY_SMS_SETTING_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await smsOperatorRelationRepository.list(preparedFilters)

    context.commit('setSmsOperatorRelationList', { data, filters })

    return { smsOperatorRelationList: data.items, pagination: data.pagination }
  },
  async loadSmsOperatorRelation(context, id) {
    const { data } = await smsOperatorRelationRepository.get(id)
    return { country: data }
  },
  createSmsOperatorRelation(context, payload) {
    return smsOperatorRelationRepository.bulkCreate(payload)
  },
  updateSmsOperatorRelation(context, payload) {
    const { id, ...data } = payload
    return smsOperatorRelationRepository.put(id, data)
  },
  deleteSmsOperatorRelation(context, payload) {
    return smsOperatorRelationRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.SMS_OPERATOR_RELATION_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filtersSmsOperatorRelation,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.SMS_OPERATOR_RELATION_FILTERS, updatedFilters)
  },
}

const mutations = {
  setSmsOperatorRelationList(state, { data, filters }) {
    state.smsOperatorRelationList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filtersSmsOperatorRelation = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default [
  {
    path: '/settings/salary-bonus',
    name: 'pageSalaryBonusList',
    meta: { title: 'page.pageSalaryBonusList', roles: ['SETTING_SALARY_SETTING_VIEW'] },
    component: () => import('@/pages/settings/salary-bonus'),
  },
  {
    path: '/settings/salary-bonus/import',
    name: 'pageSalaryBonusImport',
    meta: { title: 'page.pageSalaryBonusImport', roles: ['SETTING_SALARY_SETTING_OPERATE'] },
    component: () => import('@/pages/settings/salary-bonus/import'),
  },
]

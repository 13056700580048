import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/Prepayment/helpers'
import PrepaymentRepository from '@/repository/admin/prepaymentRepository'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import moment from 'moment'
import {
  DATE_MONTH_DASH_FORMAT,
  DATE_TIME_FORM_FULL_FORMAT,
  DATE_YEAR_MONTH_FORMAT,
} from '@/constans/date'
import i18n from '@/plugins/vue-i18n'
const { saveAs } = require('file-saver')

const defaultStartDate = moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)
const defaultMonth = moment(defaultStartDate).format(DATE_YEAR_MONTH_FORMAT)

const state = {
  prepaymentList: [],
  filters: StorageService.get(storageKeys.PREPAYMENT_FILTERS_TEAMLEAD_TWO) || {
    ...defaultFilters({
      prepayment_type: 2,
      month: defaultMonth,
      typeOfPayment: 'leader',
    }),
  },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  prepaymentList: state => state.prepaymentList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadPrepaymentList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.PREPAYMENT_FILTERS_TEAMLEAD_TWO, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await PrepaymentRepository.list(preparedFilters)

    context.commit('setPrepayment', { data, filters })

    return { prepaymentList: data.items, pagination: data.pagination }
  },
  updatePrepayment(context, payload) {
    const { id, ...data } = payload
    return PrepaymentRepository.put(id, data)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.PREPAYMENT_FILTERS_TEAMLEAD_TWO)
  },
  async loadExportFile(context, filters) {
    const response = await PrepaymentRepository.export(filters, { responseType: 'blob' })
    const preparedDateForFile = moment(filters.date, DATE_TIME_FORM_FULL_FORMAT).format(
      DATE_MONTH_DASH_FORMAT,
    )
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('prepayment.fileNameTeamLead2')}${preparedDateForFile}.xlsx`
    saveAs(blob, filename)
    return response
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.PREPAYMENT_FILTERS_TEAMLEAD_TWO, updatedFilters)
  },
}

const mutations = {
  setPrepayment(state, { data, filters }) {
    state.prepaymentList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

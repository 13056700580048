export default [
  {
    path: '/call-centre/call-controller',
    name: 'pageCallController',
    meta: { title: 'page.pageCallController', roles: ['CC_CALL_VIEW'] },
    component: () => import('@/pages/call-centre/call-controller'),
  },
  {
    path: '/call-centre/call-controller/order/:id/edit',
    name: 'pageCallControllerEdit',
    meta: { title: 'page.pageOrderEdit', roles: ['CC_ORDER_VIEW'] },
    component: () => import('@/pages/call-centre/call-controller/id/edit'),
  },
]

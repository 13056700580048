import moment from 'moment/moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFiltersSuspicious = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    country: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    offer: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      niche: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    tags: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    partnerId: {
      value: '',
      operator: 'in',
    },
    landing: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    statusReason: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    createdAt: {
      value: `${moment('2023-09').startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${
        moment('2023-09').endOf('month').format(DATE_TIME_FORM_FULL_FORMAT,)}`,
      operator: 'bwn',
    },
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
    selectedColumns: [],
  },
}

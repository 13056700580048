export default [
  {
    path: '/settings/payment-hold',
    name: 'pagePaymentHoldList',
    meta: { title: 'page.pagePaymentHoldList', roles: ['SETTING_SALARY_DEDUCTION_VIEW'] },
    component: () => import('@/pages/settings/payment-hold'),
  },
  {
    path: '/settings/payment-hold/create',
    name: 'pagePaymentHoldCreate',
    meta: { title: 'page.pagePaymentHoldCreate', roles: ['SETTING_SALARY_DEDUCTION_VIEW'] },
    component: () => import('@/pages/settings/payment-hold/create'),
  },
  {
    path: '/settings/payment-hold/:id/edit',
    name: 'pagePaymentHoldEdit',
    meta: { title: 'page.pagePaymentHoldEdit', roles: ['SETTING_SALARY_DEDUCTION_VIEW'] },
    component: () => import('@/pages/settings/payment-hold/id/edit'),
  },
]

import { generateUUID } from '@/helpers/functions'

const types = {
  NOTIFICATION_SHOW: 'NOTIFICATION_SHOW',
  NOTIFICATION_REMOVE: 'NOTIFICATION_REMOVE',
}

const baseNotificationOptions = {
  component: null,
  props: {},
  listeners: {},
  type: 'error',
  title: '',
  message: '',
  persistent: false,
  actionTitle: false,
  onEnd: null,
  onClose: null,
  actionCallback: null,
  runTime: false,
  limitTime: null,
  isSeparate: true,
}

const NOTIFICATION_DURATION = 10000

const state = {
  notificationsList: [],
}

const getters = {
  list: state => state.notificationsList,
}

const actions = {
  show(context, notificationOptions) {
    const notification = Object.assign({}, baseNotificationOptions, notificationOptions, {
      id: generateUUID(),
      iconName: notificationOptions.type,
    })

    if (!notification.persistent) {
      notification.timeout = window.setTimeout(() => {
        context.dispatch('remove', {
          notificationId: notification.id,
          isExpired: true,
        })

        notification.onEnd && notification.onEnd()
      }, NOTIFICATION_DURATION)
    }

    context.commit(types.NOTIFICATION_SHOW, notification)
  },

  remove(context, { notificationId, isExpired }) {
    const notification = context.getters.list.find(
      notification => notification.id === notificationId,
    )
    if (!notification) {
      return
    }

    if (notification.timeout) {
      window.clearTimeout(notification.timeout)
    }

    if (!isExpired && notification.onClose) {
      notification.onClose()
    }

    context.commit(types.NOTIFICATION_REMOVE, notification.id)
  },

  actionCallback(context, notificationId) {
    const notification = context.getters.list.find(
      notification => notification.id === notificationId,
    )

    if (!notification) {
      return
    }

    if (notification.timeout) {
      window.clearTimeout(notification.timeout)
    }

    if (notification.actionCallback) {
      notification.actionCallback()
    }
  },
}

const mutations = {
  NOTIFICATION_SHOW(state, notification) {
    state.notificationsList = [...state.notificationsList, notification]
  },
  NOTIFICATION_REMOVE(state, notificationId) {
    state.notificationsList = state.notificationsList.filter(
      notification => notification.id !== notificationId,
    )
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import SuperRepository from '../superRepository'

export default new (class OrderReportRepository extends SuperRepository {
  baseUri() {
    return 'order_report'
  }
  shippedMoreThan20Days = async (params = {}) =>
    this.httpClient().getFile(`${this.baseUri()}/shipped_more_than_20_days`, params)
  shippedMoreThanNoBarCode = async (params = {}) =>
    this.httpClient().getFile(`${this.baseUri()}/shipped_more_than_15_days_no_barcode`, params)
  approvedMoreThan14Day = async (params = {}) =>
    this.httpClient().getFile(`${this.baseUri()}/approved_more_than_14_day_courier`, params)
  approvedMoreThan10Day = async (params = {}) =>
    this.httpClient().getFile(`${this.baseUri()}/approved_more_than_10_day_not_courier`, params)
  awaitingRansomMore30Day = async id =>
    this.httpClient().getFile(`${this.baseUri()}/awaiting_ransom_more_than_30_day/${id}`)
  awaitingRansomMore15Day = async (params = {}) =>
    this.httpClient().getFile(`${this.baseUri()}/awaiting_ransom_more_than_15_day/`, params)
  buybackDynamics = async params =>
    this.httpClient().post(`${this.baseUri()}/buyback_dynamics_by_day`, params)
  buybackDynamicsExport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/buyback_dynamics_by_day`, data, params)
  approveSpeed = async params => this.httpClient().post(`${this.baseUri()}/approve_speed`, params)
  legalEntitiesStatistics = async params =>
    this.httpClient().post(`${this.baseUri()}/legal_entity_statistics`, params)
  legalEntitiesStatisticsExport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/legal_entity_statistics`, data, params)
  hangingRansom = async params => this.httpClient().post(`${this.baseUri()}/hanging_ransom`, params)
  hangingRansomOrders = async params =>
    this.httpClient().get(`${this.baseUri()}/hanging_ransom_orders`, params)
  hangingRansomOrdersExport = async params =>
    this.httpClient().getFile(`${this.baseUri()}/hanging_ransom_orders`, params)
  hangingRansomExport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/hanging_ransom`, data, params)
  rejection = async params =>
    this.httpClient().post(`${this.baseUri()}/reject_order_statistics`, params)
  callCentre = async params =>
    this.httpClient().post(`${this.baseUri()}/call_center_statistics`, params)
  reconfirmation = async params =>
    this.httpClient().post(`${this.baseUri()}/reconfirmation_statistic`, params)
  callStatistics = async params =>
    this.httpClient().post(`${this.baseUri()}/call_statistics`, params)
  financialReport = async params =>
    this.httpClient().post(`${this.baseUri()}/financial_report`, params)
  financialReportOrders = async (uuid, params) =>
    this.httpClient().get(`${this.baseUri()}/financial_report_orders/${uuid}`, params)
  financialReportOrdersExport = async (uuid, params) =>
    this.httpClient().getFile(`${this.baseUri()}/financial_report_orders/${uuid}`, params)
  averageCheck = async params => this.httpClient().post(`${this.baseUri()}/average_check`, params)
  averageCheckExport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/average_check`, data, params)
  deliveryServiceAnalytic = async (data, params) =>
    this.httpClient().post(`${this.baseUri()}/delivery_service_analytic${params}`, data)
  deliveryServiceAnalyticExport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/delivery_service_analytic`, data, params)
  callCentreExport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/call_center_statistics`, data, params)
  dostavim = async params => this.httpClient().post(`${this.baseUri()}/dostavim_report`, params)
  financialReportExport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/financial_report`, data, params)
})()

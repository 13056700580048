import dadataRepository from '@/repository/call-centre/dadataRepository'

const state = {}

const getters = {}

const actions = {
  async loadSuggestionAddress(context, payload) {
    const { query } = payload
    const { data } = await dadataRepository.suggestAddress({ query })
    return { addressList: data }
  },
  async loadSuggestionPerson(context, payload) {
    const { query, parts } = payload
    const { data } = await dadataRepository.suggestPerson({ query, parts })
    return { personList: data }
  },
  async loadUnitPost(context, index) {
    const { data } = await dadataRepository.unitPost(index)
    return { postalAddress: Array.isArray(data) && data[0] ? data[0].street : '' }
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import moment from 'moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    active: 1,
    createdAt: {
      value: `${moment('2023-09').startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${moment('2023-09')
          .endOf('month').format(DATE_TIME_FORM_FULL_FORMAT,)}`,
      operator: 'bwn',
    },
    country: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    offers: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    salesType: {
      id: {
        value: '',
        operator: 'in',
      },
    },
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export const orderFilters = {
  pagination: {
    limit: 10,
    page: 1,
  },
  filter: {},
  sortBy: ['id'],
  sortDesc: [false],
}

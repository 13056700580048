export default [
  {
    path: '/settings/questionnaire',
    name: 'pageQuestionnaireKnl',
    meta: { title: 'page.pageQuestionnaireKnl', roles: ['SETTING_KLN_VIEW'] },
    component: () => import('@/pages/settings/questionnaire-knl'),
  },
  {
    path: '/settings/questionnaire/create',
    name: 'pageQuestionnaireKnlCreate',
    meta: { title: 'page.pageQuestionnaireKnlEdit', roles: ['SETTING_KLN_VIEW'] },
    component: () => import('@/pages/settings/questionnaire-knl/create'),
  },
  {
    path: '/settings/questionnaire/:id/edit',
    name: 'pageQuestionnaireKnlEdit',
    meta: { title: 'page.pageQuestionnaireKnlEdit', roles: ['SETTING_KLN_VIEW'] },
    component: () => import('@/pages/settings/questionnaire-knl/id/edit'),
  },
]

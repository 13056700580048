export default [
  {
    path: '/manager/prepayment-teamlead',
    name: 'pagePrepaymentTeamLeadList',
    meta: { title: 'page.pagePrepaymentTeamLead', roles: ['MNG_LEADER_SALARY_ADVANCE_1'] },
    component: () => import('@/pages/manager/prepayment-teamlead'),
  },
  {
    path: '/manager/prepayment-teamlead-two',
    name: 'pagePrepaymentTeamLeadTwoList',
    meta: { title: 'page.pagePrepaymentTeamLeadTwo', roles: ['MNG_LEADER_SALARY_ADVANCE_2'] },
    component: () => import('@/pages/manager/prepayment-teamlead-two'),
  },
]

import StorageService from '@/services/storage.service'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import { defaultFilters } from '@/models/DeliveryServiceCity/helpers'
import deliveryServiceCityRepository from '@/repository/admin/deliveryServiceCityRepository'
import storageKeys from '@/constans/storageKeys'

const state = {
  filters: StorageService.get(storageKeys.DELIVERY_SERVICE_CITY_FILTERS) || { ...defaultFilters },
  serviceCityList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  serviceCityList: state => state.serviceCityList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadServiceCityList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.DELIVERY_SERVICE_CITY_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const { data } = await deliveryServiceCityRepository.list(preparedFilters)
    context.commit('setServiceCityList', { data, filters })

    return { serviceCityList: data.items, pagination: data.pagination }
  },
  async searchServiceCityList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await deliveryServiceCityRepository.list(preparedFilters)

    return { serviceCityList: data.items, pagination: data.pagination }
  },
  async loadServiceCity(context, id) {
    const { data } = await deliveryServiceCityRepository.get(id)
    return data
  },
  createServiceCity(context, payload) {
    return deliveryServiceCityRepository.post(payload)
  },
  updateServiceCity(context, payload) {
    const { id, ...data } = payload
    return deliveryServiceCityRepository.put(id, data)
  },
  deleteServiceCity(context, payload) {
    return deliveryServiceCityRepository.delete(payload)
  },
}

const mutations = {
  setServiceCityList(state, { data, filters }) {
    state.serviceCityList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

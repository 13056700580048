import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/OrderRansom/helpers'
import { defaultOrderFilters } from '@/models/HangingRansomOrders/helpers'
import orderReportRepository from '@/repository/admin/orderReportRepository'
import { mockPagination, omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'
import { formatDate } from '@/helpers/date'
import { DATE_FORMAT } from '@/constans/date'
import i18n from "@/plugins/vue-i18n";
const { saveAs } = require('file-saver')

const state = {
  filters: StorageService.get(storageKeys.ORDER_HANGING_RANSOM_FILTERS) || { ...defaultFilters },
  unpaidFilters: StorageService.get(storageKeys.UNPAID_ORDERS_FILTERS) || {
    ...defaultOrderFilters,
  },
  orderRansomList: [],
  orderRansomListTotal: [],
  unpaidOrdersList: [],
  tableOptions: TableOptions.defaultTableOptions(),
  unpaidTableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  filtersUnpaid: state => state.unpaidFilters,
  tableOptions: state => state.tableOptions,
  unpaidTableOptions: state => state.unpaidTableOptions,
  orderRansomList: state => state.orderRansomList,
  orderRansomListTotal: state => state.orderRansomListTotal,
  unpaidOrdersList: state => state.unpaidOrdersList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
  isOpenUnpaidFilterPanel: state =>
    state.unpaidFilters.clientOptions && state.unpaidFilters.clientOptions.filterPanelExpanded,
  isOnSortColumn: state => state.filters.sortDesc,
}

const actions = {
  async loadOrderRansomList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.ORDER_HANGING_RANSOM_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilter = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilter,
    })
    const { data } = await orderReportRepository.hangingRansom(preparedFilters.filter)

    context.commit('setOrderRansomList', {
      data,
      pagination: mockPagination(Object.values(data.rows), filters),
      filters,
    })

    return { orderRansomList: data, pagination: data.pagination }
  },
  async loadUnpaidOrdersList(context, filters) {
    context.commit('setUnpaidFilters', filters)
    StorageService.set(storageKeys.UNPAID_ORDERS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await orderReportRepository.hangingRansomOrders(preparedFilters)
    context.commit('setUnpaidOrdersList', { data, filters, pagination: data.pagination })
    return { unpaidOrdersList: data.items, pagination: data.pagination }
  },
  updateSavedFilters(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.ORDER_HANGING_RANSOM_FILTERS, filters)

    context.commit('setOrderRansomList', {
      data: context.getters.orderRansomList,
      pagination: mockPagination(Object.values(context.getters.orderRansomList.rows), filters),
      filters,
    })
  },
  async loadExportFile(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const response = await orderReportRepository.hangingRansomExport(
      { ...preparedFilters.filter, xlsx: true },
      { responseType: 'blob' },
    )
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('hangingRansom.title')} ${formatDate(
      appliedFilters.dateFrom,
      DATE_FORMAT,
    )}-${formatDate(appliedFilters.dateTo, DATE_FORMAT)}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async loadUnpaidExportFile(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const response = await orderReportRepository.hangingRansomOrdersExport({
      filter: {
        ...preparedFilters.filter,
      },
      export: 1,
    })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `Заказы с зависшим выкупом ${formatDate(
      appliedFilters.createdAt.value.split('|')[0],
      DATE_FORMAT,
    )}-${formatDate(appliedFilters.createdAt.value.split('|')[1], DATE_FORMAT)}.xlsx`
    saveAs(blob, filename)
    return response
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.ORDER_HANGING_RANSOM_FILTERS)
  },
  filtersUnpaidStorageReset() {
    StorageService.remove(storageKeys.UNPAID_ORDERS_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.ORDER_HANGING_RANSOM_FILTERS, updatedFilters)
  },
  updateUnpaidFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filtersUnpaid,
      clientOptions,
    }
    commit('setUnpaidFilters', updatedFilters)
    StorageService.set(storageKeys.UNPAID_ORDERS_FILTERS, updatedFilters)
  },
  updateSortOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      ...clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.ORDER_HANGING_RANSOM_FILTERS, updatedFilters)
  },
}

const mutations = {
  setUnpaidOrdersList(state, { data, filters, pagination }) {
    state.unpaidOrdersList = data
    state.unpaidTableOptions = new TableOptions(pagination, filters)
  },
  setOrderRansomList(state, { data, filters, pagination }) {
    state.orderRansomList = data
    state.orderRansomListTotal = data.total
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setUnpaidFilters(state, filters) {
    state.unpaidFilters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/DistributionCheck/helpers'
import orderRepository from '@/repository/admin/orderRepository'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'

const state = {
  filters: StorageService.get(storageKeys.DISTRIBUTION_CHECK_FILTERS) || { ...defaultFilters },
  distributionCheckList: [],
}

const getters = {
  filters: state => state.filters,
  distributionCheckList: state => state.distributionCheckList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadDistributionCheckList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.DISTRIBUTION_CHECK_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const { data } = await orderRepository.distributionCheck(preparedFilters.filter)

    context.commit('setDistributionCheckList', { data, filters })

    return { distributionCheckList: data }
  },

  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.DISTRIBUTION_CHECK_FILTERS, updatedFilters)
  },
}

const mutations = {
  setDistributionCheckList(state, { data }) {
    state.distributionCheckList = data
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

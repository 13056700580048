export default [
  {
    path: '/notice-department/orders',
    name: 'pageNoticeDepartmentOrders',
    meta: { title: 'page.pageNoticeDepartment', roles: ['SIDEBAR_ORDER_VIEW'] },
    component: () => import('@/pages/notice-department/orders'),
  },
  {
    path: '/notice-department/orders/create',
    name: 'pageNoticeDepartmentCreate',
    meta: {
      title: 'page.pageOrderCreate',
      roles: ['SIDEBAR_ORDER_OPERATE', 'SIDEBAR_ORDER_CREATE'],
    },
    component: () => import('@/pages/notice-department/orders/create'),
  },
  {
    path: '/notice-department/orders/:id/edit',
    name: 'pageNoticeDepartmentEdit',
    meta: { title: 'page.pageOrderEdit', roles: ['SIDEBAR_ORDER_VIEW'] },
    component: () => import('@/pages/notice-department/orders/id/edit'),
  },
]

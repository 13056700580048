<template>
  <v-chip v-bind="$attrs" v-on="$listeners" :color="backgroundColor" :text-color="textColor">
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </v-chip>
</template>

<script>
export default {
  name: 'UiChip',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    backgroundColor() {
      switch (this.status) {
        case 'approved':
        case 'awaiting_ransom':
        case 'shipped':
        case 'delivered':
        case 'paid':
        case 'partially_paid':
          return 'success'
        case 'trash':
        case 'callback':
        case 'suspicious':
          return 'warning'
        case 'no_ransom':
        case 'canceled':
        case 'incorrect':
          return 'error'
        default:
          return 'black lighten-5'
      }
    },
    textColor() {
      switch (this.status) {
        case 'processing':
        case 'call':
        case 'accepted':
          return 'black darken-3'
        default:
          return 'white'
      }
    },
  },
}
</script>

<style scoped></style>

import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'
import moment from 'moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    hotOperator: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    questionnaire: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    createdBy: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    order: {
      country: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      offer: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      operator: {
        groups: {
          id: {
            value: '',
            operator: 'in',
          },
        },
      },
    },
    createdAt: {
      value: `${moment('2023-09').startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${
        moment('2023-09').endOf('month').format(DATE_TIME_FORM_FULL_FORMAT,)}`,
      operator: 'bwn',
    },
  },
  sortBy: ['createdAt'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedQuestionnairesOrderFilters(filter) {
  const filters = cloneDeep(defaultFilters.filter)

  filters.order.country.id.value = joinBySymbol(filter.countries)
  filters.order.offer.id.value = joinBySymbol(filter.offers)
  filters.createdBy.id.value = joinBySymbol(filter.users)
  filters.createdAt.value = joinBySymbol(filter.date)
  filters.questionnaire.id.value = joinBySymbol(filter.questionnaires)
  filters.hotOperator.id.value = joinBySymbol(filter.usersHot)
  filters.order.operator.groups.id.value = joinBySymbol(filter.operatorGroups)

  return filters
}

export default [
  {
    path: '/settings/questionnaires',
    name: 'pageQuestionnairesList',
    meta: { title: 'page.pageQuestionnairesList', roles: ['SETTING_QUESTIONNAIRE_VIEW'] },
    component: () => import('@/pages/settings/questionnaires'),
  },
  {
    path: '/settings/questionnaires/create',
    name: 'pageQuestionnairesCreate',
    meta: { title: 'page.pageQuestionnairesCreate', roles: ['SETTING_QUESTIONNAIRE_VIEW'] },
    component: () => import('@/pages/settings/questionnaires/create'),
  },
  {
    path: '/settings/questionnaires/:id/edit',
    name: 'pageQuestionnairesEdit',
    meta: { title: 'page.pageQuestionnairesEdit', roles: ['SETTING_QUESTIONNAIRE_VIEW'] },
    component: () => import('@/pages/settings/questionnaires/id/edit'),
  },
]

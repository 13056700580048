import StorageService from '@/services/storage.service'
import TableOptions from '@/models/TableOptions'
import { hasAppliedFilters, omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import orderRepository from '@/repository/admin/orderRepository'
import { defaultFilters } from '@/models/OrderOperator/helpers'
import storageKeys from '@/constans/storageKeys'

const state = {
  filters: StorageService.get(storageKeys.ORDER_OPERATOR_FILTERS) || { ...defaultFilters },
  orderList: [],
  tableOptions: TableOptions.defaultTableOptions(),
  selectedColumns: null,
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  orderList: state => state.orderList,
  hasAppliedFilters: state => {
    return hasAppliedFilters(state.filters)
  },
  selectedColumns: state => state.selectedColumns,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadOrderOperatorList(context, filters) {
    const extendedFilters = {
      ...filters,
      cfFilter: {
        ...filters.cfFilter,
        [1]: {
          operator: {
            operator: 'in',
            value: context.rootGetters['ProfileModule/profile']?.id,
          },
          firstApprovedBy: {
            operator: 'in',
            value: context.rootGetters['ProfileModule/profile']?.id,
          },
        },
      },
    }

    context.commit('setFilters', extendedFilters)
    StorageService.set(storageKeys.ORDER_OPERATOR_FILTERS, extendedFilters)

    const appliedFilters = omitEmptyDeepFilters(extendedFilters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(extendedFilters.cfFilter)

    const preparedFilters = prepareFilters({
      ...extendedFilters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await orderRepository.list(preparedFilters)

    context.commit('setOrderOperatorList', { data, filters })
    return { orderList: data.items, pagination: data.pagination }
  },
  async loadOrderOperatorCallbackList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await orderRepository.list(preparedFilters)

    return { callbacksList: data.items, pagination: data.pagination }
  },
  setSelectedColumns({ commit }, payload) {
    const filters = StorageService.get(storageKeys.ORDER_OPERATOR_FILTERS)
    filters.clientOptions.selectedColumns = payload
    StorageService.set(storageKeys.ORDER_OPERATOR_FILTERS, filters)

    commit('setSelectedColumns', payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.ORDER_OPERATOR_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.ORDER_OPERATOR_FILTERS, updatedFilters)
  },
}

const mutations = {
  setOrderOperatorList(state, { data, filters }) {
    state.orderList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setSelectedColumns(state, payload) {
    state.selectedColumns = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import moment from 'moment'
import { prepareRangeDateMonthFilter } from '@/helpers/filters'
export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    groups: {
      id: {
        value: '',
        operator: 'in',
      },
      isOperator: 1,
    },
    onDuty: 1,
    recommendedGrade: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    createdAt: prepareRangeDateMonthFilter(moment().subtract(1, 'months')),
  },
  sortBy: ['id'],
  sortDesc: [false],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

import moment from 'moment/moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: -1,
  },
  filter: {
    user: {
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      grade: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      schedule: {
        norms: {
          value: '',
          operator: 'in',
        },
      },
    },
    date: {
      value: `${moment('2023-09-15').startOf('isoWeek').format(DATE_TIME_FORM_FULL_FORMAT)}|${
        moment('2023-09-15').endOf('isoWeek').format(DATE_TIME_FORM_FULL_FORMAT)}`,
      operator: 'bwn',
    },
  },
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export default function customScheduleLabel(option) {
  return option.norms
}

export const userFilters = {
  pagination: {
    limit: 10,
    page: 1,
  },
}

<template>
  <v-card v-bind="$attrs" v-on="$listeners" :flat="flat" class="ui-card">
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </v-card>
</template>

<script>
export default {
  name: 'ui-card',

  props: {
    flat: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
.ui-card {
  padding: 28px 24px;

  .v-card__title {
    color: var(--v-black-darken6);
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    padding: 0 0 10px 0;
  }
}
</style>

export default [
  {
    path: '/settings/product-sets',
    name: 'pageProductSetsList',
    meta: { title: 'page.pageProductSetsList', roles: ['SETTING_PRODUCT_BUNDLE_VIEW'] },
    component: () => import('@/pages/settings/product-sets'),
  },
  {
    path: '/settings/product-sets/create',
    name: 'pageProductSetsCreate',
    meta: { title: 'page.pageProductSetsCreate', roles: ['SETTING_PRODUCT_BUNDLE_VIEW'] },
    component: () => import('@/pages/settings/product-sets/create'),
  },
  {
    path: '/settings/product-sets/:id/edit',
    name: 'pageProductSetsEdit',
    meta: { title: 'page.pageProductSetsEdit', roles: ['SETTING_PRODUCT_BUNDLE_VIEW'] },
    component: () => import('@/pages/settings/product-sets/id/edit'),
  },
]

export default [
  {
    path: '/settings/landing',
    name: 'pageLandingList',
    meta: { title: 'page.pageLandingList', roles: ['SETTING_LANDING_VIEW'] },
    component: () => import('@/pages/settings/landing'),
  },
  {
    path: '/settings/landing/create',
    name: 'pageLandingCreate',
    meta: { title: 'page.pageLandingCreate', roles: ['SETTING_LANDING_VIEW'] },
    component: () => import('@/pages/settings/landing/create'),
  },
  {
    path: '/settings/landing/:id/edit',
    name: 'pageLandingEdit',
    meta: { title: 'page.pageLandingEdit', roles: ['SETTING_LANDING_VIEW'] },
    component: () => import('@/pages/settings/landing/id/edit'),
  },
]

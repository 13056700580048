import DeliveryTaxRepository from '@/repository/admin/deliveryTaxRepository'
const { saveAs } = require('file-saver')
import i18n from '@/plugins/vue-i18n'
function renderHeaders(data) {
  if (!data) return []
  return Array.isArray(data) && data.length
    ? Object.keys(data[0]).map(key => {
        return {
          text: i18n.t(`deliveryTax.field.${key}`),
          sortable: false,
          value: key,
        }
      })
    : []
}

const state = {
  deliveryTaxList: [],
  deliveryTaxHeaders: [],
}

const getters = {
  deliveryTaxList: state => state.deliveryTaxList,
  deliveryTaxHeaders: state => state.deliveryTaxHeaders,
}

const actions = {
  async loadExportFile() {
    const response = await DeliveryTaxRepository.export()
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = 'Налоги.xlsx'
    saveAs(blob, filename)
    return response
  },
  async importFile(context, payload) {
    const { formData, config } = payload
    return await DeliveryTaxRepository.import(formData, config)
  },
  async loadPreviewFile(context, payload) {
    const { formData, config } = payload
    const { data } = await DeliveryTaxRepository.preview(formData, config)

    const headers = renderHeaders(data)

    context.commit('setDeliveryTaxPreviewHeaders', headers)
    context.commit('setDeliveryTaxPreviewList', data)

    return {
      deliveryTaxList: data,
      deliveryTaxHeaders: headers,
    }
  },
}

const mutations = {
  setDeliveryTaxPreviewList(state, data) {
    state.deliveryTaxList = data
  },
  setDeliveryTaxPreviewHeaders(state, data) {
    state.deliveryTaxHeaders = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'
import { DATE_FORM_FORMAT } from '@/constans/date'
import moment from 'moment'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    dateFrom: moment('2023-09').startOf('month').format(DATE_FORM_FORMAT),
    dateTo: moment('2023-09').endOf('month').format(DATE_FORM_FORMAT),
    filterDateBy: 'shipped',
    deliveryServices: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    countries: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    regions: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    deliveryTypes: [],
    warehouses: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    checkFrom: null,
    checkTo: null,
    offers: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    products: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    tags: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedBuybackDynamicsFilters(filter) {
  const filters = cloneDeep(defaultFilters.filter)

  filters.deliveryServices.filter.id.value = joinBySymbol(filter.deliveryServices)
  filters.countries.filter.id.value = joinBySymbol(filter.countries)
  filters.regions.filter.id.value = joinBySymbol(filter.regions)
  filters.deliveryTypes = filter.deliveryTypes
  filters.warehouses.filter.id.value = joinBySymbol(filter.warehouses)
  filters.checkFrom = filter.checkFrom
  filters.checkTo = filter.checkTo
  filters.offers.filter.id.value = joinBySymbol(filter.offers)
  filters.products.filter.id.value = joinBySymbol(filter.products)
  filters.tags.filter.id.value = joinBySymbol(filter.orderTags)
  filters.dateFrom = joinBySymbol(filter.dateFrom)
  filters.dateTo = joinBySymbol(filter.dateTo)
  filters.filterDateBy = joinBySymbol(filter.filterDateBy)
  return filters
}

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    role: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    enabled: 1,
    offers: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    languages: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    countries: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    groups: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    onDuty: null,
  },
  sortBy: ['enabled', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export default function customUserLabel(option) {
  return option.name === undefined ? undefined : `${option.name} ${option.lastName}`
}

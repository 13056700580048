import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/FinancialReportOrders/helpers'
import orderReportRepository from '@/repository/admin/orderReportRepository'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'
import { cloneDeep } from 'lodash'
import i18n from '@/plugins/vue-i18n'
const { saveAs } = require('file-saver')

const state = {
  filters: StorageService.get(storageKeys.FINANCIAL_REPORT_ORDERS_FILTERS) || { ...defaultFilters },
  financialReportOrdersList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  financialReportOrdersList: state => state.financialReportOrdersList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadFinancialReportOrdersList(context, payload) {
    const { uuid, ...filters } = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.FINANCIAL_REPORT_ORDERS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilter = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilter,
    })
    preparedFilters.withErrors = preparedFilters.filter.withErrors
    delete preparedFilters.filter.withErrors
    const { data } = await orderReportRepository.financialReportOrders(uuid, preparedFilters)

    context.commit('setFinancialReportOrdersList', { data, filters })

    return { financialReportOrdersList: data.items, pagination: data.pagination }
  },

  updatePaginationFinancialReportOrders({ state, dispatch }, { pagination }) {
    const filters = cloneDeep(state.filters)
    filters.pagination = cloneDeep(pagination)
    dispatch('loadFinancialReportOrdersList', filters)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.FINANCIAL_REPORT_ORDERS_FILTERS)
  },
  async loadExportFile(context, payload) {
    const { uuid, ...filters } = payload
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilter = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilter,
    })
    const response = await orderReportRepository.financialReportOrdersExport(
      uuid,
      preparedFilters,
      { responseType: 'blob' },
    )
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('financialReportOrders.exportFile')}.xlsx`
    saveAs(blob, filename)
    return response
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.FINANCIAL_REPORT_ORDERS_FILTERS, updatedFilters)
  },
}

const mutations = {
  setFinancialReportOrdersList(state, { data, filters }) {
    state.financialReportOrdersList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

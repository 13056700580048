import moment from "moment/moment";
import {DATE_TIME_FORM_FULL_FORMAT} from "@/constans/date";

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    date: {
      value: `${moment('2023-09-15').startOf('isoWeek')
        .format(DATE_TIME_FORM_FULL_FORMAT)}|${moment('2023-09-15').endOf('isoWeek')
        .format(DATE_TIME_FORM_FULL_FORMAT)}`,
      operator: 'bwn'
    }
  },
  clientOptions: {
    filterPanelExpanded: false,
  },
}
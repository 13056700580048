import { cloneDeep } from 'lodash'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    active: 1,
    terminalPayment: null,
    district: {
      id: {
        value: '',
        operator: 'in',
      },
      region: {
        id: {
          value: '',
          operator: 'in',
        },
        country: {
          id: {
            value: '',
            operator: 'in',
          },
        },
      },
    },
    currency: '',
    deliveryServices: {
      deliveryService: {
        id: {
          value: '',
          operator: 'in',
        },
        type: {
          value: '',
          operator: 'in',
        },
      },
    },
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
export function preparedRegionFilters(regionFilters, { region, country }) {
  const preparedDistrict = cloneDeep(regionFilters)

  preparedDistrict.id = region?.id
  preparedDistrict.country = country?.id

  return preparedDistrict
}

export function preparedDistrictFilters(districtFilters, { district, region, country }) {
  const preparedDistrict = cloneDeep(districtFilters)

  preparedDistrict.id = district?.id
  preparedDistrict.region.id = region?.id
  preparedDistrict.region.country = country?.id

  return preparedDistrict
}

export function preparedCityFilters(cityFilters, { city, district, region, country }) {
  const preparedCity = cloneDeep(cityFilters)

  preparedCity.id = city?.id
  preparedCity.district.id = district?.id
  preparedCity.district.region.id = region?.id
  preparedCity.district.region.country = country?.id

  return preparedCity
}

import SuperRepository from '../superRepository'

export default new (class DadataRepository extends SuperRepository {
  baseUri() {
    return 'dadata'
  }
  suggestAddress = async (data, params) =>
    this.httpClient().post(`${this.baseUri()}/suggest/address`, data, params)
  suggestPerson = async (data, params) =>
    this.httpClient().post(`${this.baseUri()}/suggest/person`, data, params)
  unitPost = async index => this.httpClient().get(`${this.baseUri()}/postal_unit/${index}`)
})()

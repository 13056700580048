import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/OrderFraud/helpers'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters, mockPagination } from '@/helpers/filters'
import orderRepository from '@/repository/admin/orderRepository'

const state = {
  filters: StorageService.get(storageKeys.ORDER_FRAUD_FILTERS) || { ...defaultFilters },
  orderFraudList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  orderFraudList: state => state.orderFraudList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadOrderFraudList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.ORDER_FRAUD_FILTERS, filters)

    const appliedFilters = {
      dateFrom: filters.dateFrom,
      dateTo: filters.dateTo,
    }

    let { data } = await orderRepository.fraud(appliedFilters)
    context.commit('setOrderFraud', {
      data,
      pagination: mockPagination(data, filters),
      filters,
    })

    return { orderFraudList: data }
  },
  async searchOrderFraudList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await orderRepository.list(preparedFilters)

    return { orderFraudList: data, pagination: data.pagination }
  },
  updateStorageFilters(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.ORDER_FRAUD_FILTERS, filters)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.ORDER_FRAUD_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.ORDER_FRAUD_FILTERS, updatedFilters)
  },
  updateSavedFilters(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.ORDER_FRAUD_FILTERS, filters)
    context.commit('setOrderFraud', {
      data: context.getters.orderFraudList,
      pagination: mockPagination(context.getters.orderFraudList, filters),
      filters,
    })
  },
}

const mutations = {
  setOrderFraud(state, { data, filters, pagination }) {
    state.orderFraudList = data.map(item => {
      item.title = item.name
      return item
    })
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import SuperRepository from '../superRepository'

export default new (class ccStatisticsDashboardRepository extends SuperRepository {
  baseUri() {
    return 'call_center_statistics'
  }
  statisticsByDay = async params => this.httpClient().get(`${this.baseUri()}/chart_by_day`, params)
  statisticsByWeek = async params => this.httpClient().get(`${this.baseUri()}/chart_by_week`, params)
  statisticsByMonth = async params => this.httpClient().get(`${this.baseUri()}/chart_by_month`, params)
  tableStatisticsByDay = async params => this.httpClient().get(`${this.baseUri()}/table_by_day`, params)
  tableStatisticsByWeek = async params => this.httpClient().get(`${this.baseUri()}/table_by_week`, params)
  tableStatisticsByMonth = async params => this.httpClient().get(`${this.baseUri()}/table_by_month`, params)
})()

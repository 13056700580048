import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    country: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    salesModel: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    grade: {
      id: {
        value: '',
        operator: 'in',
      },
    },
  },
  sortBy: ['id'],
  sortDesc: [false],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedSalaryBonusFilters(filter) {
  const filters = cloneDeep(defaultFilters.filter)
  filters.country.id.value = joinBySymbol(filter.countries)
  filters.salesModel.id.value = joinBySymbol(filter.salesModel)
  filters.grade.id.value = joinBySymbol(filter.grade)

  return filters
}

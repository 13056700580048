import smsRepository from '@/repository/call-centre/smsRepository'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'

const actions = {
  async loadSmsList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await smsRepository.list(preparedFilters)

    return { smsList: data.items, pagination: data.pagination }
  },
  async getSmsStatus(context, id) {
    const { data } = await smsRepository.getStatus(id)
    return { data }
  },
}

export default {
  namespaced: true,
  actions,
}

export default [
  {
    path: '/logistics/order-import',
    name: 'pageOrderLogisticsImport',
    meta: {
      title: 'page.pageOrderImport',
      roles: ['LOGISTIC_ORDER_IMPORT_FILE'],
    },
    component: () => import('@/pages/call-centre/order-import'),
  },
]

import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/UserSalary/helpers'
import userSalaryRepository from '@/repository/admin/userSalaryRepository'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import moment from 'moment'
import { DATE_MONTH_DASH_FORMAT, DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'
import i18n from '@/plugins/vue-i18n'
const { saveAs } = require('file-saver')

const defaultStartDate = moment('2023-09').startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)
const defaultEndDate = moment('2023-09').endOf('month').format(DATE_TIME_FORM_FULL_FORMAT)

const state = {
  userSalaryList: [],
  filters: StorageService.get(storageKeys.USER_SALARY_FILTERS) || {
    ...defaultFilters({ date: `${defaultStartDate}|${defaultEndDate}` }),
  },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  userSalaryList: state => state.userSalaryList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadUserSalaryList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.USER_SALARY_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await userSalaryRepository.list(preparedFilters)

    context.commit('setUserSalary', { data, filters })

    return { userSalaryList: data.items, pagination: data.pagination }
  },
  updateUserSalary(context, payload) {
    const { id, ...data } = payload
    return userSalaryRepository.put(id, data)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.USER_SALARY_FILTERS)
  },
  async loadExportFile(context, filters) {
    const response = await userSalaryRepository.export(filters, { responseType: 'blob' })
    const preparedDateForFile = moment(filters.date, DATE_TIME_FORM_FULL_FORMAT).format(
      DATE_MONTH_DASH_FORMAT,
    )
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('userSalary.fileName')} ${preparedDateForFile}.xlsx`
    saveAs(blob, filename)
    return response
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.USER_SALARY_FILTERS, updatedFilters)
  },
}

const mutations = {
  setUserSalary(state, { data, filters }) {
    state.userSalaryList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/User/helpers'
import userRepo from '@/repository/admin/userRepository'
import { hasAppliedFilters, omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'

const state = {
  filters: StorageService.get(storageKeys.USER_FILTERS) || { ...defaultFilters },
  usersList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  usersList: state => state.usersList,
  hasAppliedFilters: state => hasAppliedFilters(state.filters),
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadUsersList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.USER_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await userRepo.list(preparedFilters)

    context.commit('setUsersList', { data, filters })

    return { users: data.items, pagination: data.pagination }
  },
  async searchUsersList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await userRepo.list(preparedFilters)

    return { users: data.items, pagination: data.pagination }
  },
  async loadUser(context, id) {
    const { data } = await userRepo.get(id)
    return { user: data }
  },
  createUser(context, payload) {
    return userRepo.post(payload)
  },
  updateUser(context, payload) {
    const { id, ...data } = payload
    return userRepo.put(id, data)
  },
  deleteUser(context, payload) {
    return userRepo.delete(payload)
  },
  bulkUpdateUser(context, payload) {
    return userRepo.bulkUpdate(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.USER_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.USER_FILTERS, updatedFilters)
  },
  bulkActiveStatusUpdate(context, payload) {
    return userRepo.bulkActiveStatusUpdate(payload)
  },
}

const mutations = {
  setUsersList(state, { data, filters }) {
    state.usersList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setOperatorGradeList(state, { data, filters }) {
    state.operatorGradeList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default [
  {
    path: '/call-centre/campaign',
    name: 'pageCampaign',
    meta: { title: 'page.pageCampaign', roles: ['CC_CAMPAIGN_VIEW'] },
    component: () => import('@/pages/call-centre/campaign'),
  },
  {
    path: '/call-centre/campaign/create',
    name: 'pageCampaignCreate',
    meta: { title: 'page.pageCampaignCreate', roles: ['CC_CAMPAIGN_VIEW'] },
    component: () => import('@/pages/call-centre/campaign/create'),
  },
  {
    path: '/call-centre/campaign/create-manual',
    name: 'pageCampaignManualCreate',
    meta: { title: 'page.pageCampaignCreateManual', roles: ['CC_CAMPAIGN_VIEW'] },
    component: () => import('@/pages/call-centre/campaign/createManual'),
  },
  {
    path: '/call-centre/campaign/:id/edit',
    name: 'pageCampaignEdit',
    meta: { title: 'page.pageCampaignEdit' },
    component: () => import('@/pages/call-centre/campaign/id/edit'),
  },
]

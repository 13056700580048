import SuperRepository from '../superRepository'

export default new (class DashboardOperatorRepository extends SuperRepository {
  baseUri() {
    return 'dashboard_operator'
  }
  operatorRating = async params =>
    this.httpClient().get(`${this.baseUri()}/operator_rating`, params)
  orderAndApprove = async params =>
    this.httpClient().get(`${this.baseUri()}/order_and_approved`, params)
  dailyEarning = async params => this.httpClient().get(`${this.baseUri()}/daily_earning`, params)
})()

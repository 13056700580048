import SuperRepository from '../superRepository'

export default new (class UserDayScheduleRepository extends SuperRepository {
  baseUri() {
    return 'user_day_schedule'
  }
  viewAll = async params => this.httpClient().post(`${this.baseUri()}/view_all`, params)
  viewAllId = async (id, params = {}) =>
    this.httpClient().post(`${this.baseUri()}/${id}/view`, params)
})()

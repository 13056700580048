export default [
  {
    path: '/settings/delivery-excluded-rules',
    name: 'pageDeliveryExcludedRulesList',
    meta: { title: 'page.pageExcludedOffersForSDList', roles: ['SETTING_DELIVERY_EXCLUDED_RULE_VIEW'] },
    component: () => import('@/pages/settings/delivery-excluded-rules'),
  },
  {
    path: '/settings/delivery-excluded-rules/create',
    name: 'pageDeliveryExcludedRulesCreate',
    meta: { title: 'page.pageExcludedOffersForSDCreate', roles: ['SETTING_DELIVERY_EXCLUDED_RULE_VIEW'] },
    component: () => import('@/pages/settings/delivery-excluded-rules/create'),
  },
  {
    path: '/settings/delivery-excluded-rules/:id/edit',
    name: 'pageDeliveryExcludedRulesEdit',
    meta: { title: 'page.pageExcludedOffersForSDEdit', roles: ['SETTING_DELIVERY_EXCLUDED_RULE_VIEW'] },
    component: () => import('@/pages/settings/delivery-excluded-rules/id/edit'),
  },
]

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    // offers: null,
    offers: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    active: 1,
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

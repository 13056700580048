import SuperRepository from '../superRepository'

export default new (class questionnaireRepository extends SuperRepository {
  baseUri() {
    return 'questionnaire'
  }
  export = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/export`, data, params)
  duplicate = async (data, params) =>
    this.httpClient().post(`${this.baseUri()}/${data.id}/duplicate`, null, { params })
})()

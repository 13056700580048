import permissionRepo from '@/repository/common/permissionRepository'
function convertTreeToList(arr) {
  return arr.reduce((cur, item) => {
    const { items, ...rest } = item

    if (items) {
      cur = cur.concat(convertTreeToList(items))
    } else {
      cur.push(rest)
    }

    return cur
  }, [])
}

const state = {
  permissionTree: [],
  permissionList: [],
}

const getters = {
  permissionTree: state => state.permissionTree || [],
  permissionList: state => state.permissionList || [],
}

const actions = {
  async loadPermissionTree(context) {
    if (context.getters.permissionTree.length) {
      return { permissionTree: context.getters.permissionTree }
    }
    const { data } = await permissionRepo.list()
    context.commit('setPermissionTree', data)
    context.commit('setPermissionList', convertTreeToList(data))
    return { permissionTree: data }
  },
}

const mutations = {
  setPermissionTree(state, payload) {
    state.permissionTree = payload
  },
  setPermissionList(state, payload) {
    state.permissionList = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default [
  {
    path: '/notice-department/sms-campaign',
    name: 'pageSmsCampaign',
    meta: { title: 'page.pageSmsCampaign', roles: ['SIDEBAR_CAMPAIGN_SMS_VIEW'] },
    component: () => import('@/pages/notice-department/sms-campaign'),
  },
  {
    path: '/notice-department/sms-campaign/create',
    name: 'pageSmsCampaignCreate',
    meta: { title: 'page.pageSmsCampaignAuto', roles: ['SIDEBAR_CAMPAIGN_SMS_OPERATE'] },
    component: () => import('@/pages/notice-department/sms-campaign/create'),
  },
  {
    path: '/notice-department/sms-campaign/create-manual',
    name: 'pageSmsCampaignCreateManual',
    meta: { title: 'page.pageSmsCampaignManual', roles: ['SIDEBAR_CAMPAIGN_SMS_OPERATE'] },
    component: () => import('@/pages/notice-department/sms-campaign/createManual'),
  },
  {
    path: '/notice-department/sms-campaign/:id/edit',
    name: 'pageSmsCampaignEdit',
    meta: { title: 'page.pageSmsCampaignAuto', roles: ['SIDEBAR_CAMPAIGN_SMS_OPERATE'] },
    component: () => import('@/pages/notice-department/sms-campaign/id/edit'),
  },
]

import moment from 'moment/moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

const startOfMonth = moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)
const endOfMonth = moment().endOf('month').format(DATE_TIME_FORM_FULL_FORMAT)

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    user: {
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      grade: {
        id: {
          value: '',
          operator: 'like',
        },
      },
    },
    date: {
      value: `${startOfMonth}|${endOfMonth}`,
      operator: 'bwn',
    },
  },
  sortBy: ['id'],
  sortDesc: [false],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/CallCenterPrice/helpers'
import CallCenterPriceRepository from '@/repository/admin/callCenterPriceRepository'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'

const state = {
  callCenterPriceList: [],
  filters: StorageService.get(storageKeys.CALL_CENTER_PRICE_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  callCenterPriceList: state => state.callCenterPriceList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadCallCenterPriceList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.CALL_CENTER_PRICE_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await CallCenterPriceRepository.list(preparedFilters)

    context.commit('setCallCenterPrice', { data, filters })

    return { callCenterPriceList: data.items, pagination: data.pagination }
  },
  updatePrepayment(context, payload) {
    const { id, ...data } = payload
    return CallCenterPriceRepository.put(id, data)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.CALL_CENTER_PRICE_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.CALL_CENTER_PRICE_FILTERS, updatedFilters)
  },
}

const mutations = {
  setCallCenterPrice(state, { data, filters }) {
    state.callCenterPriceList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import SuperRepository from '../superRepository'

export default new (class GeoCityRepository extends SuperRepository {
  baseUri() {
    return 'city'
  }
  bulkUpdate = async (data, params) =>
    this.httpClient().put(`${this.baseUri()}/bulk_update`, data, params)
  bulkActivate = async (data, params) =>
    this.httpClient().put(`${this.baseUri()}/bulk_activate`, data, params)
})()

import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/OperatorGrades/helpers'
import OfferNormsRepository from '@/repository/admin/offerNormsRepository'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'

const state = {
  offerNormsList: [],
  filters: StorageService.get(storageKeys.OFFERS_NORMS_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  offerNormsList: state => state.offerNormsList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadOfferNorms(context, filters) {
    context.commit('setFilters', filters)
    StorageService.get(storageKeys.OFFERS_NORMS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })
    const { data } = await OfferNormsRepository.list(preparedFilters)

    context.commit('setOfferNorms', { data, filters })

    return { offerNormsList: data.items, pagination: data.pagination }
  },
  async loadOfferNorm(context, id) {
    const { data } = await OfferNormsRepository.get(id)
    return { offerNorms: data }
  },

  createOfferNorm(context, payload) {
    return OfferNormsRepository.post(payload)
  },
  updateOfferNorm(context, payload) {
    const { id, ...data } = payload
    return OfferNormsRepository.put(id, data)
  },
  deleteOfferNorm(context, payload) {
    return OfferNormsRepository.delete(payload)
  },
}

const mutations = {
  setOfferNorms(state, { data, filters }) {
    state.offerNormsList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    active: 1,
    timezone: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    region: {
      id: {
        value: '',
        operator: 'in',
      },
      country: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

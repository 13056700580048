export default [
  {
    path: '/settings/user',
    name: 'pageUserList',
    meta: { title: 'page.pageUserList', roles: ['SETTING_USER_VIEW'] },
    component: () => import('@/pages/settings/user'),
  },
  {
    path: '/settings/user/create',
    name: 'pageUserCreate',
    meta: { title: 'page.pageUserCreate', roles: ['SETTING_USER_VIEW'] },
    component: () => import('@/pages/settings/user/create'),
  },
  {
    path: '/settings/user/:id/edit',
    name: 'pageUserEdit',
    meta: { title: 'page.pageUserEdit', roles: ['SETTING_USER_VIEW'] },
    component: () => import('@/pages/settings/user/id/edit'),
  },
]

import moment from 'moment'
import { DATE_FORM_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    firstGroup: 'warehouse',
    secondGroup: 'organization',
    thirdGroup: 'delivery_service',
    fourthGroup: 'delivery_type',
    dateFrom: moment('2023-09').startOf('month').format(DATE_FORM_FORMAT),
    dateTo: moment('2023-09').endOf('month').format(DATE_FORM_FORMAT),
    countries: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    regions: {
      filter: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    deliveryType: [],
  },
  clientOptions: {
    filterPanelExpanded: false,
  },
}

import SuperRepository from '../superRepository'

export default new (class PermissionRepository extends SuperRepository {
  baseUri() {
    return 'permission'
  }
  permissionList = async params => this.httpClient().get(`${this.baseUri()}/list`, params)
  permissionViewList = async ({ permission, params }) =>
    this.httpClient().get(`${this.baseUri()}/users_by_permission/${permission}`, params)
  put = async params => this.httpClient().put(`${this.baseUri()}/remove_user_permission`, params)
})()

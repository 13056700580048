<template>
  <tr>
    <td
      v-for="header in headers"
      :key="header.value"
      :class="getHeaderClasses(header)"
      :style="getCellStyles(header)"
    >
      <slot :name="`item.${header.value}`" :item="item" :value="item[header.value]">
        {{ item[header.value] }}
      </slot>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'UiExpandContent',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    group: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getCellStyles(header) {
      const defaultStyles = {
        background: '#fff',
        borderBottom: '1px var(--v-black-lighten6) solid',
      }

      if (!header.width) {
        return defaultStyles
      }

      return {
        ...defaultStyles,
        width: header.width,
        minWidth: header.width,
        maxWidth: header.width,
      }
    },
    getHeaderClasses(header) {
      const hasFixedEls = header.fixed && header.fixed.side
      return [
        { 'v-data-table__divider': header.divider },
        { 'fixed-col expanded-col': hasFixedEls },
        `${hasFixedEls ? header.fixed.side : ''}`,
        header.class,
        header.align ? 'text-' + header.align : '',
      ]
    },
  },
}
</script>

import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    offer: {
      id: {
        value: '',
        operator: 'in',
      },
      niche: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    landing: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    partnerId: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    createdAt: {
      value: null,
      operator: 'bwn',
    },
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedWebAnalyticaFilters(filter) {
  const filters = cloneDeep(defaultFilters.filter)

  filters.offer.id = joinBySymbol(filter.offers)

  filters.offer.niche.id = joinBySymbol(filter.offerNiches)

  filters.landing.id = joinBySymbol(filter.landings)

  filters.partnerId.id = joinBySymbol(filter.orderWebs)

  filters.createdAt.value = joinBySymbol(filter.date)

  return filters
}

export const OPERATOR_STATUS = {
  NOT_AVAILABLE: 'not_available',
  WAIT: 'wait',
  PERSONAL_BREAK: 'personal_break',
  TECHNICAL_BREAK: 'technical_break',
  MANUAL_CALL: 'manual_call',
  EDUCATION: 'education',
  CONSULTATION: 'consultation',
  KLN: 'kln',
  ORDER_PROCESSING: 'order_processing',
}

import moment from 'moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

const startOfMonth = moment('2023-09').startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    groups: {
      id: {
        value: '',
        operator: 'in',
      },
      manager: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
  },
  date: `${startOfMonth}`,
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

import SuperRepository from '../superRepository'

export default new (class OrderRepository extends SuperRepository {
  baseUri() {
    return 'order'
  }
  list = async (params, config = {}) => {
    return this.httpClient().get(`${this.baseUri()}`, params, config)
  }
  fraud = async params => this.httpClient().get(`${this.baseUri()}/fraud_statistic`, params)
  loadAdditionalPhone = async id =>
    this.httpClient().get(`${this.baseUri()}/${id}/additional_phone`)
  loadOrderHistory = async ({ id, params }) =>
    this.httpClient().get(`${this.baseUri()}/${id}/order_history`, params)
  loadOrderQuestionnaire = async id =>
    this.httpClient().get(`${this.baseUri()}/${id}/questionnaire`)
  report = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/order_report`, data, params)
  webList = async params => this.httpClient().get(`${this.baseUri()}/orders_web_list`, params)
  bulkUpdate = async (data, params) =>
    this.httpClient().put(`${this.baseUri()}/bulk_update`, data, params)
  suspiciousBulkUpdate = async (data, params) =>
    this.httpClient().put(`${this.baseUri()}/suspicious_bulk_update`, data, params)
  analytica = async params => this.httpClient().get(`${this.baseUri()}/web_analytica`, params)
  createPackerTask = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/packer_task`, data, params)
  exportDistrict = async (params, config = {}) => {
    return this.httpClient().get(`${this.baseUri()}/order_export_district`, params, config)
  }
  distributionCheck = async params =>
    this.httpClient().post(`${this.baseUri()}/distribution_of_check_report`, params)
  ordersWithoutBarcodeReport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/order_with_out_barcode`, data, params)
  startDispute = async id => this.httpClient().post(`${this.baseUri()}/${id}/dispute-start`)
  loadPhoneInfo = async ({ id, params }) =>
    this.httpClient().get(`${this.baseUri()}/${id}/phone`, params)
  helpNotification = async id => this.httpClient().post(`${this.baseUri()}/${id}/help_notification`)
  sendSms = async ({ id, params }) =>
    this.httpClient().post(`${this.baseUri()}/${id}/send_sms`, params)
})()

export default [
  {
    path: '/settings/ip-address',
    name: 'pageIpAddressList',
    meta: { title: 'page.pageIpAddressList', roles: ['SETTING_PRODUCT_TYPE_VIEW'] },
    component: () => import('@/pages/settings/ip-address'),
  },
  {
    path: '/settings/ip-address/create',
    name: 'pageIpAddressCreate',
    meta: { title: 'page.pageIpAddressCreate', roles: ['SETTING_PRODUCT_TYPE_VIEW'] },
    component: () => import('@/pages/settings/ip-address/create'),
  },
  {
    path: '/settings/ip-address/:id/edit',
    name: 'pageIpAddressEdit',
    meta: { title: 'page.pageIpAddressEdit', roles: ['SETTING_PRODUCT_TYPE_VIEW'] },
    component: () => import('@/pages/settings/ip-address/id/edit'),
  },
]

import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/Penalty/helpers'
import userRepository from '@/repository/admin/userRepository'
import penaltyRepository from '@/repository/admin/penaltyRepository'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'
import holdRepository from '@/repository/admin/holdRepository'
import profitRepository from '@/repository/admin/profitRepository'

const state = {
  filters: StorageService.get(storageKeys.PENALTY_FILTERS) || { ...defaultFilters },
  penaltyList: [],
  penaltyModalList: [],
  holdModalList: [],
  profitModalList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  penaltyList: state => state.penaltyList,
  penaltyModalList: state => state.penaltyModalList,
  holdModalList: state => state.holdModalList,
  profitModalList: state => state.profitModalList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadPenaltyList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.PENALTY_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilter = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilter,
    })
    const { data } = await userRepository.penalty(preparedFilters)

    context.commit('setPenaltyList', { data, filters })

    return { penaltyList: data.items, pagination: data.pagination }
  },
  bulkPenalty(context, payload) {
    return userRepository.bulkPenaltyUpdate(payload)
  },
  async loadModalPenalty(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const { data } = await penaltyRepository.list(preparedFilters)

    context.commit('setModalPenaltyList', { data })

    return { penaltyModalList: data.items }
  },
  async loadModalHold(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const { data } = await holdRepository.list(preparedFilters)

    context.commit('setModalHoldList', { data })

    return { holdModalList: data.items }
  },
  async loadModalProfit(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const { data } = await profitRepository.list(preparedFilters)

    context.commit('setModalProfitList', { data })

    return { profitModalList: data.items }
  },
  updatePenalty(context, payload) {
    const { id, ...data } = payload
    return penaltyRepository.put(id, data)
  },
  createPenalty(context, payload) {
    return penaltyRepository.post(payload)
  },
  updateHold(context, payload) {
    const { id, ...data } = payload
    return holdRepository.put(id, data)
  },
  createHold(context, payload) {
    return holdRepository.post(payload)
  },
  updateProfit(context, payload) {
    const { id, ...data } = payload
    return profitRepository.put(id, data)
  },
  createProfit(context, payload) {
    return profitRepository.post(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.PENALTY_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.PENALTY_FILTERS, updatedFilters)
  },
}

const mutations = {
  setPenaltyList(state, { data, filters }) {
    state.penaltyList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setModalPenaltyList(state, { data }) {
    state.penaltyModalList = [...data.items]
  },
  setModalHoldList(state, { data }) {
    state.holdModalList = [...data.items]
  },
  setModalProfitList(state, { data }) {
    state.profitModalList = [...data.items]
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

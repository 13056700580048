export default [
  {
    path: '/settings/phone-codes',
    name: 'pagePhoneCodesList',
    meta: { title: 'page.pagePhoneCodesList', roles: ['SETTING_DIAL_CODE_VIEW'] },
    component: () => import('@/pages/settings/phone-codes'),
  },
  {
    path: '/settings/phone-codes/create',
    name: 'pagePhoneCodesCreate',
    meta: { title: 'page.pagePhoneCodesCreate', roles: ['SETTING_DIAL_CODE_VIEW'] },
    component: () => import('@/pages/settings/phone-codes/create'),
  },
  {
    path: '/settings/phone-codes/:id/edit',
    name: 'pagePhoneCodesEdit',
    meta: { title: 'page.pagePhoneCodesEdit', roles: ['SETTING_DIAL_CODE_VIEW'] },
    component: () => import('@/pages/settings/phone-codes/id/edit'),
  },
]

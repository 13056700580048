import { defaultFilters } from '@/models/Permission/helpers'
import { defaultFiltersView } from '@/models/PermissionView/helpers'
import permissionRepo from '@/repository/common/permissionRepository'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'

const state = {
  filters: { ...defaultFilters },
  filtersView: { ...defaultFiltersView },
  permissionList: [],
  permissionViewList: [],
  tableOptions: TableOptions.defaultTableOptions(),
  tableOptionsView: TableOptions.defaultTableOptions(),
  loadingData: false,
}

const getters = {
  filters: state => state.filters,
  filtersView: state => state.filtersView,
  tableOptions: state => state.tableOptions,
  tableOptionsView: state => state.tableOptionsView,
  permissionList: state => state.permissionList,
  permissionViewList: state => state.permissionViewList,
  loadingData: state => state.loadingData,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadPermissionList(context, filters) {
    context.commit('setLoadingData', true)

    filters && context.commit('setFilters', filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await permissionRepo.permissionList(preparedFilters)

    context.commit('setPermissionList', { data, filters })

    context.commit('setLoadingData', false)
    return { permissionList: data.items, pagination: data.pagination }
  },
  async loadPermissionViewList(context, filters) {
    context.commit('setLoadingData', true)

    filters && context.commit('setFiltersView', filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })
    const filter = {
      params: {
        ...preparedFilters,
      },
      permission: preparedFilters.permission,
    }

    const { data } = await permissionRepo.permissionViewList(filter)

    context.commit('setPermissionViewList', { data, filters })

    context.commit('setLoadingData', false)

    return { permissionViewList: data.items, pagination: data.pagination }
  },
  async updateAccessControl({ dispatch }, payload) {
    await permissionRepo.put(payload)
    dispatch('loadPermissionViewList', { ...defaultFiltersView })
  },
}

const mutations = {
  setPermissionList(state, { data, filters }) {
    state.permissionList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setPermissionViewList(state, { data, filters }) {
    state.permissionViewList = [...data.items]
    state.tableOptionsView = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
  setFiltersView(state, filters) {
    state.filtersView = { ...filters }
  },
  setLoadingData(state, payload) {
    state.loadingData = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

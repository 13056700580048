import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/LogAuth/helpers'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import logRepo from '@/repository/admin/logRepository'
const { saveAs } = require('file-saver')

const state = {
  filters: StorageService.get(storageKeys.LOG_FILTERS_AUTH) || { ...defaultFilters },
  logList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  logList: state => state.logList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadLogList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.LOG_FILTERS_AUTH, filters)
    const appliedFilters = omitEmptyDeepFilters(filters)
    delete appliedFilters.clientOptions

    const { data } = await logRepo.list(appliedFilters)

    context.commit('setLog', { data, filters })

    return { logList: data.items, pagination: data.pagination }
  },
  async searchLogList(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await logRepo.list(preparedFilters)

    return { logList: data.items, pagination: data.pagination }
  },
  async loadLog(context, id) {
    const { data } = await logRepo.get(id)
    return data
  },
  async loadExportFile(context, filters) {
    const response = await logRepo.export(filters, { responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = 'User auth logs.xlsx'
    saveAs(blob, filename)
    return response
  },
  createLog(context, payload) {
    return logRepo.post(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.LOG_FILTERS_AUTH)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.LOG_FILTERS_AUTH, updatedFilters)
  },
}

const mutations = {
  setLog(state, { data, filters }) {
    state.logList = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default [
  {
    path: '/manager/financial-report',
    name: 'pageFinancialReport',
    meta: { title: 'page.pageFinancialReport', roles: ['MNG_FIN_REPORT'] },
    component: () => import('@/pages/manager/financial-report'),
  },
  {
    path: '/manager/financial-report/:uuid',
    name: 'pageFinancialOrdersReport',
    meta: { title: 'page.pageFinancialOrdersReport', roles: ['MNG_FIN_REPORT'] },
    component: () => import('@/pages/manager/financial-report/uuid/index'),
  },
]

import deliveryPointImportRepository from '@/repository/admin/deliveryPointImportRepository'
const { saveAs } = require('file-saver')
import i18n from '@/plugins/vue-i18n'

function renderHeaders(data) {
  if (!data) return []
  return Array.isArray(data) && data.length
    ? Object.keys(data[0]).map((key, index) => {
        return {
          text: i18n.t(`deliveryPointsImport.field.${key}`),
          sortable: false,
          value: key,
          index: index,
        }
      })
    : []
}

const state = {
  deliveryPointsPreviewList: [],
  deliveryPointsPreviewHeaders: [],
}

const getters = {
  deliveryPointsPreviewList: state => state.deliveryPointsPreviewList,
  deliveryPointsPreviewHeaders: state => state.deliveryPointsPreviewHeaders,
}

const actions = {
  async loadExportFile() {
    const response = await deliveryPointImportRepository.export()
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = 'Шаблон импорта точек доставки.xlsx'
    saveAs(blob, filename)
    return response
  },
  async importFile(context, payload) {
    const { formData, config } = payload
    return await deliveryPointImportRepository.import(formData, config)
  },
  async loadPreviewFile(context, payload) {
    const { formData, config } = payload
    const { data } = await deliveryPointImportRepository.preview(formData, config)

    const headers = renderHeaders(data)

    context.commit('setDeliveryPointsPreviewHeaders', headers)
    context.commit('setDeliveryPointsPreviewList', data)

    return {
      deliveryPointsPreviewList: data,
      deliveryPointsPreviewHeaders: headers,
    }
  },
}

const mutations = {
  setDeliveryPointsPreviewList(state, data) {
    state.deliveryPointsPreviewList = data
  },
  setDeliveryPointsPreviewHeaders(state, data) {
    state.deliveryPointsPreviewHeaders = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import salesScriptStyleRepo from "@/repository/admin/salesScriptStyleRepository"
import { buildDOMStyles } from "@/helpers/styles"

async function fetchData(context) {
  const { data } = await salesScriptStyleRepo.list({ pagination: { limit: -1 }, order: { active: 'desc' } })
  context.commit('setScriptStyleList', data.items)
  buildDOMStyles(data.items)
  return { scriptStyleList: data.items }
}


const state = {
  scriptStyleList: []
}

const getters = {
  scriptStyleList: state => state.scriptStyleList,
  scriptStyleActiveList: state => state.scriptStyleList.filter(item => item.active),
}

const actions = {
  async loadScriptStyleList (context) {
    if (context.getters.scriptStyleList.length) {
      return { scriptStyleList: context.getters.scriptStyleList }
    }
    return await fetchData(context)
  },
  async reLoadScriptStyleList (context) {
    return await fetchData(context)
  },
  async loadScriptStyle (context, id) {
    const { data } = await salesScriptStyleRepo.get(id)
    return data
  },
  createScriptStyle (context, payload) {
    return salesScriptStyleRepo.post(payload)
  },
  updateScriptStyle (context, payload) {
    const { id, ...data } = payload
    return salesScriptStyleRepo.put(id, data)
  },
  deleteScriptStyle (context, payload) {
    return salesScriptStyleRepo.delete(payload)
  }
}

const mutations = {
  setScriptStyleList (state, payload) {
    state.scriptStyleList = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

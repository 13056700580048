export default [
  {
    path: '/settings/status-reason',
    name: 'pageRejectReasonList',
    meta: { title: 'page.pageRejectReasonList', roles: ['SETTING_ORDER_STATUS_REASON_VIEW'] },
    component: () => import('@/pages/settings/status-reason'),
  },
  {
    path: '/settings/status-reason/create',
    name: 'pageRejectReasonCreate',
    meta: { title: 'page.pageRejectReasonCreate', roles: ['SETTING_ORDER_STATUS_REASON_VIEW'] },
    component: () => import('@/pages/settings/status-reason/create'),
  },
  {
    path: '/settings/status-reason/:id/edit',
    name: 'pageRejectReasonEdit',
    meta: { title: 'page.pageRejectReasonEdit', roles: ['SETTING_ORDER_STATUS_REASON_VIEW'] },
    component: () => import('@/pages/settings/status-reason/id/edit'),
  },
]

import SuperRepository from '../superRepository'

export default new (class smsOperatorRelationRepo extends SuperRepository {
  baseUri() {
    return 'sms_operator_relation'
  }
  operatorList = async params => this.httpClient().get(`${this.baseUri()}/operators`, params)
  bulkCreate = async (data, params) =>
    this.httpClient().post(`${this.baseUri()}/bulk_create`, data, params)
})()

export default [
  {
    path: '/settings/delivery-warehouse',
    name: 'pageDeliveryWarehouseList',
    meta: { title: 'page.pageDeliveryWarehouseList', roles: ['SETTING_WAREHOUSE_VIEW'] },
    component: () => import('@/pages/settings/delivery-warehouse'),
  },
  {
    path: '/settings/delivery-warehouse/:id/edit',
    name: 'pageDeliveryWarehouseEdit',
    meta: { title: 'page.pageDeliveryWarehouseEdit', roles: ['SETTING_WAREHOUSE_VIEW'] },
    component: () => import('@/pages/settings/delivery-warehouse/id/edit'),
  },
]

export default [
  {
    path: '/settings/finance-settings',
    name: 'pageFinanceList',
    meta: { title: 'page.pageFinanceList', roles: ['SETTING_COUNTRY_SETTING_VIEW'] },
    component: () => import('@/pages/settings/finance-settings'),
  },
  {
    path: '/settings/finance-settings/create',
    name: 'pageFinanceCreate',
    meta: { title: 'page.pageFinanceCreate', roles: ['SETTING_COUNTRY_SETTING_VIEW'] },
    component: () => import('@/pages/settings/finance-settings/create'),
  },
  {
    path: '/settings/finance-settings/:id/edit',
    name: 'pageFinanceEdit',
    meta: { title: 'page.pageFinanceEdit', roles: ['SETTING_COUNTRY_SETTING_VIEW'] },
    component: () => import('@/pages/settings/finance-settings/id/edit'),
  },
]

import moment from 'moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFilters = {
  objectType: 'order',
  objectId: undefined,
  event: ['watch_number', 'open_order'],
  loggedAt: {
    gte: `${moment('2023-09').startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}`,
    lte: `${moment('2023-09').endOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}`,
  },
  order_date: 'desc',
  source: undefined,
}

<template>
  <v-btn v-on="$listeners" v-bind="attributes">
    <span v-if="$scopedSlots.iconLeft" class="mr-2">
      <slot name="iconLeft" />
    </span>

    <span :class="textClasses">
      <slot />
    </span>

    <span v-if="$scopedSlots.iconRight" class="ml-2">
      <slot name="iconRight" />
    </span>
  </v-btn>
</template>
<script>
export default {
  name: 'UiButton',
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
      validator(type) {
        return ['default', 'outline', 'flat'].includes(type)
      },
    },
    variant: {
      type: String,
      default: 'primary',
      validator(type) {
        return ['primary', 'error', 'warning', 'success', 'white'].includes(type)
      },
    },
    size: {
      type: String,
      default: 'default',
      validator(type) {
        return ['default', 'x-small', 'small', 'large', 'x-large'].includes(type)
      },
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    iconBorderColor: {
      type: String,
      default: 'black lighten-3',
    },
    round: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    attributes() {
      return {
        ...this.themeProperties,
        ...this.iconProperties,
        [this.size]: true,
        block: this.block,
        rounded: this.round,
        disabled: this.disabled,
      }
    },

    themeProperties() {
      switch (this.theme) {
        case 'default':
          return {
            elevation: 0,
            color: this.variant,
          }
        case 'outline':
          return {
            color: !this.disabled ? 'black lighten-6' : '',
            elevation: 0,
            outlined: true,
          }
        case 'flat':
          return {
            elevation: 0,
            text: true,
          }
        default:
          return {}
      }
    },
    iconProperties() {
      if (!this.isIcon) {
        return {}
      }

      return {
        outlined: this.theme !== 'flat',
        color: this.active ? 'primary' : this.iconBorderColor,
        icon: true,
        tile: !this.round,
        fab: false,
      }
    },
    textClasses() {
      switch (this.theme) {
        case 'default':
          return {}
        case 'outline':
          return !this.disabled ? 'black--text text--darken-3' : ''
        case 'flat':
          return 'black--text text--darken-3 font-weight-regular'
        default:
          return {}
      }
    },
  },
}
</script>

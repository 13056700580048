import { cloneDeep } from 'lodash'
import { joinBySymbol } from '@/helpers/filters'
import { DATE_FORM_FORMAT } from '@/constans/date'
import moment from 'moment'
import { OPERATOR_STATUS } from '@/constans/operatorStatuses'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    dateFrom: moment('2023-09-15').subtract(1, 'days').format(DATE_FORM_FORMAT),
    dateTo: moment('2023-09-15').format(DATE_FORM_FORMAT),
    status: OPERATOR_STATUS.NOT_AVAILABLE,
    timeType: 'avg',
    userGroups: {
      id: {
        value: '',
        operator: 'in',
      },
    },
  },
  sortBy: ['id'],
  sortDesc: [false],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export function preparedWorkTimeFilters(filter) {
  const filters = cloneDeep(defaultFilters.filter)

  filters.userGroups.id.value = joinBySymbol(filter.userGroups)
  filters.timeType = filter.timeType
  filters.dateFrom = filter.dateFrom
  filters.dateTo = filter.dateTo

  return filters
}

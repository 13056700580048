export default [
  {
    path: '/settings/offer',
    name: 'pageOfferList',
    meta: { title: 'page.pageOfferList', roles: ['SETTING_OFFER_VIEW'] },
    component: () => import('@/pages/settings/offer'),
  },
  {
    path: '/settings/offer/create',
    name: 'pageOfferCreate',
    meta: { title: 'page.pageOfferCreate', roles: ['SETTING_OFFER_VIEW'] },
    component: () => import('@/pages/settings/offer/create'),
  },
  {
    path: '/settings/offer/:id/edit',
    name: 'pageOfferEdit',
    meta: { title: 'page.pageOfferEdit', roles: ['SETTING_OFFER_VIEW'] },
    component: () => import('@/pages/settings/offer/id/edit'),
  },
]

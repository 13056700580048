<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="innerDate"
    transition="scale-transition"
    offset-y
    z-index="500"
    max-width="300"
    content-class="ui-date-picker-menu"
  >
    <template v-slot:activator="props">
      <slot
        name="activator"
        v-bind="{
          ...props,
          inputValue: formattedValue,
        }"
      />
    </template>

    <v-date-picker v-model="innerDate" type="month" v-bind="datePickerSettings" />

    <v-card color="white" class="px-3 pb-3">
      <v-card-actions class="justify-end px-0">
        <ui-button theme="outline" size="small" class="d-flex" @click="menu = false">
          {{ $t('base.cancel') }}
        </ui-button>

        <ui-button size="small" class="d-flex" @click="onApply">
          {{ $t('base.apply') }}
        </ui-button>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { DATE_MONTH_FORMAT, DATE_TIME_FORMAT, DATE_YEAR_MONTH_FORMAT } from '@/constans/date'
import { isFunction } from 'lodash'
import moment from 'moment'
import 'moment/locale/ru'
const KEYDOWN_ENTER = 'Enter'

export default {
  name: 'ui-date-month-picker',
  props: {
    value: {
      type: String,
      default: '',
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    displayDateFormatter: {
      type: Function,
      default: null,
    },
    returnValueFormat: {
      type: String,
      default: DATE_TIME_FORMAT,
    },
    isSingleValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      innerDate: '',
    }
  },
  computed: {
    isEmptyDate() {
      return !this.innerDate.length
    },
    formattedValue() {
      moment.locale(this.$i18n.locale)
      if (this.isEmptyDate) {
        return this.$t('base.selectMonth')
      }

      const date = this.innerDate

      if (isFunction(this.displayDateFormatter)) {
        return this.displayDateFormatter(date)
      }

      return `${this.$moment(date).format(DATE_MONTH_FORMAT)}`
    },
    headerText() {
      moment.locale(this.$i18n.locale)

      if (this.isEmptyDate) {
        return ''
      }

      const date = this.innerDate

      return `${this.$moment(date).format(DATE_MONTH_FORMAT)}`
    },
    datePickerSettings() {
      const defaultSettings = {
        showCurrent: true,
        flat: true,
        color: 'primary base',
        scrollable: true,
        width: '300',
        firstDayOfWeek: 1,
        locale: this.$i18n.locale,
        selectedItemsText: this.headerText,
      }

      return {
        ...defaultSettings,
        ...this.settings,
      }
    },
  },
  watch: {
    value: {
      handler(dates) {
        if (dates) {
          const date = dates.split('|')[0]
          this.innerDate = this.$moment(date).format(DATE_YEAR_MONTH_FORMAT)
        } else {
          this.innerDate = ''
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown)
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === KEYDOWN_ENTER) {
        this.onApply()
      }
    },
    onApply() {
      this.$refs.menu.save(this.innerDate)

      if (this.isEmptyDate) {
        return
      }

      const date = this.innerDate

      const startDate = this.$moment(date).startOf('month').format(this.returnValueFormat)
      const endDate = this.$moment(date).endOf('month').format(this.returnValueFormat)

      if (this.isSingleValue) {
        this.$emit('input', startDate)
      } else {
        this.$emit('input', `${startDate}|${endDate}`)
      }
    },
  },
}
</script>

<style lang="scss">
.ui-date-picker-menu {
  border-radius: 16px;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.08);
}
.ui-text-field-disabled {
  opacity: 0.6;
}
</style>

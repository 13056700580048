import dashboardManagerRepository from '@/repository/admin/dashboardManagerRepository'
import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import {
  amountAndAverageDefaultFilters,
  approvesByTimeDefaultFilters,
} from '@/models/ManagerDashboard/helpers'

const state = {
  filters: {
    hangingRansom: StorageService.get(storageKeys.DASHBOARD_HANGING_RANSOM) || {},
    deliveryService: StorageService.get(storageKeys.DASHBOARD_DELIVERY_SERVICE) || {},
    approvePensioner: StorageService.get(storageKeys.DASHBOARD_APPROVE_PENSIONER) || {},
    noRansom72hours: StorageService.get(storageKeys.DASHBOARD_NO_RANSOM_72_HOURS) || {},
    approvesByTime: StorageService.get(storageKeys.DASHBOARD_APPROVES_BY_TIME) || {
      ...approvesByTimeDefaultFilters,
    },
    amountAndAverage: StorageService.get(storageKeys.DASHBOARD_AMOUNT_AND_AVERAGE) || {
      ...amountAndAverageDefaultFilters,
    },
  },
}

const getters = {
  filters: state => state.filters,
}

const actions = {
  async loadDeliveryService(context, date) {
    context.commit('setDeliveryServiceFilters', date)
    StorageService.set(storageKeys.DASHBOARD_DELIVERY_SERVICE, date)
    const { data } = await dashboardManagerRepository.deliveryStatistic(date)
    return data
  },
  async loadOrderAndApprove() {
    const { data } = await dashboardManagerRepository.orderAndApprove()
    return data
  },
  async loadDeliveryServiceDebt() {
    const { data } = await dashboardManagerRepository.deliveryServiceDebt()
    return data
  },
  async loadHangingRansom(context, date) {
    context.commit('setHangingRansomFilters', date)
    StorageService.set(storageKeys.DASHBOARD_HANGING_RANSOM, date)
    const { data } = await dashboardManagerRepository.hangingRansom(date)
    return data
  },
  async loadApprovePensioner(context, date) {
    context.commit('setApprovePensionerFilters', date)
    StorageService.set(storageKeys.DASHBOARD_APPROVE_PENSIONER, date)
    const { data } = await dashboardManagerRepository.approvePensioner(date)
    return data
  },
  async loadNoRansom72Hours(context, date) {
    context.commit('setNoRansom72HoursFilters', date)
    StorageService.set(storageKeys.DASHBOARD_NO_RANSOM_72_HOURS, date)
    const { data } = await dashboardManagerRepository.noRansom72hours(date)
    return data
  },
  saveAmountAndAverageFilters(context, filters) {
    context.commit('setAmoundAndAverageFilters', filters)
    StorageService.set(storageKeys.DASHBOARD_AMOUNT_AND_AVERAGE, filters)
  },
  async loadAverageOrderStatusTime(context, payload) {
    const { data } = await dashboardManagerRepository.averageOrderStatusTime(payload)
    return data
  },
  saveApprovesByTimeFilters(context, filters) {
    context.commit('setApprovesByTimeFilters', filters)
    StorageService.set(storageKeys.DASHBOARD_APPROVES_BY_TIME, filters)
  },
  async loadApprovesByTime(context, date) {
    const { data } = await dashboardManagerRepository.approvesByTime(date)
    return data
  },
}

const mutations = {
  setHangingRansomFilters(state, filters) {
    state.filters.hangingRansom = { ...filters }
  },
  setDeliveryServiceFilters(state, filters) {
    state.filters.deliveryService = { ...filters }
  },
  setApprovePensionerFilters(state, filters) {
    state.filters.approvePensioner = { ...filters }
  },
  setApprovesByTimeFilters(state, filters) {
    state.filters.approvesByTime = { ...filters }
  },
  setAmoundAndAverageFilters(state, filters) {
    state.filters.amountAndAverage = { ...filters }
  },
  setNoRansom72HoursFilters(state, filters) {
    state.filters.noRansom72hours = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

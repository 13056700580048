import StorageService from '@/services/storage.service'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import { defaultFilters } from '@/models/City/helpers'
import organizationRepository from "@/repository/admin/organizationRepository";
import storageKeys from '@/constans/storageKeys'

const state = {
    filters: StorageService.get(storageKeys.ORGANIZATION_FILTERS) || {
        ...defaultFilters,
    },
    organizationList: [],
    tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
    filters: state => state.filters,
    tableOptions: state => state.tableOptions,
    organizationList: state => state.organizationList,
    isOpenFilterPanel: state =>
        state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
    async loadOrganizationList(context, filters) {
        context.commit('setFilters', filters)
        StorageService.set(storageKeys.ORGANIZATION_FILTERS, filters)

        const appliedFilters = omitEmptyDeepFilters(filters.filter)
        const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

        const preparedFilters = prepareFilters({
            ...filters,
            filter: appliedFilters,
            cfFilter: appliedCfFilters,
        })
        const { data } = await organizationRepository.list(preparedFilters)
        context.commit('setOrganizationList', { data, filters })

        return { organizationList: data.items, pagination: data.pagination }
    },
    filtersStorageReset() {
        StorageService.remove(storageKeys.ORGANIZATION_FILTERS)
    },
    updateFiltersClientOptions({ commit, getters }, clientOptions) {
        const updatedFilters = {
            ...getters.filters,
            clientOptions,
        }
        commit('setFilters', updatedFilters)
        StorageService.set(storageKeys.ORGANIZATION_FILTERS, updatedFilters)
    },
}

const mutations = {
    setOrganizationList(state, { data, filters }) {
        state.organizationList = [...data.items]
        state.tableOptions = new TableOptions(data.pagination, filters)
    },
    setFilters(state, filters) {
        state.filters = { ...filters }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

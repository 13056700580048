export default [
  {
    path: '/manager/hanging-ransom',
    name: 'pageHangingRansom',
    meta: { title: 'page.pageHangingRansom', roles: ['MNG_HANDING_RANSOM'] },
    component: () => import('@/pages/manager/hanging-ransom'),
  },
  {
    path: '/manager/hanging-ransom/unpaid-orders',
    name: 'pageUnpaidOrders',
    meta: { title: 'page.pageUnpaidOrders', roles: ['MNG_HANDING_RANSOM'] },
    component: () => import('@/pages/manager/hanging-ransom/unpaid-orders'),
  },
]

import moment from 'moment'
import { DATE_FORMAT, DATE_TIME_FORM_FULL_FORMAT, TIME_FORMAT } from '@/constans/date'

export function getDateByTimeZone(data) {
  if (data) {
    return new Date().toLocaleString('en-GB', {
      timeZone: data,
      hour: '2-digit',
      minute: '2-digit',
    })
  } else {
    return ''
  }
}

export function formatDate(date, format = DATE_FORMAT) {
  return date ? moment(new Date(date)).format(format) : date
}

export function convertSecondsToFormatDate(date, format = TIME_FORMAT) {
  return moment().startOf('day').seconds(date).format(format)
}

export function convertMinutesToFormatDate(dateMinutes, dateSeconds, format = TIME_FORMAT) {
  return moment().startOf('day').minutes(dateMinutes).seconds(dateSeconds).format(format)
}

export function diffDateSecondsToFormatDate(startDate, endDate, format = TIME_FORMAT) {
  const diff = moment(startDate).diff(moment(endDate), 'seconds')
  return moment().startOf('day').seconds(Math.abs(diff)).format(format)
}

export function formatRelativeTime(date) {
  const currentYear = moment().year()
  const dateFebruary = moment([currentYear, 1, 1]).format(DATE_TIME_FORM_FULL_FORMAT)
  const daysInMont = moment(dateFebruary).daysInMonth()
  const daysInYear = daysInMont === 28 ? 365 : 366

  moment.relativeTimeThreshold('d', daysInYear)
  return date ? moment(date).fromNow() : date
}

import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/ReconfirmationStatistic/helpers'
import orderReportRepository from '@/repository/admin/orderReportRepository'
import { mockPagination, omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import TableOptions from '@/models/TableOptions'

const state = {
  filters: StorageService.get(storageKeys.RECONFIRMATION_STATISTIC_FILTERS) || {
    ...defaultFilters,
  },
  reconfirmationList: [],
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  reconfirmationList: state => state.reconfirmationList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadReconfirmationList(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.RECONFIRMATION_STATISTIC_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilter = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilter,
    })
    const { data } = await orderReportRepository.reconfirmation(preparedFilters.filter)

    context.commit('setReconfirmationList', {
      data,
      pagination: mockPagination(Object.values(data), filters),
      filters,
    })

    return { reconfirmationList: data, pagination: data.pagination }
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.RECONFIRMATION_STATISTIC_FILTERS)
  },
  updateSavedFilters(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.RECONFIRMATION_STATISTIC_FILTERS, filters)

    context.commit('setReconfirmationList', {
      data: context.getters.reconfirmationList,
      pagination: mockPagination(Object.values(context.getters.reconfirmationList), filters),
      filters,
    })
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.RECONFIRMATION_STATISTIC_FILTERS, updatedFilters)
  },
}

const mutations = {
  setReconfirmationList(state, { data, filters, pagination }) {
    state.reconfirmationList = data
    state.tableOptions = new TableOptions(pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

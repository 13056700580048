import Vue from 'vue'
import store from '@/store'
import VueSocketIO from 'vue-socket.io'

export default function (token) {
  Vue.use(
    new VueSocketIO({
      debug: false,
      connection: process.env.VUE_APP_NOTIFICATION_SOCKET_URL,
      vuex: {
        store,
        actionPrefix: 'ws_',
      },
      options: {
        autoConnect: true,
        query: {
          token: token,
        },
      },
    }),
  )
}

import axios from 'axios'
import store from '@/store'
import { getTokenData } from '@/services/token.service'
import i18nService from '../services/i18n.service'

let isRefreshing = false
let subscribers = []
const subscribeTokenRefresh = cb => subscribers.push(cb)
const onRrefreshed = token => subscribers.map(cb => cb(token))

const axiosApiInstance = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_ORIGIN}/api`,
})

axiosApiInstance.interceptors.request.use(
  config => {
    config.headers = {
      Accept: 'application/json',
      'Accept-Language': config.loc || i18nService.getActiveLanguage(),
      locale: config.loc || i18nService.getActiveLanguage(),
      ...config.headers,
    }
    const tokenData = getTokenData()
    if (tokenData && config.url !== 'token/refresh') {
      const { token } = JSON.parse(tokenData)
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  error => {
    Promise.reject(error)
  },
)

axiosApiInstance.interceptors.response.use(
  response => {
    return response
  },
  async function (err) {
    const config = err.config
    const status = err.response ? err.response.status : null
    const originalRequest = config
    if (status === 401 && !['token/refresh', 'token/logout'].includes(config.url)) {
      if (!isRefreshing) {
        isRefreshing = true
        store
          .dispatch('AuthModule/onRefreshToken')
          .then(data => {
            isRefreshing = false
            onRrefreshed(data.token)
            subscribers = []
          })
          .catch(() => {
            isRefreshing = false
            subscribers = []
            store.dispatch('AuthModule/onLogout')
          })
      }
      const requestSubscribers = new Promise(resolve => {
        subscribeTokenRefresh(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`
          resolve(axiosApiInstance(originalRequest))
        })
      })
      return requestSubscribers
    }
    if (status === 401 && ['token/refresh', 'token/logout'].includes(config.url)){
      store.commit('AuthModule/purgeAuth')
      window.location = '/login'
    }
    return Promise.reject(err)
  },
)

export default axiosApiInstance

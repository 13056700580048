import moment from 'moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    createdAt: {
      value: `${moment('2023-09').startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${moment('2023-09')
        .endOf('month')
        .format(DATE_TIME_FORM_FULL_FORMAT)}`,
      operator: 'bwn',
    },
    status: {
      value: 'shipped|approved|awaiting_ransom',
      operator: 'in',
    },
    deliveryService: {
      type: {
        value: 'post',
        operator: 'in',
      },
    },
    countries: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    regions: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    districts: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    cities: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    tags: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    groups: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    offers: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    operator: null,
  },
  sortBy: ['id'],
  sortDesc: [false],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

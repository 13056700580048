<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="innerDate"
    transition="scale-transition"
    offset-y
    z-index="500"
    max-width="300"
    content-class="ui-date-picker-menu"
  >
    <template v-slot:activator="props">
      <slot
        name="activator"
        v-bind="{
          ...props,
          inputValue: formattedValue,
        }"
      />
    </template>

    <v-date-picker v-model="innerDate" type="date" v-bind="datePickerSettings" @input="resetPreset">
      <v-container fluid class="py-3 px-1">
        <v-row v-if="showShortcuts">
          <v-col cols="12" class="pb-1">
            <ui-select
              v-model="presetDate"
              :options="itemsPeriod"
              size="small"
              :label-name="$t('base.choosePeriod')"
              @input="onSelectPreset"
            />
          </v-col>
        </v-row>

        <v-row v-if="showTime">
          <v-col cols="6" class="pb-1 pr-1 pt-1">
            <ui-time-picker v-model="innerTime.start">
              <template #activator="props">
                <ui-text-field :value="props.inputValue" dense readonly v-on="props.on">
                  <template #prepend-inner>
                    <v-icon color="black lighten-2" size="large" style="margin-top: 2px"
                      >mdi-clock-time-five-outline</v-icon
                    >
                  </template>
                </ui-text-field>
              </template>
            </ui-time-picker>
          </v-col>

          <v-col cols="6" class="pb-1 pl-1 pt-1">
            <ui-time-picker v-model="innerTime.end">
              <template #activator="props">
                <ui-text-field :value="props.inputValue" dense readonly v-on="props.on">
                  <template #prepend-inner>
                    <v-icon color="black lighten-2" size="large" style="margin-top: 2px"
                      >mdi-clock-time-five-outline</v-icon
                    >
                  </template>
                </ui-text-field>
              </template>
            </ui-time-picker>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="pr-1">
            <ui-button theme="outline" block class="w-100" size="small" @click="menu = false">
              {{ $t('base.cancel') }}
            </ui-button>
          </v-col>

          <v-col cols="6" class="pl-1">
            <ui-button block class="w-100" size="small" @click="onApply">
              {{ $t('base.apply') }}
            </ui-button>
          </v-col>
        </v-row>
      </v-container>
    </v-date-picker>
  </v-menu>
</template>

<script>
import {
  DATE_FULL_MONTH_FORMAT,
  DATE_TIME_FORMAT,
  TIME_SHORT_FORMAT,
  DATE_FORM_FORMAT,
} from '@/constans/date'
import { isFunction, orderBy } from 'lodash'
import { DATE_PRESETS } from '@/components/ui/UIDatePickerField/config'
import UiTimePicker from '@/components/ui/UIDatePickerField/UiTimePicker.vue'
import moment from 'moment'
import 'moment/locale/ru'

const KEYDOWN_ENTER = 'Enter'

export default {
  name: 'ui-date-range-picker',
  components: { UiTimePicker },
  props: {
    value: {
      type: Object,
      default: () => ({
        start: '',
        end: '',
      }),
    },
    showShortcuts: {
      type: Boolean,
      default: false,
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    displayDateFormatter: {
      type: Function,
      default: null,
    },
    returnValueFormat: {
      type: String,
      default: DATE_TIME_FORMAT,
    },
    isOnlyWeek: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      innerDate: ['', ''],
      innerTime: {
        start: '00:00',
        end: '23:59',
      },
      itemsPeriod: [...DATE_PRESETS],
      presetDate: null,
    }
  },
  computed: {
    isEmptyDate() {
      return !this.innerDate.length || (!this.innerDate[0] && !this.innerDate[1])
    },
    isSingleDate() {
      return this.innerDate.length && (!this.innerDate[0] || !this.innerDate[1])
    },
    formattedValue() {
      moment.locale(this.$i18n.locale)
      if (this.isEmptyDate) {
        return this.$t('base.selectDate')
      }

      const [start, end] = this.orderedInnerDate

      if (isFunction(this.displayDateFormatter)) {
        return this.displayDateFormatter({
          start,
          end,
        })
      }

      return `${this.$moment(start).format(DATE_FULL_MONTH_FORMAT)} - ${this.$moment(end).format(
        DATE_FULL_MONTH_FORMAT,
      )}`
    },
    headerText() {
      moment.locale(this.$i18n.locale)
      if (this.isEmptyDate) {
        return ''
      }

      const [start, end] = this.orderedInnerDate

      return `${this.$moment(start).format('DD MMM')} - ${this.$moment(end).format('DD MMM')}`
    },
    datePickerSettings() {
      const defaultSettings = {
        showCurrent: true,
        flat: true,
        color: 'primary base',
        scrollable: true,
        width: '300',
        firstDayOfWeek: 1,
        locale: this.$i18n.locale,
        selectedItemsText: this.headerText,
      }

      return {
        ...defaultSettings,
        ...this.settings,
        range: true,
      }
    },
    orderedInnerDate() {
      return this.orderRangeData(this.innerDate)
    },
  },
  watch: {
    value: {
      handler(dates) {
        if (dates.start && dates.end) {
          this.innerDate = [
            this.$moment(dates.start).format(DATE_FORM_FORMAT),
            this.$moment(dates.end).format(DATE_FORM_FORMAT),
          ]

          if (this.showTime) {
            this.innerTime.start = this.$moment(dates.start).format(TIME_SHORT_FORMAT)
            this.innerTime.end = this.$moment(dates.end).format(TIME_SHORT_FORMAT)
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown)
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === KEYDOWN_ENTER) {
        this.onApply()
      }
    },
    resetPreset() {
      if (this.isOnlyWeek) {
        const selectedDate = this.innerDate[0]
        const startDate = this.$moment(selectedDate).startOf('week')
        const endDate = this.$moment(selectedDate).endOf('week')

        this.innerDate = [
          this.$moment(startDate).format(DATE_FORM_FORMAT),
          this.$moment(endDate).format(DATE_FORM_FORMAT),
        ]
        this.innerTime.start = this.$moment(startDate).format(TIME_SHORT_FORMAT)
        this.innerTime.end = this.$moment(endDate).format(TIME_SHORT_FORMAT)
      }
      this.presetDate = null
    },
    onSelectPreset(preset) {
      this.innerDate = [
        this.$moment(preset.dates.start).format(DATE_FORM_FORMAT),
        this.$moment(preset.dates.end).format(DATE_FORM_FORMAT),
      ]
      this.innerTime.start = this.$moment(preset.dates.start).format(TIME_SHORT_FORMAT)
      this.innerTime.end = this.$moment(preset.dates.end).format(TIME_SHORT_FORMAT)
    },
    orderRangeData(dates, order = 'asc') {
      return orderBy(
        dates,
        val => {
          return this.$moment(val).format('YYYYMMDD')
        },
        [order],
      )
    },
    onApply() {
      this.$refs.menu.save(this.innerDate)

      if (this.isEmptyDate) {
        return
      }

      let datesArray = this.orderedInnerDate

      if (this.isSingleDate) {
        const [date] = this.innerDate.filter(date => !!date)

        this.innerDate[0] = date
        this.innerDate[1] = date
        datesArray = [...this.innerDate]
      }
      const [start, end] = datesArray
      const startDate = this.$moment(start + ' 00:00:00')
      const endDate = this.$moment(end + ' 00:00:00')

      const [startHour, startMinutes] = this.innerTime.start.split(':')
      startDate.add(startHour, 'hours')
      startDate.add(startMinutes, 'minutes')

      const [endHour, endMinutes] = this.innerTime.end.split(':')
      endDate.add(endHour, 'hours')
      endDate.add(endMinutes, 'minutes')
      endDate.add(59, 'seconds')

      this.$emit('input', {
        start: startDate.format(this.returnValueFormat),
        end: endDate.format(this.returnValueFormat),
      })
    },
  },
}
</script>

<style lang="scss">
.ui-date-picker-menu {
  border-radius: 16px;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.08);
}
.ui-text-field-disabled {
  opacity: 0.6;
}
</style>

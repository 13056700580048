export default [
  {
    path: '/settings/district',
    name: 'pageDistrictList',
    meta: { title: 'page.pageDistrictList', roles: ['SETTING_DISTRICT_VIEW'] },
    component: () => import('@/pages/settings/district'),
  },
  {
    path: '/settings/district/create',
    name: 'pageDistrictCreate',
    meta: { title: 'page.pageDistrictCreate', roles: ['SETTING_DISTRICT_VIEW'] },
    component: () => import('@/pages/settings/district/create'),
  },
  {
    path: '/settings/district/:id/edit',
    name: 'pageDistrictEdit',
    meta: { title: 'page.pageDistrictEdit', roles: ['SETTING_DISTRICT_VIEW'] },
    component: () => import('@/pages/settings/district/id/edit'),
  },
  {
    path: '/settings/district/district-import',
    name: 'pageDistrictImport',
    meta: { title: 'page.pageDistrictImport', roles: ['SETTING_DISTRICT_VIEW'] },
    component: () => import('@/pages/settings/district/import'),
  },
]

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    status: {
      value: 'shipped|approved|awaiting_ransom',
      operator: 'in',
    },
    deliveryService: {
      type: {
        value: 'courier',
        operator: 'in',
      },
    },
    countries: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    regions: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    districts: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    cities: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    tags: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    groups: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    offers: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    operator: null,
  },
  sortBy: ['id'],
  sortDesc: [false],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

import salesScriptMacrosRepo from "@/repository/admin/salesScriptMacrosRepository"

async function fetchData(context) {
  const { data } = await salesScriptMacrosRepo.list({ pagination: { limit: -1 }, order: { active: 'desc' } })
  context.commit('setScriptMacrosList', data.items)
  return { scriptMacrosList: data.items }
}

const state = {
  scriptMacrosList: []
}

const getters = {
  scriptMacrosList: state => state.scriptMacrosList,
  scriptMacrosActiveList: state => state.scriptMacrosList.filter(item => item.active),
}

const actions = {
  async loadScriptMacrosList (context) {
    if (context.getters.scriptMacrosList.length) {
      return { scriptMacrosList: context.getters.scriptMacrosList }
    }
    return await fetchData(context)
  },
  async reLoadScriptMacrosList (context) {
    return await fetchData(context)
  },
  async loadScriptMacros (context, id) {
    const { data } = await salesScriptMacrosRepo.get(id)
    return data
  },
  createScriptMacros (context, payload) {
    return salesScriptMacrosRepo.post(payload)
  },
  updateScriptMacros (context, payload) {
    const { id, ...data } = payload
    return salesScriptMacrosRepo.put(id, data)
  },
  deleteScriptMacros (context, payload) {
    return salesScriptMacrosRepo.delete(payload)
  }
}

const mutations = {
  setScriptMacrosList (state, payload) {
    state.scriptMacrosList = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default [
  {
    path: '/call-centre/kln-assessment',
    name: 'pageKlnAssessmentList',
    meta: { title: 'page.pageKlnAssessmentList', roles: ['CC_KLN_VIEW'] },
    component: () => import('@/pages/call-centre/kln-assessment'),
  },
  {
    path: '/call-centre/kln-assessment/create',
    name: 'pageKlnAssessmentCreate',
    meta: { title: 'page.pageKlnAssessmentCreate', roles: ['CC_KLN_VIEW'] },
    component: () => import('@/pages/call-centre/kln-assessment/create'),
  },
  {
    path: '/call-centre/kln-assessment/:id/edit',
    name: 'pageKlnAssessmentEdit',
    meta: { title: 'page.pageKlnAssessmentEdit', roles: ['CC_KLN_VIEW'] },
    component: () => import('@/pages/call-centre/kln-assessment/id/edit'),
  },
]

export default [
  {
    path: '/settings/delivery-weekend',
    name: 'PageDeliveryWeekendList',
    meta: { title: 'page.PageDeliveryWeekendList', roles: ['SETTING_DELIVERY_WEEKEND_VIEW'] },
    component: () => import('@/pages/settings/delivery-weekend'),
  },
  {
    path: '/settings/delivery-weekend/create',
    name: 'PageDeliveryWeekendCreate',
    meta: { title: 'page.PageDeliveryWeekendCreate', roles: ['SETTING_DELIVERY_WEEKEND_VIEW'] },
    component: () => import('@/pages/settings/delivery-weekend/create'),
  },
  {
    path: '/settings/delivery-weekend/:id/edit',
    name: 'PageDeliveryWeekendEdit',
    meta: { title: 'page.PageDeliveryWeekendEdit', roles: ['SETTING_DELIVERY_WEEKEND_VIEW'] },
    component: () => import('@/pages/settings/delivery-weekend/id/edit'),
  },
]
